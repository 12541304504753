import React from "react";

const TableRow = React.memo(({ isMatched, loading, ...props }: any) => {
  return (
    <>
      {false ? (
        <tr {...props} className="shimmer-effect highlight" />
      ) : (
        <tr
          {...props}
          className={`border-b bg-white ${isMatched ? "matchView" : ""}
          `}
        />
      )}
    </>
  );
});

export default TableRow;
