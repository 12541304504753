import React from "react";
import {
  decimalNumber,
  ticketTypeRenameForSalesReport,
} from "../../../../../helpers/Functions";
import { SYMBOLS } from "../../../../../helpers/const";

const FilterBlock = ({
  label,
  content,
  width = "",
  rounded = "",
  spanClassName = "px-2.5 ",
  border = false,
  glow = false,
}: any) => {
  return (
    <div
      className={`111 single_cat max-w-[${width}] w-full flex flex-col flex-wrap items-start pr-2.5 whitespace-nowrap`}
      title={content}
    >
      <label className="text-gray-500 leading-4 mb-1">{label}</label>
      <span
        className={`w-[${width}] ellips-text ${
          content && (border || glow) ? "px-2.5 py-1" : "px-0"
        } ${rounded} ${content && border ? "border bg-white" : ""} ${
          content && glow ? " text-indigo-500 border-indigo-500" : ""
        } leading-[.9375rem]`}
      >
        {content}
      </span>
    </div>
  );
};

interface HighligtedFilterInterface {
  data: any;
  bgColor?: string;
  NoBorderBottom?: boolean;
  confirmedScreen?: boolean;
}

const HighligtedFilter: React.FC<HighligtedFilterInterface> = ({
  data,
  bgColor = "bg-gray-100",
  NoBorderBottom = false,
  confirmedScreen = false,
}: any) => {
  let orderAssign = data;

  return (
    <div
      className={`cat_detail md:px-5 px-2.5 py-[.9375rem] md:pb-5 text-sm12 flex flex-wrap   ${bgColor} bg-opacity-50 ${
        NoBorderBottom ? "border-b-0" : "border-b"
      } gap-x-3.5 gap-y-[.9375rem]`}
    >
      {/* <div className="single_cat max-w-[11.25rem] w-full flex flex-col flex-wrap items-start pr-2.5">
        <label className="text-gray-400 leading-4 mb-1">Category</label>
        <span className="px-2.5 py-1 border rounded-xl text-indigo-500 border-indigo-500">
          Longside Lower
        </span>
      </div> */}
      {/* <div className="single_cat max-w-[11.25rem] w-full flex flex-col flex-wrap items-start pr-2.5">
        <label className="text-gray-400 leading-4 mb-1">Section/block</label>
        <span className="px-2.5 py-1 border rounded-xl bg-white">N1407</span>
      </div>
      <div className="single_cat max-w-[4.375rem] w-full flex flex-col flex-wrap items-start pr-2.5">
        <label className="text-gray-400 leading-4 mb-1">row</label>
        {/ <span className="px-2.5 py-1 border rounded-xl text-indigo-500 border-indigo-500"></span> /}
      </div>
      <div className="single_cat max-w-[4.375rem] w-full flex flex-col flex-wrap items-start pr-2.5">
        <label className="text-gray-400 leading-4 mb-1">Seat</label>
        {/ <span className="px-2.5 py-1 border rounded-xl text-indigo-500 border-indigo-500"></span> /}
      </div> */}

      <FilterBlock
        label={"Ticket type"}
        width="9.9375rem"
        rounded="rounded-xl"
        border={confirmedScreen ? false : true}
        glow={confirmedScreen ? false : true}
        content={ticketTypeRenameForSalesReport(
          orderAssign?.ticket_type,
          orderAssign?.sub_ticket_type
        )}
      />
      <FilterBlock
        label={"Qty"}
        width="9.875rem"
        rounded={`rounded-full ${
          !confirmedScreen ? "min-w-[1.5rem] min-h-[1.5rem]" : ""
        }`}
        border={confirmedScreen ? false : true}
        content={orderAssign?.ticket_quantity}
      />
      <FilterBlock
        label={"Ticket price"}
        width="9.9375rem"
        rounded="rounded-xl"
        content={`${SYMBOLS[orderAssign?.ticket_currency]}${decimalNumber(
          orderAssign?.ticket_price
        )}`}
      />
      {orderAssign?.transaction_value && (
        <FilterBlock
          label={"Order value"}
          width="9.875rem"
          rounded="rounded-xl"
          content={`${SYMBOLS[orderAssign?.ticket_currency]}${decimalNumber(
            orderAssign?.transaction_value
          )}`}
        />
      )}
      {/* {/ CATEGORY /} */}
      <FilterBlock
        label={"Category"}
        width="9.9375rem"
        rounded="rounded-xl"
        border={confirmedScreen ? false : true}
        glow={confirmedScreen ? false : true}
        content={orderAssign?.category}
      />
      {/* SECTION */}
      <FilterBlock
        label={"Section/block"}
        width="9.875rem"
        rounded="rounded-xl"
        border={confirmedScreen ? false : true}
        content={orderAssign?.seating_location}
      />
      {/* ROW */}
      <FilterBlock
        label={"Row"}
        width="9.9375rem"
        rounded="rounded-xl"
        content={orderAssign?.["items"]?.[0]?.row || orderAssign?.row}
      />
      {/* SEAT */}
      <FilterBlock
        label={"First seat"}
        width="9.875rem"
        rounded="rounded-xl"
        content={orderAssign?.["items"]?.[0]?.seat || orderAssign?.first_seat}
      />
      {/* BENEFITS */}
      <FilterBlock
        label={"Benefits & restrictions"}
        width="9.875rem"
        rounded="rounded-xl"
        content={
          orderAssign?.restrictions?.length > 0
            ? `${orderAssign?.restrictions?.length} selected`
            : "N/A"
        }
      />
      {/* DELIVER BY */}
      <FilterBlock
        label={"Deliver by"}
        width="9.875rem"
        rounded="rounded-xl"
        content={orderAssign?.deliver_by}
      />

      {/* {orderAssign?.seller_levels && (
        <FilterBlock
          label={"Seller levels"}
          width="9.9375rem"
          rounded="rounded-xl"
          content={`${orderAssign?.seller_levels
            ?.map((item: any) => item?.title)
            ?.join(", ")}`}
        />
      )}
      {orderAssign?.broker_name && (
        <FilterBlock
          label={"Team/broker"}
          width="9.875rem"
          rounded="rounded-xl"
          content={orderAssign?.broker_name}
        />
      )} */}
      {/* <div className="single_cat max-w-[7.5rem] w-full flex flex-col flex-wrap items-start pr-2.5">
        <label className="text-gray-400 leading-4 mb-1">Ticket price</label>
        <span className="px-2.5 py-1 rounded-xl ">£200.00</span>
      </div>
      <div className="single_cat max-w-[7.5rem] w-full flex flex-col flex-wrap items-start pr-2.5">
        <label className="text-gray-400 leading-4 mb-1">Order value</label>
        <span className="px-2.5 py-1 rounded-xl ">£400.00</span>
      </div> */}
    </div>
  );
};

export default HighligtedFilter;
