import { useContext } from "react";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { getCurrencySymbol } from "../../../helpers/Functions";
import { TXContext } from "../core/TXProvider";

const TXHeader = ({
  title,
  amount,
  children,
  svgPath,
  svgFillColor,
  bggradient = false,
  txPayLoading,
}: any) => {
  const layout = useContext(TXContext);
  const globalLayout: any = useContext(LayoutContext);
  const isGodAdmin = globalLayout?.allowedAccess?.account_info?.is_god_admin;

  function isGodadminBasedCurrency(Array: any) {
    const teamArr = Array;
    if (teamArr === undefined || teamArr?.length === 0) {
      return globalLayout.currencySymbol;
    } else {
      return getCurrencySymbol(teamArr?.base_currency);
    }
  }

  return (
    <>
      <div
        className={`w-full md:w-1/2 xl:w-1/4 mb-4 px-2.5 min-h-[10.875rem] max-sm:min-w-full max-lg:min-w-[50%] max-lg2:min-w-[40%]`}
      >
        <div
          className={`border rounded-xl p-[.9375rem] pt-5 h-full flex flex-wrap ${
            txPayLoading && "shimmer-effect"
          } `}
        >
          <div className="top_block mb-4 w-full">
            <div className="top-row flex items-center justify-between w-full">
              <h3 className="text-sm14 leading-4 ">{title}</h3>
              <IKTSVG
                className={`flex items-center justify-center ${svgFillColor}  `}
                path={svgPath}
                svgClassName="w-[.9375rem] h-[.9375rem]"
              />
            </div>
            <h2 className="text-lg md:text-[1.3125rem] mt-[1.0625rem] leading-[1.5625rem]">
              {isGodAdmin
                ? isGodadminBasedCurrency(layout?.TXPayFilterHandle?.team_id)
                : globalLayout.currencySymbol}
              {amount}
            </h2>
          </div>
          {children}
        </div>
      </div>
    </>
  );
};

export default TXHeader;
