import { useContext, useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { LayoutContext } from "../../../../components/core/LayoutProvider";
import { storeFields } from "../../../../components/core/requests";
import {
  columnsOutSideClick,
  outSideClick,
} from "../../../../helpers/AssetHelpers";
import { QUERIES } from "../../../../helpers/const";
import { SUBSCRIBE_TAB } from "../core/const";
import { PersonalDetailsContext } from "../core/PersonalDetailsProvider";
import DraggableColumns from "./DraggableColumns";
import DraggableFilters from "./DraggableFilters";

const HeaderfilterContainer = () => {
  const bulkActionRef: any = useRef(null);
  const bulkAction1Ref: any = useRef(null);
  const layout = useContext(PersonalDetailsContext);
  const globalLayout = useContext(LayoutContext);
  const active = layout?.activeTab;

  let headerFilter = `${
    active === SUBSCRIBE_TAB.subscribeDetails
      ? "savePersonalDetailsFilter"
      : active === "Referrals"
      ? "saveReferralDetailsFilter"
      : ""
  }`;
  let headerColumn = `${
    active === SUBSCRIBE_TAB.subscribeDetails
      ? "PersonalDetailsTableColumn"
      : active === "Referrals"
      ? "ReferralsTableColumn"
      : ""
  }`;

  let isFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.[headerFilter];

  let isColumnFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.[headerColumn];

  let payload: any = [
    {
      key: headerFilter,
      value: layout.filterItems,
    },
    {
      key: headerColumn,
      value: layout.columnItems,
    },
  ];

  //ADDING & REMOVING LISTENERS FOR OUTSIDE CLICK FOR DROPDOWNS
  useEffect(() => {
    document.addEventListener("mousedown", (e: any) =>
      outSideClick(e, headerFilter)
    );
    document.addEventListener("mousedown", (e: any) =>
      columnsOutSideClick(e, headerColumn)
    );
    return () => {
      document.removeEventListener("mousedown", (e: any) =>
        outSideClick(e, headerFilter)
      );
      document.removeEventListener("mousedown", (e: any) =>
        columnsOutSideClick(e, headerColumn)
      );
    };
  }, [headerColumn, headerFilter]);

  //   STORE FILTER ARRAY
  const { refetch } = useQuery(
    [`${QUERIES.STORE_FIELD}`],
    () => storeFields(payload),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,
      onError: (err) => {},
      onSettled(data, error) {
        // console.log(data);
      },
    }
  );
  // useEffect(() => {
  //   if (
  //     layout.filterItems &&
  //     existInLocalstorage("savePersonalDetailsFilter")
  //   ) {
  //     layout.setFilterItems(existInLocalstorage("savePersonalDetailsFilter"));
  //     setTimeout(() => {
  //       refetch();
  //       localStorage.removeItem("savePersonalDetailsFilter");
  //     }, 3000);
  //   }

  //   if (
  //     layout.columnItems
  //   ) {
  //     layout.setColumnItems(existInLocalstorage(headerColumn));
  //     setTimeout(() => {
  //       refetch();
  //       localStorage.removeItem(headerColumn);
  //     }, 3000);
  //   }
  // }, []);

  useEffect(() => {
    if (isFilterExistInDB) {
      setTimeout(() => {
        layout.setFilterItems(isFilterExistInDB);
        layout.setDraggedItems(isFilterExistInDB);
      }, 1000);
    }
    if (isColumnFilterExistInDB) {
      setTimeout(() => {
        layout.setColumnItems(isColumnFilterExistInDB);
        layout.setDraggedColumns(isColumnFilterExistInDB);
      }, 1000);
    }
  }, [isFilterExistInDB, isColumnFilterExistInDB]);

  return (
    <div className="flex flex-wrap" id="filters">
      <div className="ba_item relative max-md:hidden" ref={bulkActionRef}>
        <span
          className="sel_item block py-0.5 pr-6 pl-3 text-sm13 font-medium [&[aria-expanded='true']]:text-indigo-500 cursor-pointer"
          data-te-collapse-init
          data-te-collapse-collapsed
          data-te-target={`#${headerFilter}`}
          aria-expanded="false"
          aria-controls={headerFilter}
        >
          Filters
        </span>
        <div
          id={headerFilter}
          className="!visible ba_dd absolute top-full z-10 bg-white rounded shadow-lt left-0 min-w-[15rem] w-full hidden"
          data-te-collapse-item
          aria-labelledby={headerFilter}
          data-te-parent="#filters"
        >
          <DraggableFilters refetch={refetch} />
        </div>
      </div>

      <div className="ba_item relative" ref={bulkAction1Ref}>
        <span
          className="sel_item block py-0.5 pr-6 pl-3 text-sm13 font-medium [&[aria-expanded='true']]:text-indigo-500 cursor-pointer"
          data-te-collapse-init
          data-te-collapse-collapsed
          data-te-target={`#${headerColumn}`}
          aria-expanded="false"
          aria-controls={headerColumn}
        >
          Columns
        </span>
        <div
          id={headerColumn}
          className="!visible ba_dd absolute top-full z-10 bg-white rounded shadow-lt left-0 min-w-[15rem] w-full hidden cursor-pointer"
          data-te-collapse-item
          aria-labelledby={headerColumn}
          data-te-parent="#filters"
        >
          <DraggableColumns refetch={refetch} />
        </div>
      </div>
    </div>
  );
};

export default HeaderfilterContainer;
