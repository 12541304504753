/* eslint-disable jsx-a11y/aria-role */

import clsx from "clsx";
import { LayoutContext } from "components/core/LayoutProvider";
import TextInput from "components/formComponent/TextInput";
import { FormikErrors, useFormik } from "formik";
import { getValueByKey } from "helpers/Functions";
import { size } from "lodash";
import { useContext } from "react";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import * as Yup from "yup";
import AddIcon from "../../../../assets/media/icons/Add-icon.svg";
import DeleteSVG from "../../../../assets/media/icons/other_icons/Delete.svg";
import CrossSVG from "../../../../assets/media/icons/standard_icons/cross.svg";
import { IKTSVG } from "../../../../components/ui/IKTSVG";

interface Invitation {
  name: string;
  email: string;
}

interface FormValues {
  invitations: Invitation[];
}

// Define validation schema with Yup
const validationSchema = Yup.object({
  invitations: Yup.array().of(
    Yup.object({
      name: Yup.string().required("Referral name is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Referral email is required"),
    })
  ),
});

const ReferralPopup = () => {
  const { asideMenuOpen, allowedAccess, referralPopup, setReferralPopup } =
    useContext(LayoutContext);

  const { seller_level } = allowedAccess?.team_data || {};

  const formik = useFormik<FormValues>({
    initialValues: {
      invitations: [{ name: "", email: "" }],
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      // Handle submit logic here
    },
  });

  // REMOVE ROW
  const handleRemoveHeader = (index: number) => {
    const updatedInvitations = formik.values.invitations.filter(
      (_, idx) => idx !== index
    );
    formik.setFieldValue("invitations", updatedInvitations);
  };

  // ADD NEW REFERRAL VALUE
  const handleAddReferral = () => {
    formik.setFieldValue("invitations", [
      ...formik.values.invitations,
      { name: "", email: "" },
    ]);
  };

  // HANDLE CLEAR VALUE
  const handleClearValue = (name: string, index: number) => {
    const updatedInvitations = formik.values.invitations?.map(
      (item: any, sIndex: number) =>
        sIndex === index ? { ...item, [name]: "" } : item
    );

    formik.setFieldValue("invitations", updatedInvitations);
  };

  if (!referralPopup) {
    return null;
  }

  return (
    <div
      className={`${
        referralPopup ? "opacity-100 visible" : "opacity-0 invisible"
      } fixed left-auto right-0 top-0 z-[2000] h-dvh ${
        asideMenuOpen
          ? "w-full md:w-[calc(100%_-_13.875rem)]"
          : "w-full md:w-[calc(100%_-_3.75rem)]"
      } overflow-y-auto overflow-x-hidden outline-none md:rounded-l-lg transition-all duration-200 ease-in-out bg-black/70`}
    >
      <div
        className={` pointer-events-none relative flex min-h-[calc(100%-2rem)] w-auto items-center mx-auto my-5 min-[576px]:min-h-[calc(100%-3.5rem)] max-w-[43.75rem]`}
      >
        <div className="pointer-events-auto relative flex w-full flex-col rounded-lg border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
          <div className="modal-header flex justify-between border-b">
            <h5 className="modal-title mb-0 text-sm15 leading-[1.1875rem] font-semibold py-[0.6563rem] px-5">
              Share your referral link
            </h5>
            <button
              type="button"
              className="flex items-center justify-center w-9 border-l box-content group"
              data-tooltip-id="referral-popup"
              onClick={() => {
                formik.resetForm();
                setReferralPopup(false);
              }}
            >
              <Tooltip
                id={`referral-popup`}
                content={"Close"}
                variant="light"
                className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-[10000] "
              />
              <IKTSVG
                className="fill-violet-500 group-hover:fill-indigo-500 h-[0.5625rem] w-[0.5625rem] transition"
                path={CrossSVG}
                svgClassName="w-2.5 h-2.5"
              />
            </button>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="modal-body relative p-5">
              <p className="w-full text-sm12 leading-[1.0625rem] font-normal text-gray-500 pb-5">
                Know someone who sells event tickets? Share your Tixstock
                referral link and we'll give you{" "}
                {getValueByKey(seller_level?.config, "referral_margin")}% of
                their sales commission for{" "}
                {getValueByKey(
                  seller_level?.config,
                  "referral_expiry_duration"
                )}{" "}
                {getValueByKey(
                  seller_level?.config,
                  "referral_expiry_duration_options"
                )}
                .{" "}
                <Link
                  className="text-sm13 font-medium text-violet-800 hover:text-indigo-500"
                  target="_blank"
                  to={
                    "https://help.tixstock.com/en/articles/10342523-referral-scheme"
                  }
                >
                  Learn more
                </Link>
              </p>
              <div className="w-full border border-gray-200 rounded py-[0.9375rem] mb-[0.9375rem] overflow-y-auto max-h-80 scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
                <h6 className="w-full text-sm13 font-semibold leading-4 mb-[0.9375rem] px-[0.9375rem]">
                  Email an invite
                </h6>

                {formik.values.invitations?.map(
                  (invite: any, index: number) => {
                    return (
                      <div className="flex w-full items-center gap-x-[0.875rem] p-[0.9375rem] pt-2.5 last:pb-0 last:border-b-0 border-b">
                        <div className="flex w-[calc(100%_-_1.875rem)] flex-wrap items-center gap-x-[0.875rem]">
                          <div className="w-full sm:w-[calc(50%-0.4375rem)] relative">
                            <label
                              htmlFor={`invitations.${index}.name`}
                              className="text-sm12 font-medium leading-[0.9375rem] text-gray-500 mb-[0.3125rem]"
                            >
                              Referral name*
                            </label>
                            <TextInput
                              name={`invitations.${index}.name`}
                              id={`referral_name_${index}`}
                              autoComplete="off"
                              type="text"
                              inputClassName="appearance-none border border-gray-300 rounded w-full h-[1.875rem] px-2.5 pt-[0.4375rem] pb-2 focus:ring-0 focus:border-indigo-500 focus:bg-indigo-500 focus:bg-opacity-[7%] placeholder:truncate placeholder-shown:text-gray-400 block font-medium text-xs transition bg-white"
                              required={false}
                              placeholder="Type here"
                              value={invite.name}
                              errorClass={
                                formik.touched.invitations?.[index]?.name &&
                                // @ts-ignore
                                formik.errors.invitations?.[index]?.name
                                  ? "border-rose-500"
                                  : ""
                              }
                              labelErrorClass={
                                formik.touched.invitations?.[index]?.name &&
                                // @ts-ignore
                                formik.errors.invitations?.[index]?.name
                                  ? "border-rose-500"
                                  : ""
                              }
                              handleOnChange={formik.handleChange}
                              handleClearValue={() =>
                                handleClearValue("name", index)
                              }
                            />
                            {formik.touched.invitations?.[index]?.name &&
                              formik.errors.invitations?.[index] &&
                              (
                                formik.errors.invitations?.[
                                  index
                                ] as FormikErrors<Invitation>
                              )?.name && (
                                <div className="text-xxs text-rose-500 error-msg left-3">
                                  {
                                    (
                                      formik.errors.invitations?.[
                                        index
                                      ] as FormikErrors<Invitation>
                                    ).name as string
                                  }
                                </div>
                              )}
                          </div>
                          <div className="w-full sm:w-[calc(50%-0.4375rem)] relative">
                            <label
                              htmlFor={`invitations.${index}.email`}
                              className="text-sm12 font-medium leading-[0.9375rem] text-gray-500 mb-[0.3125rem]"
                            >
                              Referral email*
                            </label>
                            <TextInput
                              name={`invitations.${index}.email`}
                              id={`email${index}`}
                              autoComplete="off"
                              type="text"
                              inputClassName="appearance-none border border-gray-300 rounded w-full h-[1.875rem] px-2.5 pt-[0.4375rem] pb-2 focus:ring-0 focus:border-indigo-500 focus:bg-indigo-500 focus:bg-opacity-[7%] placeholder:truncate placeholder-shown:text-gray-400 block font-medium text-xs transition bg-white"
                              required={false}
                              placeholder="Type here"
                              value={invite?.email}
                              errorClass={
                                formik.touched.invitations?.[index]?.email &&
                                // @ts-ignore
                                formik.errors.invitations?.[index]?.email
                                  ? "border-rose-500"
                                  : ""
                              }
                              handleOnChange={formik.handleChange}
                              handleClearValue={() =>
                                handleClearValue("email", index)
                              }
                            />
                            {formik.touched.invitations?.[index]?.email &&
                              formik.errors.invitations?.[index] &&
                              typeof formik.errors.invitations?.[index] ===
                                "object" &&
                              (
                                formik.errors.invitations?.[
                                  index
                                ] as FormikErrors<Invitation>
                              )?.email && (
                                <div className="text-xxs text-rose-500 error-msg left-3">
                                  {
                                    (
                                      formik.errors.invitations?.[
                                        index
                                      ] as FormikErrors<Invitation>
                                    ).email as string
                                  }
                                </div>
                              )}
                          </div>
                        </div>

                        {/* REMOVE BUTTON */}
                        <button
                          type="button"
                          data-tooltip-id={`referral-popup-row-delete-${index}`}
                          className={clsx(
                            "group inline-flex items-center mt-3 sm:mt-6 sm:h-[1.875rem]",
                            size(formik.values.invitations) === 1
                              ? "pointer-events-none bg-transparent text-gray-500"
                              : ""
                          )}
                          onClick={() => handleRemoveHeader(index)}
                        >
                          <Tooltip
                            id={`referral-popup-row-delete-${index}`}
                            content={"Remove"}
                            variant="light"
                            className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-[10000] "
                          />
                          <IKTSVG
                            className={clsx(
                              "w-3 h-[.9375rem]",
                              size(formik.values.invitations) === 1
                                ? "pointer-events-none bg-transparent fill-gray-500"
                                : "cursor-pointer fill-violet-500 hover:fill-indigo-500"
                            )}
                            path={DeleteSVG}
                            svgClassName="w-3 h-[.9375rem]"
                          />
                        </button>
                      </div>
                    );
                  }
                )}
              </div>

              {/* BUTTONS */}
              <button
                type="button"
                className={clsx(
                  "flex items-center h-6 px-2.5 pt-[0.2188rem] pb-[0.2813rem] rounded text-sm13 leading-4 font-medium bg-gray-100 mb-5",
                  size(formik.values.invitations) === 10
                    ? "text-gray-400"
                    : "text-violet-800 group hover:bg-indigo-500 hover:text-white"
                )}
                onClick={handleAddReferral}
                disabled={size(formik.values.invitations) === 10 ? true : false}
              >
                <SVG
                  src={AddIcon}
                  className={clsx(
                    `w-3 h-3  group-hover:fill-white mr-2`,
                    size(formik.values.invitations) === 10
                      ? "fill-gray-400"
                      : "fill-violet-500"
                  )}
                />
                Add another
              </button>

              {/* Consent text */}
              <p className="w-full text-sm12 leading-[1.0625rem] font-normal text-gray-500">
                * I confirm that all invitees listed have given consent to share
                their details
              </p>
            </div>
            <div className="modal-footer relative px-5 py-[0.8125rem] h-[3.125rem] border-t border-gray-200">
              <button
                type="submit"
                className="h-6 text-sm13 leading-4 font-medium rounded bg-violet-500 hover:bg-indigo-500 text-white float-right px-2.5 pt-[0.2188rem] pb-[0.2813rem] appearance-none"
              >
                Send referral
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ReferralPopup;
