import DropDownField from "components/formComponent/DropDownField";
import TextInput from "components/formComponent/TextInput";
import { useFormik } from "formik";
import CommonDynamicComponent from "pages/subscribe/seller_levels/component/CommonDynamicComponent";
import { useContext, useEffect, useState } from "react";
import * as Yup from "yup";

import ProgressBardLoader from "components/ui/ProgressBardLoader";
import { errorReflect } from "helpers/Functions";
import { showAlert } from "helpers/ShowAlert";
import { PersonalDetailsContext } from "../core/PersonalDetailsProvider";
import { sellerLevelUpdate } from "../core/requests";

const SellerLevelConfigurationTabs = ({ viewForm, refetch }: any) => {
  const layout = useContext(PersonalDetailsContext);
  const [levelData, setLevelData] = useState<any>();

  const [isUpdatedValue, setIsUpdatedValue] = useState<any>();
  const [validationSchema, setValidationSchema] = useState<any>();
  const [optionsConfig, setOptionsConfig] = useState<any>();
  const [mainLoading, setMainLoading] = useState<any>(false);
  const [confirmAlert, setConfirmAlert] = useState<any>({
    flag: "No",
    status: false,
  });

  function convertToId(value: any) {
    return value
      ?.toLowerCase()
      ?.replace(/\s+/g, "_")
      ?.replace(/[^\w_]/g, "");
  }

  function updateLevelPayload(data: any) {
    const updatedData = isUpdatedValue?.filter((obj: any) => {
      return obj?.is_edited;
    });

    const newData = updatedData
      ?.filter((obj: any) => {
        return Object.keys(data).some(
          (key) => obj.key?.toLowerCase() === key.toLowerCase()
        );
      })
      .map((obj: any) => {
        const matchingKey = Object.keys(data).find(
          (key) => obj.key?.toLowerCase() === key.toLowerCase()
        );

        delete obj.originalValue;

        return matchingKey
          ? {
              ...obj,
              value:
                typeof data[matchingKey] === "object" &&
                data[matchingKey] !== null
                  ? data[matchingKey].id
                  : data.hasOwnProperty(matchingKey)
                  ? data[matchingKey]
                  : data[matchingKey],
            }
          : obj;
      });
    return newData;
  }

  // GET LISTINGS
  // const { isLoading: loading } = useQuery(
  //   [QUERIES.SELLER_LEVEL_PERSONAL_DETAILS_OPTIONS],
  //   () => sellerLevelConfiguration(),
  //   {
  //     refetchOnWindowFocus: false,
  //     refetchOnReconnect: false,
  //     retry: false,
  //     cacheTime: 0,
  //     // enabled: true,
  //     onSettled(data, error) {
  //       const newUpdate = data?.data?.lists?.map((obj: any) => {
  //         return {
  //           id: obj?.id,
  //           name: obj?.title,
  //           label: obj?.title,
  //         };
  //       });
  //       setOptionsData(newUpdate);
  //     },
  //   }
  // );
  const initialValues = {
    title: "",
    weightage: "",
    ...levelData?.reduce((acc: any, field: any) => {
      acc[field?.key] = field?.value !== undefined ? field?.value : "";
      return acc;
    }, {}),
  };

  // Initialize useFormik
  const formik: any = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    // enableReinitialize: true,
    onSubmit: async (values) => {
      setMainLoading(true);
      const results = await updateLevelPayload(values);

      const payload = {
        team_id: viewForm?.id,
        seller_level_id: values?.title?.id,
        ...(results?.length > 0 &&
          confirmAlert?.flag === "No" && {
            seller_level_custom_config: results,
          }),
      };

      try {
        const res = await sellerLevelUpdate(payload);
        const { data, message } = res;
        if (data?.status === 200) {
          showAlert(message, false);
          refetch();
        } else {
          errorReflect(res);
        }
      } catch (error) {
        console.error("Error updating seller level:", error);
        showAlert("Failed to update seller level", false);
      } finally {
        setMainLoading(false);
        setConfirmAlert({
          flag: "No",
          status: false,
        });
      }
    },
  });

  function setLevelsBasedOnDropDownValueChange(data: any) {
    let editConfigData = viewForm?.data?.seller_level?.config;
    const configFile = layout.levelLists?.list?.find(
      (obj: any) => obj?.id === data?.id
    )?.config;

    const weightage = layout.levelLists?.list?.find(
      (obj: any) => obj?.id === data?.id
    )?.weightage;

    let filterData: any;

    if (editConfigData?.length > 0) {
      const finalResult = editConfigData?.map((item1: any) => {
        const item2 = configFile?.find((item: any) => item?.key === item1?.key);
        if (item1?.is_edited) {
          return {
            ...item1,
            originalValue:
              item1?.component === "radio"
                ? item2?.value?.toString()
                : item2?.value,
            // value: item1?.value !== undefined ? item1?.value : item1?.value,
          };
        } else {
          return {
            ...item2,
            is_edited: false,
            // value: item1?.value !== undefined ? item1?.value : item1?.value,
          };
        }
      });
      // filterData = finalResult?.map((obj: any) => ({
      //   ...obj,
      //   value:
      //     obj?.component === "dropdown" && obj?.value
      //       ? { id: convertToId(obj.value), name: obj.value, label: obj?.value }
      //       : obj.value,
      // }));
      filterData = finalResult?.map((obj: any) => {
        const isDropdown = obj?.component === "dropdown";
        const optionsForKey = optionsConfig?.[obj?.key];
        const findOptions = optionsForKey?.find(
          (option: any) =>
            option?.id === obj?.value || option?.name === obj?.value
        );
        return {
          ...obj,
          value: isDropdown ? findOptions : obj.value,
        };
      });
    } else {
      // filterData = configFile?.map((obj: any) => ({
      //   ...obj,
      //   value:
      //     obj?.component === "dropdown" && obj?.value
      //       ? { id: convertToId(obj.value), name: obj.value, label: obj?.value }
      //       : obj.value,
      // }));
      filterData = configFile?.map((obj: any) => {
        const isDropdown = obj?.component === "dropdown";
        const optionsForKey = optionsConfig?.[obj?.key];
        const findOptions = optionsForKey?.find(
          (option: any) =>
            option?.id === obj?.value || option?.name === obj?.value
        );
        return {
          ...obj,
          value: isDropdown ? findOptions : obj.value,
        };
      });
    }
    if (weightage) {
      formik.setFieldValue("weightage", weightage);
    }
    setLevelData(filterData);
    setIsUpdatedValue(filterData);
  }

  const handleOnUpdate = (data: any, value: any, radio: any) => {
    setIsUpdatedValue((prevData: any) =>
      prevData?.map((obj: any) =>
        obj?.key?.toLowerCase() === data?.toLowerCase()
          ? { ...obj, is_edited: true }
          : obj
      )
    );
  };

  function getOptionsValue(key: any, value: any, options: any) {
    const filterData = options?.[key]?.filter(
      (item: any) =>
        item?.id === value || item?.name?.toLowerCase() === value?.toLowerCase()
    );

    return filterData?.[0];
  }

  useEffect(() => {
    let editConfigData = viewForm?.data?.seller_level?.config;

    if (editConfigData?.length > 0) {
      const mainConfig = layout.levelLists?.list?.find(
        (obj: any) => obj?.id === viewForm?.data?.seller_level?.id
      );

      if (mainConfig?.title) {
        setTimeout(() => {
          formik.setFieldValue("title", {
            id: mainConfig?.id,
            label: convertToId(mainConfig?.title),
            name: mainConfig?.title,
          });
          formik.setFieldValue("weightage", mainConfig?.weightage);
        }, 0);
      }
      const finalResult = viewForm?.data?.seller_level?.config?.map(
        (item1: any) => {
          const item2 = mainConfig?.config?.find(
            (item: any) => item?.key === item1?.key
          );
          if (item1?.is_edited) {
            return {
              ...item1,
              originalValue:
                item1?.component === "radio"
                  ? item2?.value?.toString()
                  : item2?.value,
            };
          } else {
            return { ...item2, is_edited: false };
          }
        }
      );

      const options = optionsConfig;

      const filterData = finalResult?.map((obj: any) => ({
        ...obj,
        value:
          obj?.component === "dropdown" && obj?.value
            ? getOptionsValue(obj?.key, obj.value, options)
            : obj.value !== undefined
            ? obj.value
            : obj.value,
      }));

      setLevelData(filterData);
      setIsUpdatedValue(filterData);
    }
  }, [viewForm, optionsConfig]);

  useEffect(() => {
    formik.setValues((prevValues: any) => ({
      ...prevValues,
      ...levelData?.reduce((acc: any, field: any) => {
        acc[field.key] = field.value !== undefined ? field.value : "";
        return acc;
      }, {}),
    }));

    const validationSchema = Yup.object().shape({
      title: Yup.object().nullable().required("Seller Level Name is Required"),
      weightage: Yup.string()
        .trim()
        .required("Seller Level Weightage is Required"),
      ...levelData?.reduce((acc: any, field: any) => {
        if (field.component !== "dropdown") {
          // acc[field?.key] = Yup.string()
          //   .trim()
          //   .required(`${field?.label} is Required`);
          if (field.component === "string") {
            acc[field?.key] = Yup.string()
              .trim()
              .required(`${field?.label} is Required`);
          } else if (field.component !== "radio") {
            acc[field?.key] = Yup.number()
              .required(`${field?.label} is Required`)
              .typeError(`${field?.label} must be a number`)
              .min(0, `${field?.label} cannot be negative`);
          }
        } else if (field.component !== "radio") {
          acc[field?.key] = Yup.object()
            .nullable()
            .required(`${field?.label} is Required`);
        }
        return acc;
      }, {}),
    });
    setValidationSchema(validationSchema);
  }, [levelData]);

  useEffect(() => {
    if (layout?.levelLists?.options) {
      setOptionsConfig(layout?.levelLists?.options?.options);
    }
  }, [layout?.levelLists?.options]);

  return (
    <div className="inner-content p-5 bg-gray-650">
      <div className="table-block border rounded mb-5 bg-white md:px-5 px-3 py-5">
        <div className="new-inp">
          <div className="flex flex-wrap items-center gap-y-5 -mx-2.5">
            <div className=" md:w-1/3 w-full  relative px-2.5">
              <div className="relative">
                <DropDownField
                  options={layout.levelLists?.levelOptions}
                  value={formik.values?.title}
                  placeholder={"Level"}
                  name={"title"}
                  getOptionLabel="name"
                  getOptionValue="id"
                  height=""
                  className={`h-fourty ${
                    formik.touched?.title &&
                    formik.errors?.title &&
                    "all-dropdown-errorField"
                  } `}
                  isClearable={false}
                  handleOnChange={(data: any) => {
                    formik?.setFieldValue("title", data?.target?.value);
                    setConfirmAlert({
                      flag: "No",
                      status: true,
                    });
                    setLevelsBasedOnDropDownValueChange(data?.target?.value);
                  }}
                  isCapital={true}
                  isValueCapital={true}
                />
                {formik.touched.title && formik.errors.title && (
                  <div className="text-xs text-rose-500 error-msg top-[1.875rem] left-[2.0625rem]">
                    {formik.errors.title as string}
                  </div>
                )}
              </div>
            </div>

            <div className=" md:w-1/3 w-full relative px-2.5">
              {/* <TextInput
              name="weightage"
              type={"text"}
              label="Seller level weightage"
              id="weightage"
              required={false}
              className={`!bg-white  `}
              value={formik?.values?.weightage ? formik?.values?.weightage : ""}
              handleOnChange={formik?.handleChange}
              // disabled={layout?.sellerLevelEditData?.title}
              errorClass={
                formik.touched?.weightage &&
                formik.errors?.weightage &&
                "border-rose-500"
              }
              handleClearValue={() => formik.setFieldValue("weightage", "")}
            /> */}

              <TextInput
                required={false}
                name="weightage"
                type="text"
                label="Seller level weightage"
                id="weightage"
                value={
                  formik?.values?.weightage ? formik?.values?.weightage : ""
                }
                handleOnChange={formik?.handleChange}
                errorClass={
                  formik.touched?.weightage &&
                  formik.errors?.weightage &&
                  "border-rose-500"
                }
                disabled={true}
                handleClearValue={() => formik.setFieldValue("weightage", "")}
              />

              {formik.touched.weightage && formik.errors.weightage && (
                <div className="text-xs text-rose-500 error-msg top-[3.125rem] left-[2.0625rem]">
                  {formik.errors.weightage as string}
                </div>
              )}
            </div>
          </div>
          <div
            className={`${
              formik.touched.weightage && formik.errors.weightage && "pt-2.5"
            }`}
          >
            <h5 className="text-sm13  mb-7 mt-5">
              Please fill in the configuration you wish to add at this level
            </h5>
          </div>

          <div className="flex flex-wrap -mx-2.5 hideddlabel">
            {levelData?.map((obj: any, index: any) => (
              <CommonDynamicComponent
                index={index}
                obj={obj}
                OptionData={optionsConfig}
                formik={formik}
                ClassName={"md:w-1/3 w-full flex-wrap"}
                fullWidthTitle={true}
                handleOnUpdate={handleOnUpdate}
                formikErrorClass={true}
                component="configurationTabs"
              />
            ))}
          </div>
          <div className="flex flex-wrap l_btn  items-center w-full ">
            {confirmAlert?.status ? (
              <div className="flex flex-row w-full gap-2.5">
                <span className=" text-sm15 block ">
                  Do you want to override the custom field value by selecting
                  this level?
                </span>
                <div className="flex items-center gap-2.5 ">
                  <button
                    className="relative z-10 border border-violet-500 active:text-white text-white hover:bg-indigo-500 hover:border-indigo-500 bg-violet-500 active:bg-violet-500 transition font-semibold rounded text-sm13 px-2.5 py-0.5"
                    type="button"
                    onClick={() => {
                      setConfirmAlert((pre: any) => {
                        return {
                          ...pre,
                          flag: "No",
                        };
                      });

                      setTimeout(() => {
                        formik.handleSubmit();
                      }, 500);
                    }}
                  >
                    No
                  </button>
                  <button
                    className="relative z-10 border border-violet-500 active:text-white text-white hover:bg-indigo-500 hover:border-indigo-500 bg-violet-500 active:bg-violet-500 transition font-semibold rounded text-sm13 px-2.5 py-0.5"
                    type="button"
                    id={`delete`}
                    onClick={() => {
                      setConfirmAlert((pre: any) => {
                        return {
                          ...pre,
                          flag: "Yes",
                        };
                      });

                      setTimeout(() => {
                        formik.handleSubmit();
                      }, 500);
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            ) : (
              <button
                type="button"
                className="relative z-10 border border-green-600 active:text-white text-white hover:bg-indigo-500 hover:border-indigo-500 bg-green-600 active:bg-green-600 transition font-semibold rounded text-sm13 px-2.5 py-0.5 "
                onClick={() => formik.handleSubmit()}
              >
                Save changes
              </button>
            )}
          </div>
        </div>
      </div>
      {mainLoading && <ProgressBardLoader secondCounter={2} />}
    </div>
  );
};

export default SellerLevelConfigurationTabs;
