import _ from "lodash";
import { useContext } from "react";
import { addCommaInNumber } from "../../../helpers/Functions";
import { PUBLISH_STATUS } from "../../../helpers/const";
import { InventoryContext } from "../core/InventoryProvider";

const ListingInfo = ({
  listName,
  listCount,
  ticketCount,
  isCheckbox,
  isFirstLabel,
  isLastLabel,
  postKey,
  publishStatus,
  setPublishStatus,
  disabled,
  isLoading,
  isLow,
}: any) => {
  const layout = useContext(InventoryContext);
  let checked = publishStatus
    ? publishStatus.some((data: any) => data?.id === postKey)
    : false;

  let checkedForLowQuality =
    (layout?.inventoryNewFilterValue?.listing_quality?.some(
      (qualityItem: any) => qualityItem.id === 0 && qualityItem.name === "Low"
    ) &&
      layout?.inventoryNewFilterValue?.listing_quality?.some(
        (qualityItem: any) =>
          qualityItem.id === 1 && qualityItem.name === "Level 1"
      ) &&
      postKey === "listing_quality") ||
    (layout?.inventoryNewFilterValue?.listing_quality?.some(
      (qualityItem: any) =>
        qualityItem.id === 3 && qualityItem.name === "Price high"
    ) &&
      postKey === "price_quality");

  return (
    <>
      <label
        className={`w-full sm:w-1/2 lg:w-1/3 mb-4 px-2.5 xl:min-w-[16.66%] ${
          isFirstLabel ? "pl-5" : ""
        } ${isLastLabel ? "pr-5" : ""} ${
          disabled ? "pointer-events-none" : isCheckbox ? `cursor-pointer` : ``
        }`}
        htmlFor={postKey}
      >
        <div
          className={`${
            isLoading && "shimmer-effect"
          } border bg-white rounded group px-2.5 pt-[.4375rem] pb-[.5625rem] h-full min-w-[11.25rem]
          ${
            isCheckbox &&
            "hover:bg-indigo-500/5 hover:border-indigo-500 hover:text-indigo-500"
          }
           ${
             (checked || checkedForLowQuality) && "active-style"
           } [&.active-style]:bg-indigo-500/5 [&.active-style]:border-indigo-500`}
        >
          <div className="flex justify-between items-center">
            <h3
              className={`md:text-lg md:leading-6 ${
                (checked || checkedForLowQuality) && "active-style"
              } [&.active-style]:text-indigo-500`}
            >
              {listCount ? addCommaInNumber(listCount) : 0}
            </h3>
            {ticketCount > 0 && !isLow && (
              <h5 className="flex items-center">
                <span
                  className={`flex items-center bg-gray-100 text-xxs font-semibold px-2 py-0.5 rounded ml-2.5 ${
                    (checked || checkedForLowQuality) && "active-style"
                  } [&.active-style]:text-indigo-500 [&.active-style]:bg-indigo-500/5`}
                >
                  {ticketCount ? addCommaInNumber(ticketCount) : 0} tickets
                </span>
              </h5>
            )}
            {false && (
              <div className="low-meter flex h-full rounded mt-[.3125rem] p-1 bg-gray-100 gap-x-[.0938rem]">
                <span className="w-[.1563rem] h-3 bg-gray-400 rounded-l-[1px] "></span>
                <span className="w-[.1563rem] h-3 bg-gray-400"></span>
                <span className="w-[.1563rem] h-3 bg-gray-400"></span>
                <span className="w-[.1563rem] h-3 bg-gray-400 rounded-r-[1px]"></span>
              </div>
            )}
          </div>
          <div className="flex justify-between items-center pt-1.5">
            <p
              className={`text-xs text-gray-500 ${
                isCheckbox && "group-hover:text-indigo-500"
              } ${
                (checked || checkedForLowQuality) && "active-style"
              } [&.active-style]:text-indigo-500`}
            >
              {listName}
            </p>

            {isCheckbox && (
              <label className="false  form-check flex flex-row-reverse justify-between items-center rounded border-gray-300">
                <div className="flex">
                  <input
                    className="form-check-input h-3 w-3 rounded-sm bg-gray-100 border-gray-300 checked:bg-indigo-500 transition ml-2 cursor-pointer border checked:border-indigo-500 hover:border-indigo-500 group-hover:border-indigo-500 focus:ring-0 focus:ring-offset-0"
                    type="checkbox"
                    name={postKey}
                    id={postKey}
                    checked={!!(checked || checkedForLowQuality)}
                    disabled={disabled}
                    onChange={(e: any) => {
                      if (
                        e.target.name === "listing_quality" ||
                        e.target.name === "price_quality"
                      ) {
                        if (e.target.checked) {
                          layout.setInventoryNewFilterValue((pre: any) => {
                            if (e.target.name === "listing_quality") {
                              return {
                                ...pre,
                                listing_quality: _.unionBy(
                                  [
                                    { id: 0, name: "Low" },
                                    { id: 1, name: "Level 1" },
                                  ],
                                  pre?.listing_quality,
                                  "id"
                                ),
                              };
                            } else {
                              return {
                                ...pre,
                                listing_quality: _.unionBy(
                                  [{ id: 3, name: "Price high" }],
                                  pre?.listing_quality,
                                  "id"
                                ),
                              };
                            }
                          });
                        } else {
                          layout.setInventoryNewFilterValue((pre: any) => {
                            return {
                              ...pre,
                              listing_quality: pre?.listing_quality.filter(
                                (item: any) =>
                                  e.target.name === "listing_quality"
                                    ? item?.name !== "Low" &&
                                      item?.name !== "Level 1"
                                    : item?.name !== "Price high"
                              ),
                            };
                          });
                        }
                      } else {
                        setPublishStatus((current: any) => {
                          if (e.target.checked) {
                            return !publishStatus.some(
                              (status: any) => status.id === e.target.name
                            )
                              ? [
                                  ...publishStatus,
                                  { id: e.target.name, name: e.target.value },
                                ]
                              : publishStatus;
                          } else {
                            return publishStatus.filter(
                              (status: any) => status.id !== postKey
                            );
                          }
                        });
                      }
                    }}
                    value={
                      PUBLISH_STATUS.find((status) => status.id === postKey)
                        ?.name
                    }
                  />
                </div>
              </label>
            )}
          </div>
        </div>
      </label>
    </>
  );
};

export default ListingInfo;
