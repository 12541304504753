import { storeFields } from "components/core/requests";
import { IKTSVG } from "components/ui/IKTSVG";
import ProgressBardLoader from "components/ui/ProgressBardLoader";
import { existInLocalstorage } from "helpers/Functions";
import { useContext, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import close from "../../../assets/media/icons/standard_icons/cross.svg";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { TXContext } from "../core/TXProvider";
import { TX_TAB } from "../core/const";
import { useNavigate } from "react-router-dom";

export const VirtualCardPolicy = ({ setIsVirtualCardpolicyOpen }: any) => {
    const navigate = useNavigate();
  const layout = useContext(LayoutContext);

  const { setActiveTab } = useContext(TXContext);
  const [isChecked, setIsChecked] = useState(false);

  const [isScrolled80, setIsScrolled80] = useState<any>(false);
  const contentRef = useRef<any>(null);

  const payload = [
    {
      key: "saveTxPayVirtualCardTerms",
      value: 1,
    },
  ];

  let checkLocalStorage = existInLocalstorage("access");

  const { refetch, isLoading } = useQuery(
    [`tx-pay-virtualCardTerms`],
    () => storeFields(payload),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,
      onSettled: (data) => {
        if (data?.data?.status) {
          setIsVirtualCardpolicyOpen(false);
          if (checkLocalStorage) {
            const newData = {
              ...checkLocalStorage,
              ui_field_settings: {
                ...checkLocalStorage?.ui_field_settings,
                saveTxPayVirtualCardTerms: 1,
              },
            };
            localStorage.setItem("access", JSON.stringify(newData));
          }
        }
      },
    }
  );

  const handleScroll = () => {
    const content: any = contentRef?.current;
    if (content) {
      const scrollTop = content?.scrollTop;
      const scrollHeight = content?.scrollHeight;
      const clientHeight = content?.clientHeight;

      const scrollPercentage: any =
        (scrollTop / (scrollHeight - clientHeight)) * 100;

      if (scrollPercentage >= 80 || scrollHeight <= clientHeight) {
        setIsScrolled80(true);
      }
    }
  };

  useEffect(() => {
    const content: any = contentRef?.current;
    if (content) {
      content.addEventListener("scroll", handleScroll);
      // Check if content fits without scrolling on mount
      if (content.scrollHeight <= content.clientHeight) {
        setIsScrolled80(true);
      }
      window.addEventListener("resize", handleScroll);
    }

    return () => {
      if (content) {
        content.removeEventListener("scroll", handleScroll);
      }
      window.removeEventListener("resize", handleScroll);
    };
  }, []);

  useEffect(() => {
    handleScroll();
  }, [layout.htmlFontSize]);

  return (
    <div
      className={`policyPopup fixed top-0 ${
        layout.asideMenuOpen ? "md:left-[13.875rem]" : "md:left-[3.75rem]"
      } right-0 bottom-0 z-[9999] px-5 py-[3.75rem] bg-black bg-opacity-[70%] flex items-center justify-center transition-opacity duration-200  opacity-100 visible`}
    >
      <div className="flex flex-col bg-white rounded-xl w-full max-w-[45.875rem] h-full">
        <div className="flex justify-between border-b">
          <h1 className="text-sm15 leading-6 font-semibold px-5 py-2">
            TX Pay Virtual Cards Terms & Conditions
          </h1>
          <button
            className="flex-none flex items-center justify-center w-10 h-full min-h-10 border-l"
            onClick={() => navigate("../", { replace: true })}
          >
            <IKTSVG path={close} svgClassName="w-2.5 h-2.5 fill-violet-500" />
          </button>
        </div>
        <div
          ref={contentRef}
          className="p-[1.875rem] h-full leading-[1.0625rem] text-xs font-normal overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full"
        >
          <h2 className="mb-3.5 font-bold text-sm12">How TX Pay Cards Work</h2>
          <p className="mb-6 last:mb-0 text-sm12 text-gray-600">
            TX Pay Cards are virtual debit cards designed to make managing
            purchases for your ticketing business seamless and efficient. These
            cards are created directly from your TX Pay wallet and can be used
            for transactions on event ticketing platforms that accept debit
            cards. Here’s a quick overview of their key features:
          </p>
          <ul className="list-disc pl-6">
            <li className="mb-4">
              <h2 className="text-sm12 font-bold">Creation and Activation:</h2>
              <p className="text-sm12 text-gray-600">
                Virtual cards can be created in your account’s base currency.
                After creation, cards enter a{" "}
                <span className="italic">Pending</span> status and are typically
                activated within <span className="font-bold">24-48 hours</span>.
                Once active, both the primary account holder and the user who
                created the card will receive an email confirmation.
              </p>
            </li>
            <li className="mb-4">
              <h2 className="text-sm12 font-bold">Usage:</h2>
              <p className="text-sm12 text-gray-600">
                TX Pay Cards are tailored for use on{" "}
                <span className="font-bold">event ticketing platforms</span>{" "}
                only. To complete purchases, simply input the card details (card
                number, expiry date, CVV) during checkout. Each card will be
                registered under the{" "}
                <span className="font-bold">
                  primary account holder’s full name
                </span>
                , ensuring compliance with financial regulations. Please note
                that cards cannot be used for any other type of service or
                transaction.
              </p>
            </li>
            <li className="mb-4">
              <h2 className="text-sm12 font-bold">Seller Level Benefits:</h2>
              <p className="text-sm12 text-gray-600">
                The number of free TX Pay Cards available to your account
                depends on your <span className="font-bold">Seller Level</span>:
                higher levels allow more free cards. Additional cards may be
                introduced as a paid feature in the future. Read more about
                Seller Levels{" "}
                <a
                  target="_new"
                  href="https://help.tixstock.com/en/articles/10429869-seller-levels-unlock-benefits-and-rewards"
                  className="text-indigo-500 hover:text-violet-500 underline"
                  rel="noreferrer"
                >
                  here
                </a>
                .
              </p>
            </li>
            <li className="mb-4">
              <h2 className="text-sm12 font-bold">Managing Cards:</h2>
              <p className="text-sm12 text-gray-600">
                While you can deactivate cards that are no longer needed, they
                cannot be deleted due to the requirement to maintain transaction
                records.
              </p>
            </li>
            <li className="mb-4">
              <h2 className="text-sm12 font-bold">Funds and Refunds:</h2>
              <p className="text-sm12 text-gray-600">
                Funds on your virtual card cannot currently be transferred back
                to your TX Pay Wallet. If a refund is issued for a purchase, the
                amount will be returned directly to the card.
              </p>
            </li>
          </ul>
          <p className="mb-6 last:mb-0 text-sm12 text-gray-600">
            You can read more about TX Pay Cards and how to use it in{" "}
            <a
              target="_new"
              href="https://help.tixstock.com/en/articles/10420340-tx-pay-cards-virtual-debit-cards-for-sellers"
              className="text-indigo-500 hover:text-violet-500 underline"
              rel="noreferrer"
            >
              this helpful guide
            </a>
            .
          </p>
        </div>
        <div className="px-5 py-3 border-t flex gap-5 items-center justify-between">
          <div className="inline-flex max-w-[80%]">
            <input
              id="terms_con-checkbox"
              type="checkbox"
              checked={isChecked}
              disabled={!isScrolled80 || isLoading}
              onChange={(e) => setIsChecked(e.target.checked)}
              name="default"
              className={`w-4 h-4 text-violet-500 bg-gray-100 border-gray-300 rounded focus:ring-violet-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 disabled:bg-gray-500 disabled:pointer-events-none peer`}
            />
            <label
              htmlFor="terms_con-checkbox"
              className="ml-2 text-sm12 font-medium text-gray-600 peer-disabled:text-gray-300 peer-disabled:pointer-events-none cursor-pointer"
            >
              By ticking the box, I confirm that I have read and accept the
              terms and conditions of Tixstock and its third-party provider for
              TX Pay Cards.{" "}
              <a
                target="_new"
                href="https://connexpay.com/connexpay-terms-and-conditions/"
                className="text-indigo-500 hover:text-violet-500 underline"
                rel="noreferrer"
              >
                Read Terms and Conditions here
              </a>
              .
            </label>
          </div>
          <button
            type="button"
            className={`relative z-10 border inline-flex items-center transition font-semibold rounded text-sm13 px-2.5 py-0.5 ${
              !isChecked
                ? "pointer-events-none text-gray-400 bg-gray-100 border-gray-100"
                : "text-white bg-violet-500  hover:bg-indigo-500 hover:border-indigo-500"
            } `}
            onClick={() => refetch()}
            disabled={(!isScrolled80 || isLoading) && !isChecked}
          >
            I accept
          </button>
          {isLoading && <ProgressBardLoader secondCounter={1} />}
        </div>
      </div>
    </div>
  );
};
