export const TransferIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.499"
    height="12.498"
    viewBox="0 0 12.499 12.498"
    {...props}
  >
    <path
      id="Union_141"
      data-name="Union 141"
      d="M1744-15639.75a6.252,6.252,0,0,1,6.247-6.251,6.252,6.252,0,0,1,6.251,6.251,6.251,6.251,0,0,1-6.251,6.247A6.25,6.25,0,0,1,1744-15639.75Zm1.249,0a5.007,5.007,0,0,0,5,5,5,5,0,0,0,5-5,5,5,0,0,0-5-5A5.006,5.006,0,0,0,1745.249-15639.75Zm4.335,3v-3h-2.336l3-3,3,3h-2.333v3Z"
      transform="translate(-1744 15646.001)"
      fill=""
    />
  </svg>
);
