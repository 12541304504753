import { decimalNumber, getCurrencySymbol } from "helpers/Functions";
import moment from "moment";
import React, { useContext } from "react";
import DataCell from "../../../../../tx_account/Table/component/DataCell";
import { PersonalDetailsContext } from "../../../core/PersonalDetailsProvider";

const ReferralItemContent = ({ list }: any) => {
  const layout = useContext(PersonalDetailsContext);

  //  HEADER GET COLUMNS
  const getColumnItems = () => {
    return layout.columnItems?.filter(
      (columnItem) => columnItem.checked === true
    );
  };

  const getFilterIndexForCheckbox = (id: any) => {
    let index = layout.columnItems.findIndex((item: any) => item.id === id);
    return index;
  };

  return (
    <>
      {getColumnItems().map((columnItem: any) => {
        switch (columnItem.id) {
          case 0:
            return (
              <React.Fragment key={columnItem?.id}>
                {layout.columnItems[`${getFilterIndexForCheckbox(0)}`]
                  ?.checked && (
                  <DataCell
                    value={list?.referrer_name}
                    classname="block"
                    padding="pl-5"
                    width="w-[11.25rem]"
                  />
                )}
              </React.Fragment>
            );
          case 1:
            return (
              <React.Fragment key={columnItem?.id}>
                {layout.columnItems[`${getFilterIndexForCheckbox(1)}`]
                  ?.checked && (
                  <DataCell
                    value={list?.referee_name}
                    classname="block"
                    width="w-[11.25rem]"
                  />
                )}
              </React.Fragment>
            );
          case 2:
            return (
              <React.Fragment key={columnItem?.id}>
                {layout.columnItems[`${getFilterIndexForCheckbox(2)}`]
                  ?.checked && (
                  <DataCell
                    value={
                      list?.referral_start_date
                        ? moment(list?.referral_start_date).format("DD/MM/YYYY")
                        : "-"
                    }
                    classname="block"
                    width="w-[7.5rem]"
                  />
                )}
              </React.Fragment>
            );
          case 3:
            return (
              <React.Fragment key={columnItem?.id}>
                {layout.columnItems[`${getFilterIndexForCheckbox(3)}`]
                  ?.checked && (
                  <DataCell
                    value={
                      moment(list?.referral_expiry_date).format("DD/MM/YYYY") ??
                      "-"
                    }
                    classname="block"
                    width="w-[7.5rem]"
                  />
                )}
              </React.Fragment>
            );
          case 5:
            return (
              <React.Fragment key={columnItem?.id}>
                {layout.columnItems[`${getFilterIndexForCheckbox(5)}`]
                  ?.checked && (
                  <DataCell
                    value={`${getCurrencySymbol(list?.currency)}${decimalNumber(
                      list?.total_eligible_earnings
                    )}`}
                    classname="block"
                    width="w-[7.5rem]"
                  />
                )}
              </React.Fragment>
            );
          case 6:
            return (
              <React.Fragment key={columnItem?.id}>
                {layout.columnItems[`${getFilterIndexForCheckbox(6)}`]
                  ?.checked && (
                  <DataCell
                    value={`${getCurrencySymbol(list?.currency)}${decimalNumber(
                      list?.total_paid_earnings
                    )}`}
                    classname="block"
                    width="w-[7.5rem]"
                  />
                )}
              </React.Fragment>
            );
        }
      })}
    </>
  );
};

export default ReferralItemContent;
