import {
  countHandleChange,
  decimalNumber,
  getCurrencySymbol,
} from "helpers/Functions";
import { useContext } from "react";
import CheckboxFilter from "../../../tx_trade/component/filters/CheckboxFilter";
import { SUBSCRIBE_TAB } from "../core/const";
import { PersonalDetailsContext } from "../core/PersonalDetailsProvider";
import TabWrapper from "./TabWrapper";

const CountBoxWrapper = ({ loading, options, counts }: any) => {
  const layout = useContext(PersonalDetailsContext);
  const { referralFilterData, activeTab, setReferralFilterData } = layout;

  // RENDER COMPONENT
  const renderComponent = (option: any) => {
    switch (option) {
      case SUBSCRIBE_TAB.subscribeDetails:
        return (
          <div className="flex flex-nowrap pt-3 -mx-2.5 overflow-x-auto whitespace-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
            <CheckboxFilter
              title="Total teams"
              trackedLoading={loading}
              count={counts?.total_teams}
            />
            <CheckboxFilter
              title="Total active"
              trackedLoading={loading}
              count={counts?.total_active_teams}
              isCheckbox={true}
              checked={
                layout?.filterData?.statuses?.length > 0 &&
                layout?.filterData?.statuses?.find(
                  (status: any) => status?.name === "Active"
                )
              }
              name="Active"
              handleOnChange={(event: any) => {
                layout.setFilterData((prevFilterData: any) => {
                  let statuses: any;
                  const selectedStatus = layout.filterOptions.statuses.find(
                    (status: any) => {
                      return status.name === event.target.name;
                    }
                  );
                  if (event.target.checked) {
                    statuses = prevFilterData?.statuses
                      ? [...prevFilterData?.statuses, selectedStatus]
                      : [selectedStatus];
                  } else {
                    statuses = prevFilterData?.statuses?.filter(
                      (status: any) => {
                        return status?.name !== event.target.name;
                      }
                    );
                  }
                  const newFilterData = {
                    ...prevFilterData,
                    statuses,
                    page: 1,
                    per_page: 20,
                  };
                  return newFilterData;
                });
              }}
            />
            <CheckboxFilter
              title="Total pending"
              trackedLoading={loading}
              count={counts?.total_pending_teams}
              isCheckbox={true}
              checked={
                layout?.filterData?.statuses?.length > 0 &&
                layout?.filterData?.statuses?.find(
                  (status: any) => status?.name === "Awaiting Approval"
                )
              }
              name="Awaiting Approval"
              handleOnChange={(event: any) => {
                layout.setFilterData((prevFilterData: any) => {
                  let statuses: any;
                  const selectedStatus = layout.filterOptions.statuses.find(
                    (status: any) => {
                      return status.name === event.target.name;
                    }
                  );
                  if (event.target.checked) {
                    statuses = prevFilterData?.statuses
                      ? [...prevFilterData?.statuses, selectedStatus]
                      : [selectedStatus];
                  } else {
                    statuses = prevFilterData?.statuses?.filter(
                      (status: any) => {
                        return status?.name !== event.target.name;
                      }
                    );
                  }
                  const newFilterData = {
                    ...prevFilterData,
                    statuses,
                    page: 1,
                    per_page: 20,
                  };
                  return newFilterData;
                });
              }}
            />
          </div>
        );
      case SUBSCRIBE_TAB.referral:
        return (
          <div className="flex flex-nowrap pt-3 -mx-2.5 overflow-x-auto whitespace-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
            <CheckboxFilter
              title="Referral value"
              trackedLoading={loading}
              count={`${getCurrencySymbol(counts?.currency)}${decimalNumber(
                counts?.referral_earnings
              )}`}
            />
            <CheckboxFilter
              title={`Referral${
                Number(counts?.total_referrals) > 1 ? "s" : ""
              }`}
              trackedLoading={loading}
              count={counts?.total_referrals}
            />

            {/* ACTIVE & INACTIVE  */}
            {options?.data?.referral_statuses &&
            options?.data?.referral_statuses?.length > 0
              ? options?.data?.referral_statuses?.map(
                  (item: any, index: number) => {
                    const count = counts?.[item?.name?.toLowerCase()];
                    return (
                      <CheckboxFilter
                        key={index}
                        title={item?.name}
                        name={item?.name}
                        trackedLoading={loading}
                        isCheckbox={count > 0 ? true : false}
                        count={count}
                        checked={referralFilterData?.referralStatus?.find(
                          (find: any) => find?.id === item?.id
                        )}
                        handleOnChange={(data: any) =>
                          countHandleChange(
                            data,
                            options?.data?.referral_statuses,
                            "referralStatus",
                            setReferralFilterData
                          )
                        }
                      />
                    );
                  }
                )
              : null}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div
      className="bg-white accordion-collapse font-medium collapse show"
      id="inventoryaccordion"
      data-te-collapse-item
      data-te-collapse-show
      aria-labelledby="headingOne"
    >
      <div className="tab-panel bg-no-repeat bg-tradepattern bg-cover bg-indigo-500 z-[12] bg-opacity-[15%] px-5 pt-2.5 pb-0 flex after:content after:absolute after:bottom-0 after:left-0 after:w-full after:h-2 after:z-0 after:from-black/0 after:bg-gradient-to-b after:to-black/5 relative overflow-x-auto flex-nowrap overflow-hidden">
        <TabWrapper />
      </div>
      <div
        className={`tab_vontent opacity-100 transition-opacity duration-150 ease-linear block`}
        role="tabpanel"
        data-te-tab-active
      >
        <div className="px-5 pt-2">
          <div className="divide-gray-200 divide-y-2">
            {renderComponent(activeTab)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountBoxWrapper;
