const Label = ({
  data,
  asteriskColor = "text-red-500",
  required = false,
}: any) => {
  return (
    <label className="text-gray-500 leading-4 mb-1 block w-full text-sm12">
      {data}
      {required && <span className={`${asteriskColor} ml-1`}>*</span>}
    </label>
  );
};

export default Label;
