import { useContext } from "react";
import { Link } from "react-router-dom";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { decimalNumber } from "../../../helpers/Functions";
import { TX_TAB } from "../core/const";

const TxPayTab = ({
  tab,
  onClick,
  labelText,
  currency,
  amount,
  shimmer,
  route,
}: any) => {
  const globalLayout: any = useContext(LayoutContext);
  const isGodAdmin = globalLayout?.allowedAccess?.account_info?.is_god_admin;
  return (
    <Link
      to={`/tx-pay${route}`}
      className={`relative single-tab lg:px-4 px-2.5 py-[.5625rem]  lg:whitespace-normal whitespace-nowrap flex  items-center bg-white rounded-t-lg cursor-pointer mr-[1px] w-full gap-2 group hover:bg-opacity-100 lg2:max-w-[13.625rem] min-w-[10.625rem] justify-between ${
        tab === labelText
          ? "bg-opacity-100 z-10 after:bg-menuCorner after:bg-contain after:absolute after:left-full after:bottom-0 after:w-2 after:h-2 after:transition after:duration-200 after:brightness-0 after:invert after:-rotate-90 before:bg-menuCorner before:bg-contain before:absolute before:right-full before:bottom-0 before:w-2 before:h-2 before:transition before:duration-200 before:brightness-0 before:invert before:rotate-180"
          : "bg-opacity-60"
      } `}
      onClick={() => onClick(labelText)}
    >
      <h4 className={`lg:text-sm15 text-sm14 font-semibold  `}>{labelText}</h4>

      {shimmer && tab === labelText ? (
        <span
          className={`shimmer-effect flex items-center justify-center bg-violet-500 text-xxs text-white px-1.5 py-[.1563rem] rounded group-hover:bg-indigo-500 transition-all min-h-[1.25rem] min-w-[1.25rem]`}
        ></span>
      ) : (
        <span
          className={`flex items-center justify-center bg-violet-500 text-xxs text-white px-1.5 py-[.1563rem] rounded group-hover:bg-indigo-500 transition-all min-h-[1.25rem] min-w-[1.25rem] ${
            tab === labelText ? "!bg-indigo-500" : ""
          }`}
        >
          {currency}
          {/* {decimalNumber(amount)} */}
          {currency
            ? amount
              ? decimalNumber(amount)
              : "0"
            : amount
            ? amount
            : "0"}
        </span>
      )}
    </Link>
  );
};

export default TxPayTab;
