import { LayoutContext } from "components/core/LayoutProvider";
import DataCell from "pages/tx_account/Table/component/DataCell";
import React, { useContext, useState } from "react";
import { TableVirtuoso } from "react-virtuoso";
import UserMarketplaceEdit from "../UserMarketplaceEdit";
import TableEditAction from "./TableEditAction";
import TableHeaderRow from "./TableHeaderRow";
import TableRow from "./TableRow";

const Table = ({ style, ...props }: any) => (
  <table
    {...props}
    className="w-full text-xs text-left last-auto"
    style={{
      ...style,
    }}
  />
);
const TableHead = ({ style, ...props }: any) => (
  <thead
    {...props}
    className="text-xs text-gray-400 last-auto"
    style={{
      ...style,
      position: "sticky",
    }}
  />
);

const DataTable = ({
  data,
  paginateData,
  setTeamApiFilter,
  loading,
  viewForm,
  teamApiFilter,
}: any) => {
  const globalLayout = useContext(LayoutContext);
  const [height, setHeight] = useState<any | undefined>(undefined);
  let timeout: any;

  const [editUserMarkteplace, setEditUserMarketplace] = useState({
    status: false,
    data: undefined,
    team_id: undefined,
    user_id: undefined,
  });

  const handleOnEditClick = (data: any) => {
    setEditUserMarketplace((pre: any) => {
      return {
        ...pre,
        status: true,
        data: undefined,
        team_id: viewForm?.id,
        user_id: data?.id,
        isTxtrade: viewForm?.data?.tx_trade_user,
      };
    });
  };

  const handleCloseForm = () => {
    setEditUserMarketplace((pre: any) => {
      return {
        ...pre,
        status: false,
        data: undefined,
        team_id: undefined,
        user_id: undefined,
        isTxtrade: undefined,
      };
    });
  };

  const calculateHeight = (length: any) => {
    const baseHeight = 2.8125; // Height per item in rem
    const extraHeight = 2.5625; // Additional height in rem
    const maxHeight = 26.25; // Maximum height in rem

    return length > 10
      ? `${maxHeight}rem`
      : `${length * baseHeight + extraHeight}rem`;
  };
  return (
    <div
      className="accordion font-medium transition-all relative max-lg:!mr-0"
      id={`EventAccordion`}
    >
      <div className="accordion-item bg-white  !rounded overflow-hidden relative">
        <div
          id="EventCollapseBody"
          className="accordion-collapse collapse show"
          aria-labelledby="EventCollapseBody"
          data-te-collapse-show
        >
          <div className="accordion-body relative">
            <div
              className="inventory-table sales-table max-h-[26.25rem] overflow-x-auto  scrollbar-thumb-transparent scrollbar-thumb-rounded-full hover-style2"
              id={`tableId1`}
              style={{ height: height > 41 ? `${height}px` : "auto" }}
            >
              <div className="order-replacement-table hover-style2 !h-full tx-trade-table last-auto">
                <TableVirtuoso
                  data={data}
                  endReached={() => {
                    if (paginateData?.current_page < paginateData?.last_page) {
                      setTeamApiFilter((current: any) => {
                        let nextPage: number = paginateData?.current_page + 1;
                        return {
                          ...current,
                          page: nextPage,
                          per_page: 20,
                        };
                      });
                    }
                  }}
                  // useWindowScroll
                  style={{
                    height: calculateHeight(data?.length),
                  }}
                  // style={{
                  //   height: data?.length > 10 ? 420 : data?.length * 45 + 41,
                  // }}
                  overscan={200}
                  totalCount={data?.length}
                  atBottomThreshold={200}
                  id={"txtrade-inventory-lazyload"}
                  components={{
                    Table: Table,
                    TableHead: TableHead,
                    TableRow: (props) => {
                      return <TableRow {...props} />;
                    },
                  }}
                  fixedHeaderContent={() => (
                    <TableHeaderRow
                      setTeamApiFilter={setTeamApiFilter}
                      teamApiFilter={teamApiFilter}
                    />
                  )}
                  itemContent={(sIndex: any, list: any) => {
                    const { firstname, lastname, email, phone, country_code } =
                      list;
                    return (
                      <React.Fragment key={sIndex}>
                        <DataCell
                          value={`${firstname} ${lastname}`}
                          classname="block"
                          padding="pl-5"
                          width="w-[12rem] min-w-[12rem]"
                          // evtName={"!p-0"}
                        />
                        <DataCell
                          value={email}
                          classname="block"
                          // padding="pl-5"
                          width="w-[15rem] min-w-[15rem]"
                          evtName={"!p-0"}
                        />
                        <DataCell
                          value={`${country_code ? country_code : ""}${
                            phone ? phone : ""
                          }`}
                          classname="block"
                          // padding="pl-5"
                          width="w-[15rem] min-w-[15rem]"
                          evtName={"!p-0"}
                        />

                        <TableEditAction
                          sIndex={sIndex}
                          list={list}
                          handleOnEditClick={handleOnEditClick}
                          isTxtrade={!viewForm?.data?.tx_trade_user}
                        />
                      </React.Fragment>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && paginateData?.current_page > 1 && (
        <div
          id="custom-loader"
          className={`accordion  absolute left-0 right-0 w-full h-auto bottom-0  px-5 bg-white font-medium pt-2.5    z-[1] `}
        >
          <div
            className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect h-[1.875rem]`}
          ></div>
          <div
            className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect h-[1.875rem]`}
          ></div>
        </div>
      )}
      <UserMarketplaceEdit
        data={editUserMarkteplace}
        handleCloseForm={handleCloseForm}
      />
    </div>
  );
};

export default DataTable;
