import { IKTSVG } from "components/ui/IKTSVG";
import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";

interface TabInterface {
  imgPath?: any;
  title?: string;
  handleTab?: any;
  path: string;
}
const Tab: React.FC<TabInterface> = ({ imgPath, title, handleTab, path }) => {
  const params = useParams();
  const location = useLocation();

  const isTabActive = true;
  const isTitle = location.pathname === path;
  return (
    <>
      <Link
        to={path}
        replace
        className={`relative ${
          isTitle
            ? "bg-opacity-100 z-10 after:bg-menuCorner after:bg-contain after:absolute after:left-full after:bottom-0 after:w-2 after:h-2 after:transition after:duration-200 after:brightness-0 after:invert after:-rotate-90 before:bg-menuCorner before:bg-contain before:absolute before:right-full before:bottom-0 before:w-2 before:h-2 before:transition before:duration-200 before:brightness-0 before:invert before:rotate-180"
            : "bg-opacity-60"
        } single-tab lg:px-4 px-2.5 py-[.5625rem] lg2:max-w-[14.9375rem] min-w-[10.625rem lg:whitespace-normal whitespace-nowrap
        flex justify-between items-center bg-white rounded-t-lg cursor-pointer mr-[1px] w-full gap-2 hover:bg-opacity-100`}
        onClick={() => handleTab()}
      >
        <h4 className={`lg:text-sm15 text-sm14 font-semibold ${isTitle && ""}`}>
          {title}
        </h4>
        <IKTSVG
          className={`123 flex items-center justify-center fill-${
            isTitle ? "indigo" : "violet"
          }-500 transition`}
          path={imgPath}
          svgClassName={"w-4 h-4"}
        />
      </Link>
    </>
  );
};

export default Tab;
