import { useContext, useState } from "react";
import { useClickOutside } from "../../../helpers/Functions";
import { ConnectorContext } from "../core/ConnectorProvider";

const JsonTreeViewer = ({ data, basePath = "", error }: any) => {
  const [dropdownState, setDropdownState] = useState<{
    path: any;
    position: any;
  }>({ path: null, position: null });

  const layout = useContext(ConnectorContext);
  const [activeKey, setActiveKey] = useState("");

  const renderNode = (node: any, path: string, idPath: string) => {
    if (Array.isArray(node)) {
      return (
        <div className="ms-2 mt-4">
          {"["}
          <div className="flex flex-col gap-1">
            {node.map((item, index) => {
              const newPath = `${path}[]`;
              const newIdPath = `${idPath}[${index}]`;
              return (
                <div
                  key={newIdPath}
                  id={newIdPath}
                  className={`ms-2 ${
                    typeof item !== "object" ? "pl-1 w-max" : ""
                  }`}
                >
                  {typeof item === "object" ? (
                    ""
                  ) : (
                    <button
                      type="button"
                      className={`${
                        error ? "cursor-default " : ""
                      } underline px-2 ${
                        newIdPath === activeKey ? "bg-violet-100" : ""
                      }`}
                      onClick={(e) => {
                        e?.preventDefault();
                        !error && handleClick(newPath, newIdPath, e);
                      }}
                    >
                      {item.toString()}
                    </button>
                  )}
                  {renderNode(item, newPath, newIdPath)}
                </div>
              );
            })}
          </div>
          {"]"}
        </div>
      );
    } else if (typeof node === "object" && node !== null) {
      return (
        <div className="ms-2 mt-4">
          {"{"}
          <div className="flex flex-col gap-1">
            {Object.keys(node).map((key) => {
              const newPath = path !== "" ? `${path}.${key}` : `${key}`;
              const newIdPath = `${idPath}.${key}`;
              return (
                <div
                  key={newIdPath}
                  id={newIdPath}
                  className={`ms-2 ${
                    typeof node[key] !== "object" ? "pl-1 w-max" : ""
                  }`}
                >
                  <button
                    type="button"
                    className={`${
                      error ? "cursor-default " : ""
                    } underline px-2 ${
                      newIdPath === activeKey ? "bg-violet-80" : ""
                    }`}
                    onClick={(e) => {
                      e?.preventDefault();
                      !error && handleClick(newPath, newIdPath, e);
                    }}
                  >
                    {key}:
                  </button>
                  {typeof node[key] === "object" ? "" : node[key].toString()}
                  {renderNode(node[key], newPath, newIdPath)}
                </div>
              );
            })}
          </div>
          {"},"}
        </div>
      );
    } else {
      return null;
    }
  };

  const handleClick = (path: string, idPath: string, event: any) => {
    event.preventDefault();
    setActiveKey(idPath);
    const buttonElement = event.currentTarget as HTMLElement;
    const responseviewdiv = document.getElementById(
      "responseviewdiv"
    ) as HTMLElement;
    // const element = document?.getElementById(path);
    const responseviewdivRect = responseviewdiv?.getBoundingClientRect();
    const rect = buttonElement.getBoundingClientRect();
    // buttonElement.scrollTop;
    // const rect: any = element?.getBoundingClientRect();

    setDropdownState({
      path,
      position: {
        top: rect?.top + responseviewdiv?.scrollTop - responseviewdivRect?.top,
        left: rect?.right - responseviewdivRect?.left,
      },
    });
  };
  const [options, setOptions] = useState(
    layout.clientsOptionsData?.system_variables
  );
  const [floating, setFloating] = useState(false);

  // useEffect(() => {
  //   setOptions(() =>
  //     layout.clientsOptionsData?.system_variables?.filter((option: any) => {
  //       return !Object.keys(layout.mapping).includes(option);
  //     })
  //   );
  // }, [layout.mapping]);

  const domRef = useClickOutside(() => {
    setDropdownState({ path: null, position: null });
    setActiveKey("");
  });

  const getKeyFromValue = (object: any, value: any) => {
    return Object.keys(object)?.find((key: string) => {
      return object[key] === value;
    });
  };

  return (
    <div className="main">
      {renderNode(data, basePath, basePath)}
      {dropdownState.path && dropdownState.position && (
        <div
          className="dropdown bg-white border p-5 z-100 rounded-md shadow-md"
          style={{
            position: "absolute",
            top: dropdownState.position.top - 20,
            left: dropdownState.position.left + 30, // Adjust this value as needed to position the dropdown
          }}
          id="mapping-dropdown"
          ref={domRef}
        >
          {
            <>
              <div>
                <strong>Path:</strong> {dropdownState.path}
              </div>
              <select
                name=""
                id=""
                onChange={(e) => {
                  layout.setMapping((prev: any) => {
                    const key = getKeyFromValue(prev, dropdownState.path);
                    const value = e.target.value;
                    if (key) {
                      delete prev[key];
                    }
                    if (value) {
                      return { ...prev, [value]: dropdownState.path };
                    } else {
                      return { ...prev };
                    }
                    // return { ...prev, [e.target.value]: dropdownState.path };
                  });
                  // setOptions((options: any) => {
                  //   return options?.filter((option: any) => {
                  //     return option.id != e.target.value;
                  //   });
                  // });

                  // const key = getKeyFromValue(layout.mapping)
                  setDropdownState({ path: null, position: null });
                }}
                // value={()=>{
                //   function getKeyByValue(object:any, value:any) {
                //     return Object.keys(object).find(key =>
                //         object[key] === value)
                // }

                //   if(Object.values(layout?.mapping)?.includes(dropdownState?.path)){
                //     return getKeyByValue(layout?.mapping,dropdownState?.path)
                //   }else{
                //     return ""
                //   }
                // }}
                value={getKeyFromValue(layout.mapping, dropdownState.path)}
              >
                <option value={""}></option>;
                {options?.map((option: any) => {
                  return <option value={option?.id}>{option?.name}</option>;
                })}
              </select>
            </>
          }
        </div>
      )}
    </div>
  );
};

export default JsonTreeViewer;
