const VirtualTableRow = ({ popupData, ...props }: any) => {
  const list = props?.item;
  const matchedView =
    // showLedger?.index === tIndex && showLedger?.ledgerIndex === index;

    popupData?.status && popupData?.data?.id === list?.id;

  return (
    <tr
      {...props}
      id="tx-pay-row"
      className={`border-b last:border-b-0 bg-white   ${
        matchedView ? "matchView" : ""
      }
  `}
    />
  );
};

export default VirtualTableRow;
