import { useContext, useEffect, useRef } from "react";
import angleLeftSVG from "../../../assets/media/icons/standard_icons/angle_left.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { KTSVG } from "../../../components/ui/KTSVG";
import { NotificationsContext } from "../core/NotificationsProvider";
const ActivityDetail = ({ show, handleShow }: any) => {
  const layout = useContext(NotificationsContext);
  const canvasRef: any = useRef();
  let activity = layout.activities;

  // HANDLE NOTIFICATION VIEW PAGE
  const display = (isOpen: boolean) => {
    var pop_width =
      document.getElementsByClassName("notification_view")[0].clientWidth;
    if (isOpen) {
      // @ts-ignore
      document.getElementById("notification_list").style.marginRight = `${
        pop_width - 21
      }px`;
    } else if (!isOpen) {
      // @ts-ignore
      document.getElementById("notification_list").style.marginRight = "0px";
    }
  };

  // ADJUsT TABLE STYLING
  useEffect(() => {
    display(show?.show);
  }, [show]);

  // NAVIGATION BUTTON COMPONENT
  const NavigationButtons = ({
    handleNavigation,
    imgPath,
    className,
    id,
  }: any) => {
    return (
      <button
        className={`group relative p-1 md:p-1 w-6 h-6 rounded-l ${className} ${
          id === "next" && "rotate-180"
        }`}
        type="button"
        id={id}
        onClick={() => handleNavigation(id)}
      >
        <IKTSVG
          className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
          path={imgPath}
          svgClassName="w-1.5 h-[.5625rem]"
        />
      </button>
    );
  };

  // HANDLE NAVIGATION OF ORDER VIEW PAGE
  const handleNavigation = (nav: string) => {
    let newIndex = show?.index;
    if (nav === "back") {
      newIndex = newIndex === 0 ? activity.length - 1 : newIndex - 1;
    } else {
      newIndex = newIndex === activity.length - 1 ? 0 : newIndex + 1;
    }

    const preRecord = activity[newIndex];
    handleShow({
      show: true,
      data: preRecord,
      index: newIndex,
    });
  };

  return (
    <div
      data-te-modal-init
      className={`show offcanvas notification_view offcanvas-end fixed bottom-0 top-[3.75rem] md:top-[3.75rem] right-0 shadow-3xl bg-clip-padding outline-none transition duration-300 ease-in-out font-medium md:max-w-[690px] max-w-full w-full z-40`}
      tabIndex={-1}
      id="detailCanvas"
      aria-labelledby="detailCanvasLabel"
      ref={canvasRef}
    >
      <div className=" bg-white shadow-3xl w-full h-full ml-auto relative flex flex-col">
        <div className="offcanvas-header flex justify-between border-t border-b">
          <h5
            className="offcanvas-title mb-0 leading-normal text-sm15 font-semibold py-2 px-5"
            id="offcanvasRightLabel"
          >
            {show.data.created_at}
          </h5>
          <button
            type="button"
            className="flex items-center justify-center w-9 border-l box-content group"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              handleShow({
                show: false,
                data: "",
              });
              display(false);
            }}
          >
            <KTSVG
              className="fill-violet-800 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
              path="standard_icons/cross.svg"
              svgClassName="w-2.5 h-2.5"
            />
          </button>
        </div>

        <div className="offcanvas-body h-full w-full flex flex-row overflow-y-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full md:p-10 p-5 mb-1">
          <div className="not_content">
            <h4
              className="text-sm14 mb-4"
              dangerouslySetInnerHTML={{
                __html: show.data?.text,
              }}
            ></h4>
          </div>
        </div>

        {/* NAVIGATION BUTTONS */}
        <div className=" sticky bottom-0 right-0 w-full flex items-center justify-start px-5 py-3 bg-white z-10 border-t">
          <div className="flex items-center bg-gray-100 rounded">
            {/* BACK BUTTON */}
            <NavigationButtons
              id="back"
              imgPath={angleLeftSVG}
              className={show?.index === 0 ? "" : "pointer"}
              handleNavigation={() => handleNavigation("back")}
            />

            <NavigationButtons
              id="next"
              imgPath={angleLeftSVG}
              handleNavigation={() => handleNavigation("next")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityDetail;
