import moment from "moment";
import Calendar from "../../../../../assets/media/icons/standard_icons/calendar.svg";
import ClockOutline from "../../../../../assets/media/icons/standard_icons/clock-outline.svg";
import GeoIcon from "../../../../../assets/media/icons/standard_icons/geo_icon.svg";
import { IKTSVG } from "../../../../../components/ui/IKTSVG";
import { getTimeOnly } from "../../../../add_inventory/core/_functions";

const EventDetails = ({ data }: any) => {
  let orderAssign = data;

  return (
    <div className="evt_detail flex flex-wrap items-center border-b">
      <h5 className="ellips-text text-sm13 font-medium md:max-w-[18.75rem] md:w-auto w-full md:pl-5 md:pr-5 md:py-3 p-2.5 md:border-r leading-4 md:border-b-0 border-b">
        {orderAssign?.event_description}
      </h5>
      <span className="inline-flex items-center md:p-3 leading-4 p-2.5 md:pl-3.5 md:pr-3.5 text-sm12 md:w-auto w-1/2">
        <IKTSVG
          className="flex items-center justify-center min-w-[0.75] fill-black mr-2"
          path={Calendar}
        />
        {moment(orderAssign?.event_date).format("ddd, DD MMM YYYY")}
      </span>
      <span className="inline-flex items-center md:p-3 leading-4 p-2.5 md:pl-0 md:pr-3.5 text-sm12 md:w-auto w-1/2">
        <IKTSVG
          className="flex items-center justify-center min-w-[0.75] fill-black mr-2"
          path={ClockOutline}
        />
        {getTimeOnly(orderAssign?.event_date)}
      </span>
      <span
        className="inline-flex items-center md:p-3 leading-4 p-2.5 md:pl-0 md:pr-3.5 text-sm12 md:w-auto w-1/2"
        title={"Etihad Stadium, Manchester"}
      >
        <IKTSVG
          className="flex items-center justify-center min-w-[0.75] fill-black mr-2"
          path={GeoIcon}
          svgClassName="w-3 h-3"
        />
        <p className="ellips-text">{orderAssign?.address}</p>
      </span>
    </div>
  );
};

export default EventDetails;
