// @ts-nocheck

export const display = (isOpen: boolean, defaultId = "") => {
  let pop_width: any = "";
  if (defaultId) {
    pop_width = document.getElementById(defaultId)?.clientWidth;
  } else {
    // pop_width = document.getElementsByClassName("s_ticket")[0]?.clientWidth;
    const elements: any = document.getElementsByClassName("s_ticket");
    pop_width = elements.length > 0 ? elements?.[0]?.clientWidth : undefined;
    console.log(elements, "elements");
  }

  var body = document.querySelector("body");
  var html = document.querySelector("html");

  if (isOpen) {
    (document.getElementById("EventAccordion")?.style).marginRight = `${
      pop_width - 21
    }px`;
    // document.body.style.overflow = 'hidden';
    // body?.classList.add("overflow-hidden");
    body?.classList.add("scrollbar-none");
    html?.classList.add("scrollbar-none");
    console.log("closed");
  } else if (!isOpen) {
    if (document.getElementById("EventAccordion")?.style) {
      (document.getElementById("EventAccordion")?.style).marginRight = `0px`;
    }
    // document.body.style.overflow = 'unset';
    // body?.classList?.remove("overflow-hidden");
    body?.classList.add("scrollbar-none");
    html?.classList.add("scrollbar-none");
    const singleTicket = document.getElementById("singleTicket");
    if (singleTicket) {
      singleTicket.style.visibility = "hidden";
    }
  }
};

export const getDynamicHTML = (template, templateInputes) => {
  let updatedTemplateHTML = `<html><head>
    <style>
    .ql-syntax {
    background-color: #23241f;
    color: #f8f8f2;
    overflow: visible;
    }
    .ql-font-Roboto {
      font-family: "Roboto";
    }
    .ql-font-Raleway {
      font-family: "Raleway";
    }
    .ql-font-Montserrat {
      font-family: "Montserrat";
    }
    .ql-font-Lato {
      font-family: "Lato";
    }
    .ql-font-Rubik {
      font-family: "Rubik";
    }
    .ql-font-PT-Sans {
      font-family: "PT Sans";
    }
    .ql-font-sans-serif {
      font-family: "Sans Serif";
    }
    </style>
  </head><body>${template?.html_content}</body></html>`;

  templateInputes &&
    Object.keys(templateInputes).forEach((key) => {
      const regex =
        key === "Dynamic content"
          ? new RegExp(`\\[DYNAMIC_CONTENT\\]`, "g")
          : new RegExp(`\\[${key}:DYNAMIC_CONTENT\\]`, "g");
      updatedTemplateHTML = updatedTemplateHTML.replace(
        regex,
        templateInputes[key]
          ? `${key === "Dynamic content" ? "" : key + " : "}${
              templateInputes[key]
            }`
          : `[${key === "Dynamic content" ? "" : key + " : "}DYNAMIC_CONTENT]`
      );
    });

  return updatedTemplateHTML;
};
