/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from "lodash";
import moment from "moment";
import { useContext } from "react";
import { Tooltip } from "react-tooltip";
import RefreshSVG from "../../../assets/media/icons/other_icons/refresh.svg";
import CrossSVG from "../../../assets/media/icons/standard_icons/cross.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { AddInventoryContext } from "../core/AddInventoryProvider";
import PerformerFields from "./components/PerformerFields";

const PerformerTable = ({
  handleOnChange,
  resetFilters,
  resetIndividual,
  isLoading,
  id,
}: any) => {
  const layout = useContext(AddInventoryContext);
  let performerPaginationData =
    layout.performerPaginationData && layout.performerPaginationData;

  return (
    <>
      <div
        className={`${
          isLoading && `pointer-events-none opacity-40 bg-gray-50`
        } accordion-body border-t`}
      >
        <div className="flex flex-wrap">
          <div className="p-5 font-semibold text-sm13 border-r border-b text-center sm:w-auto">
            {performerPaginationData?.nbHits
              ? performerPaginationData?.nbHits > 1
                ? `${performerPaginationData?.nbHits} results`
                : `${performerPaginationData?.nbHits} result`
              : "N/A"}{" "}
          </div>
          <div className="flex items-center px-5 py-3 border-r gap-x-5 flex-auto border-b">
            {/* TIMEFRAME FILTER - CHIP */}
            {layout.performerKeyboardField?.timeframe?.name && (
              <>
                <div className="flex py-1 px-2.5 text-xs border rounded-full">
                  {/* Next 90 days */}
                  {layout.performerKeyboardField?.timeframe?.name}
                  <button
                    className="ml-2"
                    type="button"
                    data-te-chip-close
                    onClick={(data: any) =>
                      resetIndividual({
                        target: {
                          name: "timeframe",
                          value: "",
                        },
                      })
                    }
                  >
                    <IKTSVG path={CrossSVG} svgClassName="h-2 w-2" />
                  </button>
                </div>
              </>
            )}

            {/* DATE RANGE FILTER - CHIP */}
            {layout.performerKeyboardField?.start_date &&
              _.size(layout.performerKeyboardField?.start_date) > 0 && (
                <>
                  <div className="flex py-1 px-2.5 text-xs border rounded-full">
                    {moment(
                      layout.performerKeyboardField?.start_date?.startDate
                    ).format("DD/MM/YYYY")}
                    <span className="px-2">-</span>
                    {moment(
                      layout.performerKeyboardField?.start_date?.endDate
                    ).format("DD/MM/YYYY")}
                    <button
                      className="ml-2"
                      type="button"
                      data-te-chip-close
                      onClick={(data: any) =>
                        resetIndividual({
                          target: {
                            name: "start_date",
                            value: {},
                          },
                        })
                      }
                    >
                      <IKTSVG path={CrossSVG} svgClassName="h-2 w-2" />
                    </button>
                  </div>
                </>
              )}

            {/* RESET ALL FILTERS */}
            {((layout.performerKeyboardField?.start_date &&
              _.size(layout.performerKeyboardField?.start_date) > 0) ||
              layout.performerKeyboardField?.timeframe?.name) && (
              <button
                type="button"
                id="reset"
                className="group border flex items-center justify-center bg-white rounded-sm w-6 h-6 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition"
                onClick={(data: any) => resetFilters(data)}
              >
                <Tooltip
                  anchorId={`reset`}
                  content={`Reset`}
                  place="top"
                  variant="light"
                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10 "
                />
                <IKTSVG
                  className="fill-violet-500 group-hover:fill-white transition"
                  path={RefreshSVG}
                  svgClassName="w-3 h-3"
                />
              </button>
            )}
          </div>
          {/* <div className="flex flex-wrap items-center px-5 py-3 border-b gap-x-5 w-full lg:w-auto">
            <div className="flex items-center text-xs gap-x-2">
              <span>View</span>
              <select
                name=""
                id=""
                className="border-gray-300 focus:border-indigo-500 rounded py-1 pl-2 text-xs"
                value={performerPaginationData?.hitsPerPage}
                onChange={handleOnChange}
              >
                {pageSizeList.length > 0 &&
                  pageSizeList.map((page, pIndex) => {
                    if (
                      Math.ceil(performerPaginationData?.nbHits / 10) * 10 >=
                      page
                    ) {
                      return (
                        <option value={page} key={`project-${pIndex}`}>
                          {page}
                        </option>
                      );
                    } else if (
                      performerPaginationData?.nbHits < 10 &&
                      pIndex === 0
                    ) {
                      return (
                        <option value={10} key={`project-${pIndex}-default`}>
                          10
                        </option>
                      );
                    }
                  })}
              </select>
            </div>
            <div className="flex items-center text-xs gap-x-2 navigation_pg">
              <span>Page</span>

              <input
                type="number"
                min={1}
                value={performerPaginationData.page + 1}
                max={performerPaginationData?.nbPages}
                className="w-6 h-6 text-center border border-gray-300 rounded p-1 text-xs focus:border-indigo-500 pointer-events-none "
              />
              <span>of {performerPaginationData?.nbPages}</span>
              <ReactPaginate
                previousLabel={
                  <button
                    type="button"
                    className=" rounded-l bg-gray-100 hover:bg-gray-200 transition py-2 px-[.5625rem]"
                  >
                    <IKTSVG
                      className="flex items-center justify-center fill-violet-500 transition"
                      path={AngleLeft}
                    />
                  </button>
                }
                nextLabel={
                  <button
                    className="rounded-r bg-gray-100 hover:bg-gray-200 transition py-2 px-[.5625rem]"
                    type="button"
                  >
                    <IKTSVG
                      className="flex items-center justify-center fill-violet-500 transition"
                      path={AngleRight}
                    />
                  </button>
                }
                pageCount={Math.ceil(
                  performerPaginationData.nbHits /
                    performerPaginationData.hitsPerPage
                )}
                onPageChange={handleOnChange}
                // tailwind for pagination
                pageClassName="pg_num hidden flex cursor-pointer items-center justify-center w-9 h-9 shadow-[0_.25rem_.625rem_rgba(0,0,0,0.03)] text-sm font-normal transition-colors rounded-lg"
                activeClassName="active:bg-red-500"
              />
            </div>
          </div> */}
        </div>
        {}
        <PerformerFields id={id} />
      </div>
    </>
  );
};

export default PerformerTable;
