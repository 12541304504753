export const SettingIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="13.001"
    viewBox="0 0 16 13.001"
    {...props}
  >
    <g id="Group_893" data-name="Group 893" transform="translate(-24 -416)">
      <path
        id="Subtraction_83"
        data-name="Subtraction 83"
        d="M14,13H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H14a2,2,0,0,1,2,2v9A2,2,0,0,1,14,13ZM10.909,2.167V4.56a1.259,1.259,0,0,0,0,2.075v4.2h1.454v-4.2a1.259,1.259,0,0,0,0-2.075V2.167Zm-3.636,0V7.088a1.259,1.259,0,0,0,0,2.074v1.67H8.727V9.163a1.26,1.26,0,0,0,0-2.075V2.167Zm-3.636,0v1.67a1.259,1.259,0,0,0,0,2.075v4.92H5.091V5.913a1.26,1.26,0,0,0,0-2.075V2.167Z"
        transform="translate(24 416)"
        fill=""
      />
    </g>
  </svg>
);
