/* eslint-disable array-callback-return */
import { compareWeightageWithEvents } from "helpers/AssetHelpers";
import { showAlert } from "helpers/ShowAlert";
import _, { isArray } from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Tooltip } from "react-tooltip";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import TablePopup from "../../../components/tableComponent/TablePopup";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import ProgressBardLoader from "../../../components/ui/ProgressBardLoader";
import { handleScroll } from "../../../helpers/Functions";
import { AddInventoryContext } from "../core/AddInventoryProvider";
import {
  get2DaysBeforeDateOfEvent,
  visibilityMeterCalculationForKeyBoard,
} from "../core/_functions";
import {
  fetchMarketplacePrice,
  fetchMarketplacePriceWithSection,
  getEventDetails,
} from "../core/_requsets";
import SearchField from "../helpers/SearchField/SearchField";
import CollapseSVG from "./../../../assets/media/icons/other_icons/Collapse.svg";
import GreenSVG from "./../../../assets/media/icons/other_icons/Market-Insights-Green.svg";
import PublishGragSVG from "./../../../assets/media/icons/other_icons/hax-Publish-Gray.svg";
import ProcessSVG from "./../../../assets/media/icons/other_icons/spinner.svg";
import ListingLineSVG from "./../../../assets/media/icons/standard_icons/listing_lines.svg";
import SubstractionSVG from "./../../../assets/media/icons/standard_icons/subtraction.svg";
import WarningSVG from "./../../../assets/media/icons/standard_icons/warning1.svg";
import EventHeader from "./components/EventHeader";
import { MarketInsight } from "./components/MarketInsight";
import PublishHeader from "./components/PublishHeader";
import VirtuosoEventField from "./components/VirtuosoEventField";

const EventTable = React.memo(
  ({
    event,
    eIndex,
    handleTableField,
    eventCheckBox,
    searchInventoryOption,
    eventError,
    debouncedChangeHandler,
    isLoading,
    handlePPETableChange,
    handleSelectAll,
    list,
    setList,
    setIsOpenListingQualityPopup,
    virtuosoRef,
    instructionLetterTemplates,
  }: any) => {
    const layout = useContext(AddInventoryContext);

    const events = list;
    const [tableAlert, setTableAlert] = useState(false);
    const [loader, setLoader] = useState(false);
    const [msgAlert, setMsgAlert] = useState("");
    const coreLayout = useContext(LayoutContext);
    const conversation_rate = coreLayout?.conversationRate;
    const [collapse, setCollapse] = useState(true);
    const sellerData = coreLayout?.allowedAccess?.team_data?.seller_level;

    const handleCollapseAll = () => {
      setCollapse(!collapse);
      layout.isPublish && layout.SetIsEventOpen(!layout.isOpen);
    };
    let ticketQuantity =
      event["eventRecords"] &&
      event["eventRecords"].reduce(
        (acc: any, obj: any) => (acc += Number(obj.quantity)),
        0
      );

    function calculateAvgPriceForProceedValue(
      sectionname: any,
      markplaceArry: any
    ) {
      let sectionValue = sectionname?.name
        ?.trim()
        ?.toLowerCase()
        .split(" ")
        .join("");
      let markeplaceValueWithAvg: any =
        markplaceArry &&
        Object.entries(markplaceArry)?.map((item: any) => {
          return {
            id: item?.[0],
            name: item?.[0]?.trim()?.toLowerCase().split(" ").join(""),
            avgPrice: item?.[1]?.total?.price_avg,
          };
        });

      let avgPrice = markeplaceValueWithAvg?.find(
        (obj: any) => obj.name === sectionValue
      )?.avgPrice;

      let finalAvgRate = avgPrice ? avgPrice * conversation_rate : undefined;
      return finalAvgRate;
    }

    const handleSelectField = async (data: any) => {
      const tempEvent = (events: any) => {
        let foundValue = events.filter((record: any) => {
          if (record.id === "") {
            return record;
          }
        });
        return { ...foundValue[0], ...data };
      };
      let duplicateRecord = events.find(
        (event: any) => event.id === tempEvent(events).id
      );

      if (duplicateRecord && duplicateRecord.name) {
        setMsgAlert("Event is already selected");
        setTableAlert(true);
      } else {
        setLoader(true);
        //? CLONE TO NEW FASTER VERSION
        const recordMap = new Map(
          events.map((record: any) => [record.id, record])
        );
        const foundRecord: any = recordMap.get("");

        // CALL API TO GET DROPDOWN OPTIONS FOR NEW CLONED EVENT
        let eventDetails = await getEventDetails(data.id, "").then((res) => {
          return res?.data?.data[0];
        });
        let defaultDateToShip = get2DaysBeforeDateOfEvent(eventDetails?.date);

        let response: any;

        fetchMarketplacePriceWithSection(eventDetails)
          .then((res) => {
            response = res;
          })
          .catch(() => {})
          .finally(() => {
            // Create a new object with the required properties
            const updatedRecord: any = {
              // id: foundRecord.id,
              ...data,
              selected: true,
              markeInsightsForAvgPrice: response?.data?.results?.sections,
              eventRecords: foundRecord?.eventRecords.map(
                (eventRecord: any) => {
                  const isAllFilesUploaded =
                    eventRecord?.ticketFiles &&
                    Object.keys(eventRecord?.ticketFiles).length &&
                    Object.keys(
                      eventRecord?.ticketFiles?.filter(
                        (obj: any) => JSON.stringify(obj) !== "{}"
                      )
                    ).length >=
                      (eventRecord?.quantity
                        ? Number(eventRecord?.quantity)
                        : 0) &&
                    Object.keys(
                      eventRecord?.ticketFiles?.filter(
                        (obj: any) => JSON.stringify(obj) !== "{}"
                      )
                    ).length > 0
                      ? true
                      : false;

                  const marketplaces = isArray(eventDetails?.event_exchanges)
                    ? eventDetails?.event_exchanges?.filter(
                        (event_exchange: any) =>
                          eventDetails?.selected_event_exchanges?.includes(
                            event_exchange?.exchange_id
                          )
                      )
                    : [];
                  let category = eventDetails?.categories?.filter(
                    (category: any) =>
                      category?.name === eventRecord?.category?.name
                  );

                  let section = category?.[0]?.sections.filter(
                    (section: any) =>
                      section?.name === eventRecord?.section?.name ||
                      section?.name ===
                        category?.[0]?.name + eventRecord?.section?.name
                  );
                  const ticketFiles: any = [];
                  for (let i = 0; i < eventRecord?.quantity; i++) {
                    ticketFiles.push({});
                  }
                  let visibility: any = visibilityMeterCalculationForKeyBoard(
                    foundRecord,
                    {
                      ...eventRecord,
                      category: category?.[0] || {},
                      section: section?.[0] || {},
                      ticketFiles: ticketFiles,
                    }
                  );

                  const avg_price = calculateAvgPriceForProceedValue(
                    section?.[0],
                    response?.data?.results?.sections
                  );

                  return {
                    ...eventRecord,
                    marketplaces: marketplaces,
                    category: category?.[0] || {},
                    section: section?.[0] || {},
                    dateToShip: defaultDateToShip,
                    ticketFiles: ticketFiles,
                    additionalFile: null,
                    template: null,
                    inputs: null,
                    templateKeys: null,
                    visibility: visibility,
                    avg_price: avg_price,
                    ticketsInHands: isAllFilesUploaded
                      ? false
                      : eventRecord?.ticketsInHands,
                  };
                }
              ),
            };

            layout.setSearchFieldValue({
              ...eventDetails,
              ...updatedRecord,
            });

            let updatedKeyboard = {
              event_date_local: "",
              ...eventDetails,
              ...(updatedRecord && {
                ...updatedRecord,
                // eventRecords: [...layout?.keyboardField?.eventRecords],
                eventRecords: layout?.keyboardField?.eventRecords?.map(
                  (obj: any) => {
                    let visibility: any = visibilityMeterCalculationForKeyBoard(
                      updatedRecord,
                      { ...obj, category: {}, section: {} }
                    );
                    return {
                      ...obj,
                      category: {},
                      section: {},
                      visibility: visibility,
                    };
                  }
                ),
              }),
            };
            layout.setKeyboardField(updatedKeyboard);

            setList((preValue: any) => {
              return [
                {
                  ...eventDetails,
                  ...updatedRecord,
                  marketplaces: isArray(eventDetails?.event_exchanges)
                    ? eventDetails?.event_exchanges?.filter(
                        (event_exchange: any) =>
                          eventDetails?.selected_event_exchanges?.includes(
                            event_exchange?.exchange_id
                          )
                      )
                    : [],
                },
                ...preValue.filter((record: any) => {
                  if (record.id !== "") {
                    return record;
                  }
                }),
              ];
              // return preValue;
            });
            setLoader(false);
          });
      }
    };

    const getPublishedListingCount = useMemo(() => {
      return event?.eventRecords?.filter(
        (eventRecord: any) => eventRecord.status === "published"
      ).length;
    }, [event.eventRecords]);

    const getPartPublishedListingCount = useMemo(() => {
      return event?.eventRecords?.filter(
        (eventRecord: any) => eventRecord.status === "partPublished"
      ).length;
    }, [event.eventRecords]);

    const getFailedListingCount = useMemo(() => {
      return event?.eventRecords?.filter(
        (eventRecord: any) => eventRecord.status === "failed"
      ).length;
    }, [event.eventRecords]);

    const handleMarketplaceInsights = async (data: any) => {
      if (!data?.markeInsights) {
        setList((current: any) => {
          return current.map((innerEvent: any) => {
            return data?.id === innerEvent.id
              ? { ...innerEvent, marketInsightLoader: true }
              : innerEvent;
          });
        });

        let response = fetchMarketplacePrice(data)
          .then((res) => {
            response = res;
          })
          .catch((err) => {})
          .finally(() => {
            setList((current: any) => {
              return current.map((innerEvent: any) => {
                return data?.id === innerEvent.id
                  ? {
                      ...innerEvent,
                      markeInsights: response,
                      marketInsightLoader: false,
                    }
                  : innerEvent;
              });
            });
          });
      }
    };

    const MemoizedEventField = useMemo(() => {
      return (
        <VirtuosoEventField
          event={event}
          eIndex={eIndex}
          handleTableField={handleTableField}
          handlePPETableChange={handlePPETableChange}
          handleSelectAll={handleSelectAll}
          list={list}
          setList={setList}
          setIsOpenListingQualityPopup={setIsOpenListingQualityPopup}
          virtuosoRef={virtuosoRef}
          instructionLetterTemplates={instructionLetterTemplates}
        />
      );
    }, [event, list]);

    // COLLAPSE ALL BTN CLICKED DATA
    useEffect(() => {
      const collapsedAllBtn: any = document.getElementById("collpaseBtn");
      const handleButtonClick = () => {
        const buttonText = collapsedAllBtn.textContent;
        if (buttonText === "Expand all") {
          setCollapse(true);
        } else {
          setCollapse(false);
        }
      };
      if (collapsedAllBtn) {
        collapsedAllBtn?.addEventListener("click", handleButtonClick);
      }
      return () => {
        collapsedAllBtn &&
          collapsedAllBtn.removeEventListener("click", handleButtonClick);
      };
    }, []);

    // auto disabled left right table button
    useEffect(() => {
      const element = document.getElementById(event?.id + "lazyLoad");
      const stickyHead = document.getElementById(
        "EventCollapseBody" + event?.id
      );
      if (element) {
        const scrollHandler = () => handleScroll(element, stickyHead);
        element.removeEventListener("scroll", scrollHandler);
        element.addEventListener("scroll", scrollHandler);
        scrollHandler(); // Initial check
        setTimeout(() => {
          scrollHandler();
        }, 1000);
        return () => {
          element.removeEventListener("scroll", scrollHandler);
        };
      }
    }, [event]); // Empty dependency array to run only once on mount

    // TOOLTIP CONTENT - MARKET INSIGHT
    let marketSighTooltipContent: string = "";
    if (layout.isPublish) {
      if (getFailedListingCount > 0) {
        marketSighTooltipContent = "Failed";
      } else if (getPartPublishedListingCount > 0) {
        marketSighTooltipContent = "Part-Published";
      } else if (getPublishedListingCount > 0) {
        marketSighTooltipContent = "Published";
      }
    } else {
      marketSighTooltipContent = "Market Insights";
    }

    const handleEventIdClick = (id: any) => {
      setList((prev: any) => {
        return prev.map((event: any) => {
          if (event?.id === id) {
            let final = {
              ...event,
              isOpen: !event?.isOpen,
            };
            return final;
          } else {
            return event;
          }
        });
      });
    };

    return (
      <React.Fragment key={`EventRecord${event?.id}`}>
        {tableAlert && (
          <TablePopup
            isConfirm={false}
            message={msgAlert && msgAlert}
            cancelBtn={(data: any) => setTableAlert(data)}
          />
        )}

        <div
          className={`accordion font-medium add-inventory-accordion  ${
            event?.eventRecords?.length === 0 && "pointer-events-none"
          }
          ${event?.eventRecords?.length === 0 && layout.isPublish && "hidden"}
          mb-2.5`}
          id={`EventAccordion${event?.id}`}
        >
          {/* {JSON.stringify(event?.id)} */}
          {/* {JSON.stringify(new Date())} */}
          <div className="accordion-item bg-white shadow-md !rounded overflow-hidden">
            <div
              className={`flex accordion-header mb-0 event__header rounded-t text-white title-accordion ${
                layout.searchFieldValue?.id === event.id && !layout.isPublish
                  ? "active"
                  : ""
              }`}
            >
              {layout.isPublish === false && (
                <div
                  className={`flex items-center border-white/20 min-w-[2.5rem] ${
                    event.id === "" ? `border-r-0` : `border-r`
                  } pointer-events-auto `}
                >
                  <div className="min-w-[2.4375rem] max-w-[2.4375rem] text-center p-1">
                    <div className="relative w-3.5 h-3.5 mx-auto">
                      <input
                        className="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0 z-10 peer invisible"
                        type={`${layout.addAllEvents ? "checkbox" : "radio"}`}
                        name="eventRadio"
                        id={event?.id}
                        disabled={layout.addAllEvents ? true : false}
                        checked={
                          !layout.addAllEvents
                            ? layout.searchFieldValue?.id === event.id
                              ? true
                              : false
                            : event.selected
                        }
                        onChange={(e: any) => {
                          eventCheckBox(event, eIndex, e);
                        }}
                      />
                      <label
                        htmlFor={event?.id}
                        className="absolute top-0 left-0 w-full h-full border-2 border-white rounded-full scale-110 peer-checked:scale-90 peer-checked:bg-indigo-500 peer-checked:bg-none peer-checked:border-2 peer-checked:border-violet-500 before:absolute before:left-1/2 before:top-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:h-full before:w-full before:border before:rounded-full before:border-transparent peer-checked:before:border-indigo-500 before:scale-[1.8]"
                      ></label>
                    </div>
                  </div>
                </div>
              )}
              <div
                className="flex md:flex-nowrap flex-wrap w-full cursor-pointer 123 acc-click add-inventory-collapse"
                id={`EventHeading${event?.id}`}
                data-te-collapse-init
                data-te-target={`${
                  layout.isPublish === false && event.id
                    ? `#EventCollapseBody${event?.id}`
                    : `#EventCollapseBodyTwo${event?.id}`
                }`}
                aria-expanded="true"
                aria-controls={`${
                  layout.isPublish === false && event.id
                    ? `EventCollapseBody${event?.id}`
                    : `EventCollapseBodyTwo${event?.id}`
                }`}
                onClick={(e: any) => {
                  e.preventDefault();
                  handleCollapseAll();
                  handleEventIdClick(event.id);
                }}
              >
                <div className="accordion-search flex lg:flex-nowrap flex-wrap flex-auto w-full md:w-auto md:mr-4 md:flex-1">
                  <div
                    className={`${
                      event.id === ""
                        ? "xl:max-w-[34.31rem] lg:max-w-[34.31rem] !p-1.5 !pl-0"
                        : "xl:max-w-[15.85rem] lg:max-w-[12.65rem] md:border-r"
                    }  max-w-full w-full flex items-center px-4 lg:pb-2.5 pb-1 lg:pt-2.5 pt-3 text-sm  border-white/20 lg:whitespace-nowrap whitespace-normal ellips-text`}
                  >
                    {event?.id !== "" ? (
                      <p title={event.name}>{event.name}</p>
                    ) : (
                      <SearchField
                        id={`search-eventHeading${event?.id}`}
                        placeholder="Search event"
                        name="tableSearchField"
                        options={searchInventoryOption}
                        classNamePrefix="tableSearchField"
                        isForTable="true"
                        handleOnChange={(data: any, event: any) => {
                          const compareResult = compareWeightageWithEvents(
                            data?.seller_level_weightage,
                            sellerData,
                            data
                          );

                          if (compareResult?.flag) {
                            showAlert(compareResult?.message, 2);
                            return 0;
                          }

                          if (event?.action !== "clear") {
                            handleSelectField(data);
                          }
                        }}
                        onInputChange={debouncedChangeHandler}
                        isLoading={isLoading}
                      />
                    )}
                  </div>

                  {event.id !== "" ? (
                    <div className="flex flex-wrap flex-auto">
                      <EventHeader
                        title={moment(event.date).format("ddd, DD MMM YYYY")}
                        Imgpath={"standard_icons/calendar.svg"}
                        svgClassName="w-[.675rem] h-[.75rem]"
                        className="lg:max-w-[10.125rem] max-w-full md:w-full w-auto flex items-center px-4 lg:py-3 py-1 text-xs md:border-r border-white/20 whitespace-nowrap"
                      />
                      <EventHeader
                        title={moment(event.time, ["h:mm A"]).format("HH:mm")}
                        Imgpath={"standard_icons/clock-outline.svg"}
                        svgClassName="w-[.75rem] h-[.75rem]"
                        className="lg:max-w-[6.87rem] max-w-full md:w-full w-auto flex items-center px-4 lg:py-3 py-1 text-xs md:border-r border-white/20"
                      />
                      <EventHeader
                        title={event.address}
                        Imgpath={"standard_icons/geo_icon.svg"}
                        svgClassName="w-[.7813rem] h-[.7813rem]"
                        className="lg:w-auto w-full flex items-center flex-auto px-4 lg:pt-3 pt-1 lg:pb-3 pb-3 text-xs lg:border-none md:border-r border-white/20"
                      />
                    </div>
                  ) : (
                    <div
                      className="flex flex-wrap flex-auto"
                      data-te-collapse-init
                      data-te-target={`${
                        layout.isPublish === false
                          ? `#EventCollapseBody${event?.id}`
                          : `#EventCollapseBodyTwo${event?.id}`
                      }`}
                      aria-expanded="true"
                      aria-controls={`${
                        layout.isPublish === false
                          ? `EventCollapseBody${event?.id}`
                          : `EventCollapseBodyTwo${event?.id}`
                      }`}
                    ></div>
                  )}
                </div>
                <div className="flex ml-auto">
                  <div className="flex items-center">
                    <div className="flex items-center lg:flex-nowrap flex-wrap">
                      <Tooltip
                        anchorId={`listing-tooltip${event?.id}`}
                        content={
                          event["eventRecords"] &&
                          _.size(event["eventRecords"]) + " Listings"
                        }
                        place="top"
                        variant="light"
                        className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xxs font-medium  z-10"
                      />
                      <Tooltip
                        anchorId={`ticketQuantity${event?.id}`}
                        content={ticketQuantity + " Tickets"}
                        place="top"
                        variant="light"
                        className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xxs font-medium  z-10"
                      />
                      <div
                        id={`listing-tooltip${event?.id}`}
                        className="group flex-auto flex items-center mr-3 text-xs text-white hover:text-indigo-500"
                      >
                        <IKTSVG
                          className="flex items-center justify-center min-w-[0.75] fill-white mr-1.5 group-hover:fill-indigo-500"
                          path={ListingLineSVG}
                          svgClassName="w-3 h-[.5625rem]"
                        />
                        {event["eventRecords"] && _.size(event["eventRecords"])}
                      </div>
                      {!layout.isPublish && ticketQuantity > 0 ? (
                        <div
                          id={`ticketQuantity${event?.id}`}
                          className="group flex-auto flex items-center xl:mr-5 mr-4 text-xs hover:text-indigo-500"
                        >
                          <IKTSVG
                            className="flex items-center justify-center min-w-[0.75] fill-white mr-1.5 group-hover:fill-indigo-500"
                            path={SubstractionSVG}
                            svgClassName="w-3 h-[.5625rem]"
                          />
                          {ticketQuantity}
                        </div>
                      ) : (
                        <>
                          {/* <div
                          className={`flex-auto flex items-center text-xs mr-3`} >
                          <span className="flex items-center justify-center transition mr-1.5 ">
                            <KTSVG className="fill-white transition" path="standard_icons/check-circle.svg" />
                          </span>
                          2
                        </div> */}
                          {getPublishedListingCount > 0 && (
                            <div
                              className="flex-auto flex items-center text-xs mr-3"
                              id={`publised-${getPublishedListingCount}-${event?.id}`}
                            >
                              <span className="flex items-center justify-center fill-green-500 transition mr-1.5 ">
                                <Tooltip
                                  anchorId={`publised-${getPublishedListingCount}-${event?.id}`}
                                  content={
                                    getPublishedListingCount + " Published"
                                  }
                                  place="top"
                                  variant="light"
                                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xxs font-medium  z-10"
                                />
                                <IKTSVG
                                  className="flex items-center justify-center fill-green-500 transition w-[.875rem] h-[.875rem]"
                                  path={PublishGragSVG}
                                />
                              </span>
                              {getPublishedListingCount}
                            </div>
                          )}
                          {getPartPublishedListingCount > 0 && (
                            <div
                              className="flex-auto flex items-center text-xs mr-3"
                              id={`part-publised-${getPartPublishedListingCount}-${event?.id}`}
                            >
                              <span className="flex items-center justify-center fill-orange-500 transition mr-1.5 ">
                                <Tooltip
                                  anchorId={`part-publised-${getPartPublishedListingCount}-${event?.id}`}
                                  content={
                                    getPartPublishedListingCount +
                                    " Part-Published"
                                  }
                                  place="top"
                                  variant="light"
                                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xxs font-medium  z-10"
                                />
                                <IKTSVG
                                  className="flex items-center justify-center fill-orange-500 transition w-[.875rem] h-[.875rem]"
                                  path={PublishGragSVG}
                                />
                              </span>
                              {getPartPublishedListingCount}
                            </div>
                          )}
                          {getFailedListingCount > 0 && (
                            <div
                              className="flex-auto flex items-center text-xs mr-3 "
                              id={`failed-${getFailedListingCount}-${event?.id}`}
                            >
                              <span className="flex items-center justify-center fill-rose-500 transition mr-1.5 ">
                                <Tooltip
                                  anchorId={`failed-${getFailedListingCount}-${event?.id}`}
                                  content={"Failed"}
                                  place="top"
                                  variant="light"
                                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xxs font-medium  z-10"
                                />
                                <IKTSVG
                                  className="flex items-center justify-center fill-rose-500 transition w-[.875rem] h-[.875rem]"
                                  path={WarningSVG}
                                />
                              </span>
                              {getFailedListingCount}
                            </div>
                          )}
                          {/* <div className="mr-4 xl:mr-5"></div> */}
                        </>
                      )}
                    </div>
                  </div>
                  {event["eventRecords"] &&
                    _.size(event["eventRecords"]) !== 0 && (
                      <>
                        <Tooltip
                          anchorId={`market-insights-tooltip${event?.id}`}
                          content={marketSighTooltipContent}
                          place="top"
                          variant="light"
                          className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xxs font-medium  z-10"
                        />
                        <button
                          className={` flex items-center pb-2 mt-2 text-base text-gray-800 text-left 
                  !transition focus:outline-none group collapsed bg-transparent [&[data-te-collapse-collapsed]]:bg-transparent ${
                    !layout.isPublish
                      ? `bottom-arrow  [&:not([data-te-collapse-collapsed])]:fill-white [&:not([data-te-collapse-collapsed])]:bg-gray-750 [&:not([data-te-collapse-collapsed])]:rounded-t   
                  [&:not([data-te-collapse-collapsed])>.arrow]:block `
                      : ``
                  }  relative ${
                            event?.marketInsightLoader
                              ? `pointer-events-none`
                              : ``
                          }  pointer-events-auto `}
                          type="button"
                          data-te-collapse-init
                          data-te-collapse-collapsed
                          data-te-target={`#EventInsightCollapseBody${event?.id}`}
                          aria-expanded="true"
                          aria-controls={`EventInsightCollapseBody${event?.id}`}
                          onClick={() => handleMarketplaceInsights(event)}
                        >
                          <div id={`market-insights-tooltip${event?.id}`}>
                            {layout.isPublish ? (
                              <>
                                {/* {event.loader ? (
                            // <div className="publish-ripple-icon relative w-4 h-4 before:content-[''] before:absolute before:left-1/2 before:top-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:border-2 before:border-white before:rounded-full after:content-[''] after:absolute after:left-1/2 after:top-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:border-2 after:border-white after:rounded-full">
                            //   sss
                            // </div>
                          
                          ) : (
                            
                          )} */}
                                {getFailedListingCount > 0 ? (
                                  <IKTSVG
                                    className="flex items-center justify-center fill-rose-500 transition w-[.875rem] h-[.875rem]"
                                    path={WarningSVG}
                                  />
                                ) : getPartPublishedListingCount > 0 ? (
                                  <>
                                    <IKTSVG
                                      className="flex items-center justify-center fill-orange-500 transition w-[.875rem] h-[.875rem]"
                                      path={PublishGragSVG}
                                    />
                                  </>
                                ) : getPublishedListingCount > 0 ? (
                                  <>
                                    <IKTSVG
                                      className="flex items-center justify-center fill-green-500 transition w-[.875rem] h-[.875rem]"
                                      path={PublishGragSVG}
                                    />
                                  </>
                                ) : (
                                  <IKTSVG
                                    className="flex items-center justify-center  fill-white transition  "
                                    path={ProcessSVG}
                                    svgClassName="w-[.8125rem] h-[.8125rem]"
                                  />
                                )}
                              </>
                            ) : (
                              <>
                                <div className="flex items-center border border-white border-opacity-[13%] bg-white bg-opacity-[7%] py-1.5 px-2.5 rounded mkt-btn">
                                  {!layout.isPublish && (
                                    <>
                                      <div className="arrow hidden absolute w-2.5 h-2.5 right-full bottom-0 bg-white">
                                        <span className="block w-full h-full bg-violet-700 rounded-br-2xl"></span>
                                      </div>
                                      <div className="arrow hidden absolute w-2.5 h-2.5 left-full bottom-0 bg-white">
                                        <span className="block w-full h-full bg-violet-700 rounded-bl-2xl"></span>
                                      </div>
                                    </>
                                  )}
                                  <IKTSVG
                                    className="flex items-center justify-center  fill-green-600 transition group-hover:!fill-indigo-500 group-[:not([data-te-collapse-collapsed])]:fill-violet-500 "
                                    path={GreenSVG}
                                    svgClassName="w-[.8125rem] h-[.8125rem]"
                                  />
                                  <span className="text-sm12 text-white ml-1.5 leading-[1] font-medium group-[:not([data-te-collapse-collapsed])]:text-violet-800">
                                    Market Insights
                                  </span>
                                </div>
                              </>
                            )}
                          </div>
                        </button>
                      </>
                    )}
                  <Tooltip
                    anchorId={`collapseIcon-${event?.id}`}
                    content={`${collapse ? `Collapse` : "Expand"}`}
                    place="top"
                    variant="light"
                    className="!bg-white !opacity-100 !py-0.5 !px-1 !text-xxs font-medium  z-10 mt-3 "
                  />
                  <button
                    id={`collapseIcon-${event?.id}`}
                    className="group relative flex items-center py-[.9375rem] px-[.9375rem] text-base text-gray-800 text-left border-white/20 !transition focus:outline-none group h-full"
                    type="button"
                    data-te-collapse-init
                    // data-te-target={`#EventCollapseBody${event?.id}`}
                    data-te-target={`${
                      layout.isPublish === false
                        ? `#EventCollapseBody${event?.id}`
                        : `#EventCollapseBodyTwo${event?.id}`
                    }`}
                    aria-expanded="true"
                    // aria-controls={`EventCollapseBody${event?.id}`}
                    aria-controls={`${
                      layout.isPublish === false
                        ? `EventCollapseBody${event?.id}`
                        : `EventCollapseBodyTwo${event?.id}`
                    }`}
                    // onClick={() => handleCollapseAll()}
                  >
                    <IKTSVG
                      className="flex items-center justify-center group-[.collapsed]:rotate-180 fill-white group-hover:fill-indigo-500 transition group-[[data-te-collapse-collapsed]]:rotate-180 rotate-180}"
                      svgClassName="w-[.5625rem]  h-[.5625rem]"
                      path={CollapseSVG}
                    />
                  </button>
                </div>
              </div>
            </div>
            {!layout.isPublish && <MarketInsight event={event} />}

            {layout.isPublish ? (
              <PublishHeader
                event={event}
                eIndex={eIndex}
                handleTableField={handleTableField}
                eventError={eventError}
                list={list}
                setList={setList}
                handlePPETableChange={handlePPETableChange}
                handleSelectAll={handleSelectAll}
                setIsOpenListingQualityPopup={setIsOpenListingQualityPopup}
                instructionLetterTemplates={instructionLetterTemplates}
              />
            ) : (
              MemoizedEventField
            )}
          </div>
        </div>
        {loader && <ProgressBardLoader secondCounter={0.5} />}
      </React.Fragment>
    );
  }
);

export default EventTable;
