import { IKTSVG } from "components/ui/IKTSVG";
import { KTSVG } from "components/ui/KTSVG";
import { useContext, useEffect, useState } from "react";
import notifyIcon from "../../../assets/media/icons/other_icons/notifyIcon.svg";
import CalenderSVG from "../../../assets/media/icons/standard_icons/calendar.svg";
import ClockOutline from "../../../assets/media/icons/standard_icons/ClockOutline";

import { LayoutContext } from "components/core/LayoutProvider";
import ProgressBardLoader from "components/ui/ProgressBardLoader";
import { checkWeightageOfEvent } from "helpers/AssetHelpers";
import { LISTINGS_UTILS } from "helpers/const";
import { showAlert } from "helpers/ShowAlert";
import moment from "moment";
import { getSalesEventDateRange } from "pages/inventory/core/_functions";
import TooltipPortal from "pages/tx_trade/table/component/TooltipPortal";
import { useNavigate } from "react-router-dom";
import geoIcon from "../../../assets/media/icons/standard_icons/geo_icon.svg";
import { DashboardContext } from "../core/DashboardProvider";

const OnsalesDetailsModal = () => {
  const layout = useContext(DashboardContext);
  const coreLayout = useContext(LayoutContext);
  const performer = layout.selectedOnSalesPerformer?.performer;
  const navigate = useNavigate();
  const isGodAdmin = coreLayout?.allowedAccess?.account_info?.is_god_admin;
  const [createListingLoader, setCreateListingLoader] = useState<any>(false);

  const sellerData = coreLayout?.allowedAccess?.team_data?.seller_level;
  useEffect(() => {
    if (layout?.selectedOnSalesPerformer?.status === true) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [layout?.selectedOnSalesPerformer]);
  return (
    <>
      {layout?.selectedOnSalesPerformer?.status === true && (
        <div className="fixed top-0 left-0 w-full h-full bg-black/70 z-40"></div>
      )}
      <div
        data-te-modal-init
        className={`offcanvas offcanvas-end add_teammember fill_canvas  fixed bottom-0 top-[3.75rem] right-0 shadow-lg bg-clip-padding outline-none transition duration-300 ease-in-out font-medium ${
          coreLayout?.asideMenuOpen
            ? "md:max-w-[calc(100%_-_13.8125rem)]"
            : "md:max-w-[calc(100%_-_3.75rem)]"
        } w-full upload-ticket-popup z-40  ${
          layout?.selectedOnSalesPerformer?.status === true && "show"
        }`}
        // tabIndex={-1}
        id="onsales"
        aria-labelledby="onsalesLabel"
      >
        <div className="md:max-w-[43.125rem] max-w-full bg-white shadow-3xl w-full h-full ml-auto relative flex flex-col">
          <div className="offcanvas-header flex justify-between border-t border-b">
            <h5
              className="offcanvas-title mb-0 leading-normal text-sm15 font-semibold py-2 px-5"
              id="offcanvasRightLabel"
            >
              Onsale
            </h5>
            <div className="buttons flex flex-wrap">
              <button
                type="button"
                className="flex items-center justify-center w-9 border-l box-content group"
                data-te-dismiss="offcanvas"
                aria-label="Close"
                onClick={() => {
                  layout.setSelectedOnSalesPerformer("");
                }}
              >
                <KTSVG
                  className="fill-violet-500 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
                  path="standard_icons/cross.svg"
                  svgClassName="w-2.5 h-2.5"
                />
              </button>
            </div>
          </div>
          <div
            className="offcanvas-body h-full w-full flex-row overflow-y-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full px-5 py-5 mb-1"
            id="onsalesbody"
          >
            <div className="firstRow p-3.5 border mb-5 rounded">
              <div className="title mb-3 relative pr-[1.125rem]">
                <h3 className="text-sm13 font-medium leading-[1.0625rem]">
                  {performer?.performer_name}
                  {performer?.events?.length > 1 &&
                    ` (${performer?.events?.length} events)`}
                </h3>
                {performer?.starting_soon === 1 && (
                  <div className="absolute top-0 right-0">
                    <IKTSVG
                      path={notifyIcon}
                      svgClassName="w-[.5625rem] h-2.5"
                      className="w-[1.125rem] h-[1.125rem] bg-rose-500 flex items-center justify-center rounded-full cursor-pointer hover:bg-indigo-500"
                      dataTooltipID={`onsales-card-${performer?.performer_name}-${performer?.start_event_date}`}
                    />
                    <TooltipPortal
                      id={`onsales-card-${performer?.performer_name}-${performer?.start_event_date}`}
                      content={`Starting soon`}
                      className="!bg-white !py-0.5 !px-1.5 font-medium !text-xxs z-[1045] mt-1.5 text-center !opacity-100"
                    />
                  </div>
                )}
              </div>
              <div className="eventInfo flex flex-wrap gap-x-2.5 gap-y-1">
                <div className="singleInfo">
                  <p className="flex items-center text-xs text-gray-500 font-medium">
                    <IKTSVG
                      path={CalenderSVG}
                      className="mr-1.5"
                      svgClassName="w-3 h-3 fill-gray-500 "
                    />
                    {getSalesEventDateRange(performer)}
                  </p>
                </div>
                <div className="singleInfo">
                  <p className="flex items-start text-xs text-gray-500 font-medium">
                    <IKTSVG
                      path={geoIcon}
                      className="mr-1.5 mt-0.5"
                      svgClassName="w-3 h-3 fill-gray-500 "
                    />
                    {performer?.venue}
                  </p>
                </div>
              </div>
              <div className="eventdetails px-2.5 py-[.5625rem] mt-[.9375rem] bg-gray-100/50 rounded group-hover:bg-indigo-500 group-hover:bg-opacity-[7%]">
                <div className="-mx-[.3125rem] flex flex-wrap gap-y-[.375rem]">
                  {performer?.pre_sale_date && (
                    <div className="singleDetail w-full font-medium px-[.3125rem]">
                      <label className="leading-[.875rem] text-xxs text-gray-500 mb-[.1875rem] flex">
                        Pre-sale
                      </label>
                      <div className=" flex flex-wrap justify-between">
                        <h4 className="text-sm12 leading-4">
                          {moment(performer?.pre_sale_date).format(
                            "ddd, DD MMM YY HH:mm"
                          )}{" "}
                          {/* <span className="text-gray-500 inline-block">
                        (Presale ends Day, 00 Mon YY 00:00)
                      </span> */}
                        </h4>
                        {/* <div className="links flex items-center">
                      <a
                        href={urlToCopy}
                        className="text-sm12 text-violet-500 leading-[.9375rem] flex"
                      >
                        O2 Priority (link)
                      </a>

                      <IKTSVG
                        path={CloneIcon}
                        className="ml-[0.4688rem] cursor-pointer"
                        svgClassName="w-[0.7813rem] h-[0.6563rem] fill-violet-500 hover:fill-indigo-500"
                      />
                    </div> */}
                      </div>
                    </div>
                  )}
                  <div className="singleDetail w-full font-medium px-[.3125rem]">
                    <label className="leading-[.875rem] text-xxs text-gray-500 mb-[.1875rem] flex">
                      On-sale
                    </label>
                    <div className=" flex flex-wrap justify-between">
                      <h4 className="text-sm12 leading-4">
                        {moment(performer?.on_sale_date).format(
                          "ddd, DD MMM YY HH:mm"
                        )}
                      </h4>
                      {/* <div className="links flex items-center">
                      <a
                        href={urlToCopy}
                        className="text-sm12 text-violet-500 leading-[.9375rem] flex"
                      >
                        Ticketmaster (link)
                      </a>
                      <IKTSVG
                        path={CloneIcon}
                        className="ml-[0.4688rem] cursor-pointer"
                        svgClassName="w-[0.7813rem] h-[0.6563rem] fill-violet-500 hover:fill-indigo-500"
                      />
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h4 className="flex w-full text-sm13 leading-4 font-semibold mb-[0.9375rem]">
              {performer?.total_events}{" "}
              {performer?.total_events > 1 ? "events" : "event"}
            </h4>

            <div className="border mb-5 rounded">
              {performer?.events?.map((event: any, index: any) => {
                return (
                  <div
                    className={`p-3.5 ${
                      index !== performer?.total_events - 1 && "border-b"
                    }`}
                  >
                    <div className="title mb-2 relative flex flex-wrap gap-2.5 justify-between items-center">
                      <h3 className="text-sm13 font-medium leading-4">
                        {event?.event_name}
                      </h3>
                      {!isGodAdmin && (
                        <button
                          className="flex items-center justify-center px-1.5 py-1 ml-auto disabled:bg-gray-100 disabled:text-gray-400 text-xxs font-semibold rounded bg-violet-500 text-white hover:bg-indigo-500 disabled:hover:bg-gray-100 max-h-5"
                          disabled={event?.create_listing === 0}
                          // onClick={() => {
                          //   document
                          //     .getElementById("onsale-close-button")
                          //     ?.click();
                          //   navigate(`..${LISTINGS_UTILS?.addListings?.url}`, {
                          //     state: event?.event_id,
                          //     // replace: true,
                          //   });
                          // }}

                          onClick={async () => {
                            try {
                              let selectedEventsIDS = [{ id: event?.event_id }];
                              setCreateListingLoader(true);

                              const { checkWeightage, level } =
                                await checkWeightageOfEvent(
                                  selectedEventsIDS,
                                  coreLayout,
                                  sellerData
                                );

                              if (checkWeightage) {
                                const message = `
                                                                  Seller Level [${level}] is not eligible to create listings for this event`;
                                showAlert(message, 2);
                              } else {
                                navigate(
                                  `..${LISTINGS_UTILS?.addListings?.url}`,
                                  {
                                    replace: true,
                                    state: event?.event_id,
                                  }
                                );
                              }
                            } catch (error) {
                              console.error(
                                "Error while calling the API:",
                                error
                              );
                            } finally {
                              setCreateListingLoader(false);
                            }
                          }}
                        >
                          Create listing
                        </button>
                      )}
                    </div>
                    <div className="eventInfo flex flex-wrap gap-x-2.5 gap-y-1">
                      <div className="singleInfo">
                        <p className="flex items-center text-xs text-gray-500 font-medium">
                          <IKTSVG
                            path={CalenderSVG}
                            className="mr-1.5"
                            svgClassName="w-3 h-3 fill-gray-500 "
                          />
                          {moment(event?.event_date).format("ddd, DD MMM YYYY")}
                        </p>
                      </div>
                      <div className="singleInfo">
                        <p className="flex items-center text-xs text-gray-500 font-medium">
                          <ClockOutline
                            className={`mr-1.5 w-3 h-3 fill-gray-500`}
                          />{" "}
                          {moment(event?.event_date).format("HH:mm")}
                        </p>
                      </div>
                      <div className="singleInfo">
                        <p className="flex items-start text-xs text-gray-500 font-medium">
                          <IKTSVG
                            path={geoIcon}
                            className="mr-1.5 mt-0.5"
                            svgClassName="w-3 h-3 fill-gray-500 "
                          />
                          {`${event?.venues}, ${event?.city}, ${event?.country}`}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* <div className=" offcanvas-footer bottom-0 right-0 w-full flex flex-wrap justify-between px-5 py-1.5 bg-white z-10 border-t min-h-[3.125rem]">
          <div className="r_btn gap-2.5 flex flex-wrap items-center justify-end w-full">
            <button
              className="relative z-10 active:text-white text-white hover:bg-indigo-500 hover:border-indigo-500 bg-violet-500 transition font-semibold rounded text-sm13 px-2.5 py-1 leading-[1.0625rem]"
              onClick={() => {
                alert("OK");
              }}
            >
              Remind me
            </button>
          </div>
        </div> */}
        </div>
      </div>

      {createListingLoader && <ProgressBardLoader secondCounter={2} />}
    </>
  );
};

export default OnsalesDetailsModal;
