import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { LayoutContext } from "../../../../components/core/LayoutProvider";
import { getHeaderFilter } from "../../../../helpers/AssetHelpers";
import {
  INITIAL_PERSONAL_DETAILS,
  INITIAL_REFERRAL_DETAILS,
  PEROSNAL_DETAILS_COLUMNS_ITEMS,
  PEROSNAL_DETAILS_FILTER_ITEMS,
  REFERRAL_DETAILS_FILTER_ITEMS,
  REFERRALS_COLUMNS_ITEMS,
} from "../../../../helpers/const";
import { SUBSCRIBE_ROUTE, SUBSCRIBE_TAB } from "./const";

export interface LayoutContextModel {
  activeTab: any;
  setActiveTab: (data: any) => void;

  filterItems: Array<any>;
  setFilterItems: (data: any) => void;

  draggedItems: Array<any>;
  setDraggedItems: (data: any) => void;

  draggedColumns: Array<any>;
  setDraggedColumns: (data: any) => void;

  columnItems: Array<any>;
  setColumnItems: (data: any) => void;

  filterData: any;
  setFilterData: (data: any) => void;

  referralFilterData: any;
  setReferralFilterData: (data: any) => void;

  meta: any;
  setMeta: (data: any) => void;

  referralMeta: any;
  setReferralMeta: (data: any) => void;

  filterOptions: any;
  setFilterOptions: (data: any) => void;

  listings: any;
  setListings: (data: any) => void;

  referralListings: any;
  setReferralListings: (data: any) => void;

  personalDetailsTabs: any;
  setPersonalDetailsTabs: (data: any) => void;

  levelLists: any;
  setLevelLists: (data: any) => void;

  demoStatusOptions: any;
}

const PersonalDetailsContext = createContext<LayoutContextModel>({
  activeTab: "",
  setActiveTab: () => {},

  filterItems: [],
  setFilterItems: (data: any) => {},

  draggedItems: [],
  setDraggedItems: (data: any) => {},

  draggedColumns: [],
  setDraggedColumns: (data: any) => {},

  columnItems: [],
  setColumnItems: (data: any) => {},

  filterData: [],
  setFilterData: (data: any) => {},

  referralFilterData: [],
  setReferralFilterData: (data: any) => {},

  meta: [],
  setMeta: (data: any) => {},

  referralMeta: [],
  setReferralMeta: (data: any) => {},

  filterOptions: [],
  setFilterOptions: (data: any) => {},

  listings: [],
  setListings: (data: any) => {},

  referralListings: [],
  setReferralListings: (data: any) => {},

  personalDetailsTabs: {},
  setPersonalDetailsTabs: (data: any) => {},

  levelLists: [],
  setLevelLists: (data: any) => {},

  demoStatusOptions: [],
});

const PersonalDetailsProvider = ({ children }: PropsWithChildren) => {
  const globalLayout = useContext(LayoutContext);
  let { pathname } = useLocation();

  const [activeTab, setActiveTab] = useState<any>(() =>
    pathname === `/subscribe${SUBSCRIBE_ROUTE.subscribeDetails}`
      ? SUBSCRIBE_TAB?.subscribeDetails
      : SUBSCRIBE_TAB?.referral
  );

  // FILTER - CONDITION
  let isFilterExistInDB =
    pathname === `/subscribe${SUBSCRIBE_ROUTE.subscribeDetails}`
      ? globalLayout?.allowedAccess?.ui_field_settings
          ?.savePersonalDetailsFilter
      : globalLayout?.allowedAccess?.ui_field_settings
          ?.saveReferralDetailsFilter;

  let items =
    pathname === `/subscribe${SUBSCRIBE_ROUTE.subscribeDetails}`
      ? getHeaderFilter(
          "savePersonalDetailsFilter",
          isFilterExistInDB,
          PEROSNAL_DETAILS_FILTER_ITEMS
        )
      : getHeaderFilter(
          "saveReferralDetailsFilter",
          isFilterExistInDB,
          REFERRAL_DETAILS_FILTER_ITEMS
        );

  // COLUMN FILTER - CONDITION
  let isColumnFilterExistInDB =
    pathname === `/subscribe${SUBSCRIBE_ROUTE.subscribeDetails}`
      ? globalLayout?.allowedAccess?.ui_field_settings
          ?.PersonalDetailsTableColumn
      : globalLayout?.allowedAccess?.ui_field_settings?.ReferralsTableColumn;

  let columnsItems =
    pathname === `/subscribe${SUBSCRIBE_ROUTE.subscribeDetails}`
      ? getHeaderFilter(
          "PersonalDetailsTableColumn",
          isColumnFilterExistInDB,
          PEROSNAL_DETAILS_COLUMNS_ITEMS
        )
      : getHeaderFilter(
          "ReferralsTableColumn",
          isColumnFilterExistInDB,
          REFERRALS_COLUMNS_ITEMS
        );

  const [filterItems, setFilterItems] = useState(items);
  const [draggedItems, setDraggedItems] = useState(filterItems);
  const [draggedColumns, setDraggedColumns] = useState([]);
  const [columnItems, setColumnItems] = useState(columnsItems);
  const [filterData, setFilterData] = useState<any>(INITIAL_PERSONAL_DETAILS);
  const [referralFilterData, setReferralFilterData] = useState<any>(
    INITIAL_REFERRAL_DETAILS
  );
  const [meta, setMeta] = useState<any>();
  const [referralMeta, setReferralMeta] = useState<any>();
  const [filterOptions, setFilterOptions] = useState<any>([]);
  const [listings, setListings] = useState<any>([]);
  const [referralListings, setReferralListings] = useState<any>([]);
  const [personalDetailsTabs, setPersonalDetailsTabs] = useState<any>({
    activeTab: "Personal Details",
  });

  const [levelLists, setLevelLists] = useState<any>([]);

  const demoStatusOptions = useMemo(() => {
    const demo_statuses = filterOptions?.demo_statuses ?? [];

    return demo_statuses;
  }, [filterOptions?.demo_statuses]);

  const value: LayoutContextModel = {
    activeTab,
    setActiveTab,

    filterItems,
    setFilterItems,

    draggedItems,
    setDraggedItems,

    draggedColumns,
    setDraggedColumns,

    columnItems,
    setColumnItems,

    filterData,
    setFilterData,

    referralFilterData,
    setReferralFilterData,

    meta,
    setMeta,

    referralMeta,
    setReferralMeta,

    filterOptions,
    setFilterOptions,

    listings,
    setListings,

    referralListings,
    setReferralListings,

    personalDetailsTabs,
    setPersonalDetailsTabs,

    levelLists,
    setLevelLists,

    demoStatusOptions,
  };

  return (
    <PersonalDetailsContext.Provider value={value}>
      {children}
    </PersonalDetailsContext.Provider>
  );
};

export { PersonalDetailsContext, PersonalDetailsProvider };
