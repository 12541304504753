import { size } from "lodash";
import { useContext, useState } from "react";
import angleLeftSVG from "../../../../assets/media/icons/standard_icons/angle_left.svg";
import { default as CrossSVG } from "../../../../assets/media/icons/standard_icons/cross.svg";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import { showAlert } from "../../../../helpers/ShowAlert";
import NavigationButtons from "../../../Sales/component/NavigationButtons";
import { copy } from "../../../add_inventory/core/_functions";
import TooltipPortal from "../../../tx_trade/table/component/TooltipPortal";
import CopytoChipboard from "../../component/bankViewComponent/CopytoChipboard";
import HeaderView from "../../component/bankViewComponent/HeaderView";
import Tab from "../../component/bankViewComponent/Tab";
import { TxAccountContext } from "../../core/TxAccountProvider";
import { addBankAccount } from "../../core/_request";

const BankAccountsViewForm = ({
  data,
  handleClose,
  setBankView,
  bankAccountRefetch,
}: any) => {
  const layout = useContext(TxAccountContext);
  let status = data?.show;
  const { deposit_bank_account, withdrawal_bank_account } = data?.data;
  const [isCopied, setIsCopied] = useState<any>({
    id: "",
    value: "",
  });

  let initialState: any = {
    fund: {
      status: true,
      lockStatus: true,
      data: {
        ...deposit_bank_account,
        team_id: data?.data?.team_id,
        type: "Deposit",
      },
      newData: {
        team_id: data?.data?.team_id,
        type: "Deposit",
      },
    },
    withdraw: {
      status: false,
      lockStatus: true,
      data: {
        ...withdrawal_bank_account,
        team_id: data?.data?.team_id,
        type: "Withdrawal",
      },
      newData: {
        team_id: data?.data?.team_id,
        type: "Withdrawal",
      },
    },
  };

  const [state, setState] = useState<any>(initialState);

  // UNLOCK CONDITION
  let unLock: boolean = false;
  if (state?.fund?.status && !state?.fund?.lockStatus) {
    unLock = true;
  } else if (state?.withdraw?.status && !state?.withdraw?.lockStatus) {
    unLock = true;
  } else {
    unLock = false;
  }

  // HANDLE COPY
  const handleCopy = (data: any) => {
    setIsCopied({
      id: data?.id,
      defaultValue: copy(data?.defaultValue),
    });
    setTimeout(() => {
      setIsCopied({
        id: "",
        defaultValue: "",
      });
    }, 2000);
  };

  // HANDLE NAVIGATION OF ORDER VIEW PAGE
  const handleNavigation = (nav: string) => {
    let newIndex = data?.index;
    if (nav === "back") {
      newIndex =
        newIndex === 0 ? layout?.bankAccountList?.length - 1 : newIndex - 1;
    } else {
      newIndex =
        newIndex === layout?.bankAccountList?.length - 1 ? 0 : newIndex + 1;
    }

    const preRecord = layout?.bankAccountList?.[newIndex];
    setBankView({
      show: true,
      data: preRecord,
      index: newIndex,
    });

    setState((current: any) => {
      return {
        ...initialState,
        fund: {
          ...initialState?.fund,
          data: {
            ...initialState?.fund?.data,
            ...preRecord?.deposit_bank_account,
            team_id: preRecord?.team_id,
          },
          newData: {
            ...initialState?.fund?.newData,
            team_id: preRecord?.team_id,
          },
        },
        withdraw: {
          ...initialState?.withdraw,
          data: {
            ...initialState?.withdraw?.data,
            ...preRecord?.withdrawal_bank_account,
            team_id: preRecord?.team_id,
          },
          newData: {
            ...initialState?.withdraw?.newData,
            team_id: preRecord?.team_id,
          },
        },
      };
    });
  };

  // HANDLE UPDATE
  const handleUpdate = (active: string) => {
    let payload = {
      ...state?.[active]?.newData,
    };

    // BANK ADD - API INTEGRATION
    addBankAccount(payload).then((response: any) => {
      if (response?.data?.status) {
        setBankView({
          show: false,
          data: undefined,
          index: undefined,
        });
        bankAccountRefetch();

        showAlert(response?.message, false);
      } else if (response?.data && size(response?.errors) > 0) {
        if (size(response?.data) === 0) {
          showAlert(response?.errors?.[0], true);
        }

        if (!response?.data?.status) {
          showAlert(response?.errors?.join(", "), true);
        }
      } else {
        showAlert("Something went wrong", true);
      }
    });
  };

  return (
    <form action="">
      <div
        data-te-modal-init
        className={`${
          status ? "show" : "hidden"
        } popup input-style2 upload-ticket-popup offcanvas s_ticket offcanvas-end fixed bottom-0 top-[3.75rem] right-0 shadow-3xl bg-clip-padding outline-none transition duration-1000 ease-in-out font-medium z-40 md:max-w-[43.125rem] w-full`}
        tabIndex={-1}
        id="singleTicket"
        aria-labelledby="singleTicketLabel"
      >
        <div className="  bg-white border-t w-full h-full ml-auto flex flex-col">
          <div className="flex justify-between pl-5 items-center border-b">
            <div>
              <span className="md:text-sm15 text-sm12 block font-semibold">
                {" "}
                {/* ID: {data?.data?.team_id} */}
              </span>
            </div>
            <div className="flex flex-wrap">
              <button
                type="button"
                className="group relative flex flex-auto items-center justify-center p-1 md:p-1 w-7 md:w-10 h-[2.562rem] border-l"
                data-tooltip-id="close-bank-view"
                onClick={() => handleClose()}
              >
                <TooltipPortal
                  id={`close-bank-view`}
                  content={"Close"}
                  place="left"
                  className="text-center  !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-100 max-w-[9rem] cursor-default "
                />
                <IKTSVG
                  className="flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 transition"
                  path={CrossSVG}
                  svgClassName="w-2.5 h-2.5"
                />
              </button>
            </div>
          </div>

          <div className=" h-full overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
            <div className="flex justify-around tab-panel bg-no-repeat bg-tradepattern bg-cover bg-indigo-500 bg-opacity-[15%] p-5 pb-0  after:content after:absolute after:bottom-0 after:left-0 after:w-full after:h-2 after:z-0 after:from-black/0 after:bg-gradient-to-b after:to-black/5 relative overflow-x-auto flex-nowrap overflow-hidden">
              <Tab
                title="Fund In"
                active={state?.fund?.status}
                handleActive={(data: any) => {
                  setState((current: any) => {
                    return {
                      ...current,
                      fund: {
                        ...current?.fund,
                        status: true,
                      },
                      withdraw: {
                        ...current?.withdraw,
                        status: false,
                      },
                    };
                  });
                }}
              />
              <Tab
                title="Withdraw"
                active={state?.withdraw?.status}
                handleActive={(data: any) => {
                  setState((current: any) => {
                    return {
                      fund: {
                        ...current?.fund,
                        status: false,
                      },
                      withdraw: {
                        ...current?.withdraw,
                        status: true,
                      },
                    };
                  });
                }}
              />
            </div>
            <div
              className={`tab_vontent opacity-100 transition-opacity duration-150 ease-linear block`}
              role="tabpanel"
              data-te-tab-active
            >
              <div className="p-5">
                <div className="">
                  <div className=" flex flex-wrap  border rounded relative mb-5 last-of-type:mb-0">
                    <HeaderView
                      title={
                        state?.fund?.status
                          ? "Fund In account details"
                          : "Withdraw account details"
                      }
                      isLock={
                        state?.fund?.status
                          ? state?.fund?.lockStatus
                          : state?.withdraw?.lockStatus
                      }
                      handleLock={() => {
                        setState((current: any) => {
                          let dynamicKey = current?.fund?.status
                            ? "fund"
                            : "withdraw";
                          return {
                            ...current,
                            [dynamicKey]: {
                              ...current?.[dynamicKey],
                              lockStatus: !current?.[dynamicKey]?.lockStatus,
                            },
                          };
                        });
                      }}
                    />

                    <div className="inner-part px-3 py-3 flex flex-wrap w-full">
                      {state?.fund?.status ? (
                        <>
                          {/* Bank name */}
                          <CopytoChipboard
                            headerName="Bank name"
                            value={state?.fund?.newData?.bank_name}
                            defaultValue={state?.fund?.data?.bank_name}
                            id={"bank_name"}
                            isCopied={isCopied}
                            isLock={state?.fund?.lockStatus}
                            placeholder="Bank name"
                            inputFieldName={"bank_name"}
                            handleClick={(data: any) => handleCopy(data)}
                            setState={setState}
                          />

                          {/* Account holder name */}
                          <CopytoChipboard
                            headerName="Account holder name"
                            value={state?.fund?.newData?.bank_account_name}
                            defaultValue={state?.fund?.data?.bank_account_name}
                            id={"bank_account_name"}
                            isCopied={isCopied}
                            isLock={state?.fund?.lockStatus}
                            placeholder="Account holder name"
                            inputFieldName="bank_account_name"
                            handleClick={(data: any) => handleCopy(data)}
                            setState={setState}
                          />

                          {/* Account number */}
                          <CopytoChipboard
                            headerName="Account number"
                            value={state?.fund?.newData?.account_number}
                            defaultValue={state?.fund?.data?.account_number}
                            id={"account_number"}
                            isCopied={isCopied}
                            isLock={state?.fund?.lockStatus}
                            placeholder="Account number"
                            inputFieldName="account_number"
                            handleClick={(data: any) => handleCopy(data)}
                            setState={setState}
                          />

                          {/* IBAN */}
                          <CopytoChipboard
                            headerName="IBAN"
                            value={state?.fund?.newData?.iban}
                            defaultValue={state?.fund?.data?.iban}
                            id={"iban"}
                            isCopied={isCopied}
                            isLock={state?.fund?.lockStatus}
                            placeholder="IBAN"
                            inputFieldName={"iban"}
                            handleClick={(data: any) => handleCopy(data)}
                            setState={setState}
                          />

                          {/* Swift code */}
                          <CopytoChipboard
                            headerName="Swift code"
                            value={state?.fund?.newData?.swift_bic_code}
                            defaultValue={state?.fund?.data?.swift_bic_code}
                            id={"swift_bic_code"}
                            isCopied={isCopied}
                            isLock={state?.fund?.lockStatus}
                            placeholder="Swift code"
                            inputFieldName={"swift_bic_code"}
                            handleClick={(data: any) => handleCopy(data)}
                            setState={setState}
                          />

                          {/* BRANCH CODE === SORT CODE */}
                          <CopytoChipboard
                            headerName="Branch code"
                            value={state?.fund?.newData?.branch_code}
                            defaultValue={state?.fund?.data?.branch_code}
                            id={"branch_code"}
                            isCopied={isCopied}
                            isLock={state?.fund?.lockStatus}
                            placeholder="Branch code"
                            inputFieldName={"branch_code"}
                            handleClick={(data: any) => handleCopy(data)}
                            setState={setState}
                          />

                          {!state?.fund?.lockStatus && <></>}
                        </>
                      ) : (
                        !state?.fund?.status && (
                          <>
                            <>
                              {/* Bank name */}
                              <CopytoChipboard
                                headerName="Bank name"
                                value={state?.withdraw?.newData?.bank_name}
                                defaultValue={state?.withdraw?.data?.bank_name}
                                id={"bank_name"}
                                isCopied={isCopied}
                                isLock={state?.withdraw?.lockStatus}
                                placeholder="Bank name"
                                inputFieldName={"bank_name"}
                                handleClick={(data: any) => handleCopy(data)}
                                setState={setState}
                              />

                              {/* Account holder name */}
                              <CopytoChipboard
                                headerName="Account holder name"
                                value={
                                  state?.withdraw?.newData?.bank_account_name
                                }
                                defaultValue={
                                  state?.withdraw?.data?.bank_account_name
                                }
                                id={"bank_account_name"}
                                isCopied={isCopied}
                                isLock={state?.withdraw?.lockStatus}
                                placeholder="Account holder name"
                                inputFieldName="bank_account_name"
                                handleClick={(data: any) => handleCopy(data)}
                                setState={setState}
                              />

                              {/* Account number */}
                              <CopytoChipboard
                                headerName="Account number"
                                value={state?.withdraw?.newData?.account_number}
                                defaultValue={
                                  state?.withdraw?.data?.account_number
                                }
                                id={"account_number"}
                                isCopied={isCopied}
                                isLock={state?.withdraw?.lockStatus}
                                placeholder="Account number"
                                inputFieldName="account_number"
                                handleClick={(data: any) => handleCopy(data)}
                                setState={setState}
                              />

                              {/* IBAN */}
                              <CopytoChipboard
                                headerName="IBAN"
                                value={state?.withdraw?.newData?.iban}
                                defaultValue={state?.withdraw?.data?.iban}
                                id={"iban"}
                                isCopied={isCopied}
                                isLock={state?.withdraw?.lockStatus}
                                placeholder="IBAN"
                                inputFieldName={"iban"}
                                handleClick={(data: any) => handleCopy(data)}
                                setState={setState}
                              />

                              {/* Swift code */}
                              <CopytoChipboard
                                headerName="Swift code"
                                value={state?.withdraw?.newData?.swift_bic_code}
                                defaultValue={
                                  state?.withdraw?.data?.swift_bic_code
                                }
                                id={"swift_bic_code"}
                                isCopied={isCopied}
                                isLock={state?.withdraw?.lockStatus}
                                placeholder="Swift code"
                                inputFieldName={"swift_bic_code"}
                                handleClick={(data: any) => handleCopy(data)}
                                setState={setState}
                              />

                              {/* BRANCH CODE === SORT CODE */}
                              <CopytoChipboard
                                headerName="Branch code"
                                value={state?.withdraw?.newData?.branch_code}
                                defaultValue={
                                  state?.withdraw?.data?.branch_code
                                }
                                id={"branch_code"}
                                isCopied={isCopied}
                                isLock={state?.withdraw?.lockStatus}
                                placeholder="Branch code"
                                inputFieldName={"branch_code"}
                                handleClick={(data: any) => handleCopy(data)}
                                setState={setState}
                              />

                              {!state?.withdraw?.lockStatus && (
                                <>
                                  {/* BANK ACCOUNT CURRENCY */}
                                  <CopytoChipboard
                                    headerName="Bank account currency"
                                    value={
                                      state?.withdraw?.newData
                                        ?.bank_account_currency
                                    }
                                    defaultValue={
                                      state?.withdraw?.data
                                        ?.bank_account_currency
                                    }
                                    id={"bank_account_currency"}
                                    isCopied={isCopied}
                                    isLock={state?.withdraw?.lockStatus}
                                    placeholder="Bank account currency"
                                    inputFieldName={"bank_account_currency"}
                                    handleClick={(data: any) =>
                                      handleCopy(data)
                                    }
                                    setState={setState}
                                  />

                                  {/* BANK ACCOUNT COUNTRY CODE */}
                                  <CopytoChipboard
                                    headerName="Bank account country code"
                                    value={
                                      state?.withdraw?.newData
                                        ?.bank_account_country_code
                                    }
                                    defaultValue={
                                      state?.withdraw?.data
                                        ?.bank_account_country_code
                                    }
                                    id={"bank_account_country_code"}
                                    isCopied={isCopied}
                                    isLock={state?.withdraw?.lockStatus}
                                    placeholder="Bank account country code"
                                    inputFieldName={"bank_account_country_code"}
                                    handleClick={(data: any) =>
                                      handleCopy(data)
                                    }
                                    setState={setState}
                                  />

                                  {/* Recipient COUNTRY CODE */}
                                  <CopytoChipboard
                                    headerName="Recipient country code"
                                    value={
                                      state?.withdraw?.newData
                                        ?.recipient_country_code
                                    }
                                    defaultValue={
                                      state?.withdraw?.data
                                        ?.recipient_country_code
                                    }
                                    id={"recipient_country_code"}
                                    isCopied={isCopied}
                                    isLock={state?.withdraw?.lockStatus}
                                    placeholder="Recipient country code"
                                    inputFieldName={"recipient_country_code"}
                                    handleClick={(data: any) =>
                                      handleCopy(data)
                                    }
                                    setState={setState}
                                  />

                                  {/* Recipient street */}
                                  <CopytoChipboard
                                    headerName="Recipient street"
                                    value={
                                      state?.withdraw?.newData?.recipient_street
                                    }
                                    defaultValue={
                                      state?.withdraw?.data?.recipient_street
                                    }
                                    id={"recipient_street"}
                                    isCopied={isCopied}
                                    isLock={state?.withdraw?.lockStatus}
                                    placeholder="Recipient street"
                                    inputFieldName={"recipient_street"}
                                    handleClick={(data: any) =>
                                      handleCopy(data)
                                    }
                                    setState={setState}
                                  />

                                  {/* Recipient city */}
                                  <CopytoChipboard
                                    headerName="Recipient city"
                                    value={
                                      state?.withdraw?.newData?.recipient_city
                                    }
                                    defaultValue={
                                      state?.withdraw?.data?.recipient_city
                                    }
                                    id={"recipient_city"}
                                    isCopied={isCopied}
                                    isLock={state?.withdraw?.lockStatus}
                                    placeholder="Recipient city"
                                    inputFieldName={"recipient_city"}
                                    handleClick={(data: any) =>
                                      handleCopy(data)
                                    }
                                    setState={setState}
                                  />

                                  {/* Recipient state */}
                                  <CopytoChipboard
                                    headerName="Recipient state"
                                    value={
                                      state?.withdraw?.newData?.recipient_state
                                    }
                                    defaultValue={
                                      state?.withdraw?.data?.recipient_state
                                    }
                                    id={"recipient_state"}
                                    isCopied={isCopied}
                                    isLock={state?.withdraw?.lockStatus}
                                    placeholder="Recipient state"
                                    inputFieldName={"recipient_state"}
                                    handleClick={(data: any) =>
                                      handleCopy(data)
                                    }
                                    setState={setState}
                                  />

                                  {/* Recipient postcode */}
                                  <CopytoChipboard
                                    headerName="Recipient postcode"
                                    value={
                                      state?.withdraw?.newData
                                        ?.recipient_postcode
                                    }
                                    defaultValue={
                                      state?.withdraw?.data?.recipient_postcode
                                    }
                                    id={"recipient_postcode"}
                                    isCopied={isCopied}
                                    isLock={state?.withdraw?.lockStatus}
                                    placeholder="Recipient postcode"
                                    inputFieldName={"recipient_postcode"}
                                    handleClick={(data: any) =>
                                      handleCopy(data)
                                    }
                                    setState={setState}
                                  />
                                </>
                              )}
                            </>
                          </>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* FOOTER */}
          <div
            className={`top-full bottom-0 right-0 w-full flex ${
              unLock ? "justify-between" : "justify-end"
            } px-5 py-3 bg-white z-10 border-t`}
          >
            {unLock && (
              <div className="btns flex flex-wrap items-center tx-pay-module gap-3.5">
                <button
                  type="button"
                  className="btn flex-1 text-sm13 font-medium py-1 px-2.5"
                  onClick={() => {
                    setBankView({
                      show: false,
                      data: undefined,
                      index: undefined,
                    });
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className=" false  text-sm13 font-medium py-1 px-2.5 bg-green-600 hover:bg-indigo-500 !text-white rounded border-hidden	 hover:border-hidden	 flex"
                  onClick={() =>
                    handleUpdate(state?.fund?.status ? "fund" : "withdraw")
                  }
                >
                  Update
                </button>
              </div>
            )}
            <div className="arrow_buttons flex rounded ">
              <NavigationButtons
                id="back"
                imgPath={angleLeftSVG}
                className={
                  data?.index === 0
                    ? "pointer-events-none"
                    : layout?.bankAccountList?.length - 1 === data?.index
                }
                svgClassName={
                  data?.index === 0
                    ? "!fill-gray-400"
                    : layout?.bankAccountList?.length - 1 === data?.index
                }
                handleNavigation={(data: any) => handleNavigation(data)}
              />
              <NavigationButtons
                id="next"
                imgPath={angleLeftSVG}
                className={
                  layout?.bankAccountList?.length - 1 === data?.index
                    ? "pointer-events-none"
                    : ""
                }
                svgClassName={
                  layout?.bankAccountList?.length - 1 === data?.index
                    ? "!fill-gray-400"
                    : ""
                }
                handleNavigation={(data: any) => handleNavigation(data)}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default BankAccountsViewForm;
