import { useRef, useState } from "react";
import Collapse from "../../../../assets/media/icons/other_icons/Collapse.svg";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import TooltipPortal from "../../../tx_trade/table/component/TooltipPortal";
import HeaderfilterContainer from "./HeaderFilterContainer";

const HeaderFilterWrapper = ({ handleToggle }: any) => {
  const heightRef1: any = useRef();
  const [isCollapseAccordion, setIsCollapseAccordion] =
    useState<boolean>(false);

  return (
    <div className="accordion font-medium" id="heightRef1" ref={heightRef1}>
      <div className=" !rounded-none bg-white accordion-item">
        <div
          className="flex  justify-between accordion-header mb-0"
          id="headingOne"
        >
          <div className="flex flex-wrap justify-between w-full pl-2 pr-5 py-2">
            {/* HEADER FILTER */}
            <HeaderfilterContainer />
          </div>
          <div>
            <button
              className="group relative flex items-center py-4 px-[.9375rem] text-base text-gray-800 text-left bg-white border-l  rounded-tr transition focus:outline-none group h-full md:h-auto [&[aria-expanded='false'] > .icon ]:rotate-180"
              type="button"
              data-te-collapse-init
              data-te-toggle="collapse"
              data-te-target="#inventoryaccordion"
              aria-expanded="true"
              aria-controls="inventoryaccordion"
              id="personal-details-accordion-btn"
              data-tooltip-id="personal-details-accordion"
              onClick={() => {
                setIsCollapseAccordion((current: boolean) => {
                  let isCollapse: any = document
                    .getElementById("personal-details-accordion-btn")
                    ?.getAttribute("aria-expanded");
                  let toggleCondition = isCollapse === "false" ? true : false;
                  handleToggle(toggleCondition);
                  return toggleCondition;
                });
              }}
            >
              <TooltipPortal
                id={`personal-details-accordion`}
                content={`${isCollapseAccordion ? "Expand" : "Collapse"}`}
                positionStrategy="fixed"
                place="left"
                variant="light"
                className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
              />
              <IKTSVG
                className="flex items-center justify-center group-[.collapsed]:rotate-180  fill-violet-500 group-hover:fill-indigo-500 transition group-[[data-te-collapse-collapsed]]:rotate-180"
                path={Collapse}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderFilterWrapper;
