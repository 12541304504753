import { LayoutContext } from "components/core/LayoutProvider";
import { showAlert } from "helpers/ShowAlert";
import { useContext } from "react";
import { deleteSellerLevel } from "../../core/request";

const Footer = ({
  setDeleteLevelLoader,
  refetch,
  deleteConfirmation,
  setDeleteConfirmation,
}: any) => {
  const coreLayout = useContext(LayoutContext);

  return (
    <footer
      className={`page_footer sticky bottom-0 border-b flex md:flex-nowrap flex-wrap items-center justify-between px-5 py-[.1875rem] bg-white shadow-3xl   min-h-[3.1437rem] z-30 ${
        coreLayout?.asideMenuOpen && "menu_open"
      }`}
    >
      <div className="flex items-center ">
        <button
          className="inline-flex group items-center text-violet-800 border transition font-medium rounded text-sm13 px-2.5 py-0.5 hover:bg-indigo-500 hover:border-indigo-500 hover:text-white"
          type="button"
          onClick={() => {
            setDeleteConfirmation({
              status: false,
              id: null,
            });
          }}
        >
          Cancel
        </button>
        <button
          className="ml-2 bg-rose-500  inline-flex group items-center text-white   
                  transition font-medium rounded text-sm13 px-2.5 py-0.5 hover:bg-indigo-500 "
          type="button"
          onClick={async () => {
            const payload = {
              id: deleteConfirmation?.id,
            };
            setDeleteLevelLoader(true);
            try {
              const res = await deleteSellerLevel(payload);
              showAlert(res?.message, !res?.data?.status);
              setDeleteLevelLoader(false);

              refetch();
            } catch (error) {
              showAlert(
                "An error occurred while deleting the seller level",
                true
              );
            } finally {
              setDeleteConfirmation({
                status: false,
                id: null,
              });
            }
          }}
          id={`delete`}
        >
          <i className="fas fa-solid fa-trash mr-1.5"></i>
          Delete
        </button>
      </div>
    </footer>
  );
};

export default Footer;
