import React from "react";

const TableRow = React.memo(
  ({
    hoveredId,
    setHoveredId,
    isGodAdmin,
    tabValue,
    isRowHighlighted = () => {},
    ...props
  }: any) => {
    const list = props?.item;
    const matchedView = isRowHighlighted(list);

    return (
      <>
        {false ? (
          <tr {...props} className="shimmer-effect highlight" />
        ) : (
          <>
            <tr
              {...props}
              key={list.id}
              className={`border-b bg-white ${
                list.selected && !matchedView ? "matchView" : ""
              } ${matchedView ? "matchView" : ""} ${
                (list?.is_legacy_order === "true" ||
                  list?.ticker_uploaded === "yes") &&
                "legacy_block assign"
              }
            ${
              isGodAdmin &&
              list?.isLock === true &&
              (tabValue === "to_be_fulfilled" || tabValue === "out_to_offer") &&
              "pointer-events-none !bg-gray-100"
            }`}

              // // id={`sales-tr-${sIndex}`}
              // onMouseEnter={() => {
              //   setHoveredId(list.order_id);
              // }}
              // onMouseLeave={() => setHoveredId(null)}
            />

            {/* <tr
              className={`border-b !bg-gray-100/50 123 h-[1.875rem]
            `}
            >
              <td></td>

              <td
                colSpan={2}
                className="sticky p-0 border-b left-[5rem] pl-[.9375rem] align-top"
              >
                <div className="456 border bg-white px-2.5 pb-1 -mt-[1px] border-t-0 rounded-b w-max">
                  <span className="text-green-600 font-semibold">
                    Tickets in hand:
                  </span>{" "}
                  24h to deliver
                </div>
              </td>
              <td colSpan={15}></td>
            </tr> */}
          </>
        )}
      </>
    );
  }
);
export default TableRow;
