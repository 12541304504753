export const TX_TAB = {
  wallet: "Wallet",
  virtualCard: "Virtual Cards",
};

export const TX_PAY_TAB_ROUTES = {
  wallet: "/wallet",
  virtualCard: "/virtual-cards",
};

export const COLOR_NAME_ID: any = {
  Purple: "dark-purple",
  Green: "dark-green",
  Blue: "dark-blue",
  Teal: "dark-parrot-green",
  Violet: "dark-purple-blue",
};
