/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-template-curly-in-string */
import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import * as Yup from "yup";
import CloneSVG from "../../../assets/media/icons/other_icons/Clone.svg";
import UploadSVG from "../../../assets/media/icons/other_icons/Upload.svg";
import WarningIcon from "../../../assets/media/icons/standard_icons/warning.svg";

import UploadSVGNew from "../../../assets/media/icons/other_icons/Upload_new.svg";
import AddSVG from "../../../assets/media/icons/other_icons/add_icon.svg";
import CheckSVG from "../../../assets/media/icons/other_icons/check.svg";
import EditSVG from "../../../assets/media/icons/other_icons/edit-square.svg";
import externalLink from "../../../assets/media/icons/other_icons/external-link.svg";
import LockIconOpen from "../../../assets/media/icons/other_icons/lock-open-solid.svg";
import LockIcon from "../../../assets/media/icons/other_icons/lock-solid.svg";
import Minimize from "../../../assets/media/icons/other_icons/minimize.svg";
import processSVG from "../../../assets/media/icons/other_icons/process.svg";
import ReloadIconSVG from "../../../assets/media/icons/other_icons/refresh.svg";
import CrossSVG from "../../../assets/media/icons/standard_icons/cross.svg";
import DatePickerField from "../../../components/formComponent/DatePickerField";
import TextInput from "../../../components/formComponent/TextInput";
import TableDropdown from "../../../components/tableComponent/TableDropdown";

import _ from "lodash";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import ProgressBardLoader from "../../../components/ui/ProgressBardLoader";
import { base64ToPDF } from "../../../helpers/AssetHelpers";
import {
  convertToFormattedData,
  daysDifference,
  decimalNumber,
  getOrderStatusColor,
  getProperTicketTypeWithSubticketCombine,
  handleKeyDown,
  parseMMDDYYYY,
  ticketTypeRename,
  ticketTypeRenameForSalesReport,
} from "../../../helpers/Functions";
import Messages from "../../../helpers/Messages";
import { showAlert } from "../../../helpers/ShowAlert";
import { SYMBOLS } from "../../../helpers/const";
import { copy } from "../../add_inventory/core/_functions";
import TooltipPortal from "../../tx_trade/table/component/TooltipPortal";
import DataCell from "../component/fullOrderDetail/DataCell";
import DetailInfo from "../component/fullOrderDetail/DetailInfo";
import OrderDetailBox from "../component/fullOrderDetail/OrderDetailBox";
import SubDetailInfo from "../component/fullOrderDetail/SubDetailInfo";
import { SalesContext } from "../core/SalesProvider";
import {
  addOrderNote,
  changeTicketType,
  deleteOrderNote,
  updateCustomerDetails,
} from "../core/_requests";

const FullOrderDetail = ({
  show,
  tabValue,
  closeFullOrderDetail,
  handleOrder,
  isGodAdmin,
  setShowOrder = () => {},
  setOrderDetails = () => {},
  optionsData,
  handleMizimize,
  refetch,
  detailsListingData,
}: any) => {
  let data = show?.data;
  const layout = useContext(SalesContext);
  const coreLayout = useContext(LayoutContext);
  const [isCopied, setIsCopied] = useState<any>();
  const [loader, setLoader] = useState<boolean>(false);
  const [isCustomerLock, setIsCustomerLock] = useState<boolean>(true);
  const [isStatusLock, setIsStatusLock] = useState<boolean>(true);
  const [isTicketLock, setIsTicketLock] = useState<boolean>(true);
  const [isAddressLock, setIsAddressLock] = useState<boolean>(true);
  const [copyData, setCopyData] = useState<any>(undefined);
  const [isShippingAddressLock, setShippingIsAddressLock] =
    useState<boolean>(true);
  const [updateLoader, setUpdateLoader] = useState<Boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isEditable1, setisEditable1] = useState<boolean>(false);
  const [buttonShow, setButtonShow] = useState<boolean>(false);
  const [isTicketTypeChange, setIsTicketTypeChange] = useState<boolean>(false);
  const [ticketTypeOptions, setTicketTypeOptions] = useState<any>();
  const [ticketTypeValue, setTicketTypeValue] = useState<any>(null);
  const [progressLoader, setProgressLoader] = useState<boolean>(false);

  const [orderNotes, setOrderNotes] = useState<any>(show?.data?.history);
  const [orderText, setOrderText] = useState<any>({
    show: false,
    note: "",
    orderType: !isGodAdmin ? "external" : "internal",
    confirmed: false,
  });
  const [noteLoader, setNoteLoader] = useState<any>(false);

  useEffect(() => {
    if (detailsListingData) {
      setOrderDetails(detailsListingData?.data?.[0]);

      // RENAME TICKET TYPE DROPDOWN OPTIONS
      const ticketType = getProperTicketTypeWithSubticketCombine(
        detailsListingData?.data[0]?.ticket_types
      );
      const newRenameTicketType = ticketType?.map((obj: any) => {
        return {
          ...obj,
          name: ticketTypeRename(obj?.id),
        };
      });
      let updatedTicketTypes = _.orderBy(
        newRenameTicketType,
        ["name"],
        ["asc"]
      );

      const allowTicketTypeChange =
        detailsListingData?.data[0]?.allow_ticket_type_change === 1;
      setIsTicketTypeChange(
        (tabValue === "new" || tabValue === "to_be_fulfilled") &&
          allowTicketTypeChange
      );

      setIsEditable(false);
      setButtonShow(false);
      if (updatedTicketTypes.length > 0) {
        setTicketTypeOptions(updatedTicketTypes);
      }

      if (detailsListingData?.data?.[0]?.history) {
        setOrderNotes(detailsListingData?.data?.[0]?.history);
      }
    }
  }, [detailsListingData]);

  useEffect(() => {
    setOrderNotes(data?.history);
  }, [data?.history]);

  const handleDropdownChange = (selectedOption: any) => {
    setTicketTypeValue(selectedOption.target.value);
    setButtonShow(true);
  };

  const handleConfirm = async (orderId: any, ticketId: any) => {
    setProgressLoader(true);
    try {
      const response = await changeTicketType(orderId, ticketId);
      if (response?.data?.status) {
        showAlert(response.message, false);
        setTicketTypeValue(null);
        setButtonShow(false);
        setIsEditable(false);
        refetch();

        layout?.setOrders((pre: any) => {
          return pre?.map((obj: any) => {
            if (obj?.order_id === orderId) {
              return {
                ...obj,
                ticket_type: ticketTypeValue.id,
              };
            } else {
              return obj;
            }
          });
        });
      } else {
        showAlert(response.errors, true);
      }
      setProgressLoader(false);
    } catch (error) {
      console.error(error);
      showAlert("An error occurred. Please try again.", true);
    }
  };

  // SHOW ORDER NOTE TEXT INPUT
  const HadleAddOrderNote = () => {
    setOrderText((prev: any) => {
      return {
        ...prev,
        show: true,
      };
    });
  };

  // HANDLE ONCHANGE TEXT INPUT
  const handleOrderText = (e: any) => {
    setOrderText((prev: any) => {
      return {
        ...prev,
        note: e.target?.value,
      };
    });
  };

  // ADD ORDER TEXT TO API
  const addOrderText = (e: any) => {
    e.preventDefault();
    let trimOrderText = orderText?.note?.trim();
    if (trimOrderText === "") {
      showAlert(Messages?.inValidOrderNote, true);
    } else {
      setNoteLoader(true);
      let payload: any = {
        order_id: show?.data?.order_id,
        note: trimOrderText,
        type: orderText?.orderType === "internal" ? 1 : 3,
      };
      addOrderNote(payload)
        .then((response: any) => {
          if (response?.data?.status === true) {
            showAlert("The order note has been successfully added.", false);
            response?.data?.history && setOrderNotes(response?.data?.history);
            cancleOrderText();
          } else {
            if (response?.errors?.title) {
              showAlert(response?.errors?.title, true);
            } else {
              showAlert("Something went wrong.", true);
            }
          }
        })
        .finally(() => setNoteLoader(false));
    }
  };

  // CANCEL ORDER TEXT
  const cancleOrderText = () => {
    setOrderText((prev: any) => {
      return {
        ...prev,
        show: false,
        note: "",
        confirmed: false,
      };
    });
  };

  // DELETE ORDER TEXT
  const deleteOrderText = (data: any) => {
    setNoteLoader(true);
    let payload: any = {
      order_id: show?.data?.order_id,
      index: data?.index,
    };
    deleteOrderNote(payload)
      .then((response: any) => {
        if (response?.data?.id) {
          showAlert("The order note has been successfully deleted.", false);
          response?.data?.history && setOrderNotes(response?.data?.history);
          cancleOrderText();
        } else {
          if (response?.errors?.title) {
            showAlert(response?.errors?.title, true);
          } else {
            showAlert("Something went wrong.", true);
          }
        }
      })
      .finally(() => setNoteLoader(false));
  };

  let copyShippingAddress =
    show &&
    `${show?.data?.customer_shipping_address_line_1},  ${show?.data?.customer_shipping_address_line_2}, ${show?.data?.customer_shipping_town}, ${show?.data?.customer_shipping_postcode}, ${show?.data?.customer_shipping_country}`;
  let copyBillingAddress =
    show &&
    `${show?.data?.customer_billing_address_line_1},  ${show?.data?.customer_billing_address_line_2}, ${show?.data?.customer_billing_town}, ${show?.data?.customer_billing_postcode}, ${show?.data?.customer_billing_country}`;

  const checkShippingAddress = copyShippingAddress
    .replaceAll(",", "")
    .replaceAll(" ", "");

  const checkBillingAddress = copyBillingAddress
    .replaceAll(",", "")
    .replaceAll(" ", "");

  // COPIED EFFECT
  useEffect(() => {
    setTimeout(() => {
      setIsCopied("");
    }, 2000);
  }, [isCopied]);

  // TEXT CAN BE COPIED COMPONENT
  const CopyToClipboard = ({
    header,
    value,
    className = "md:w-1/2 md:p-2 py-2 w-full",
    id,
  }: any) => {
    return (
      <div className={className}>
        <div
          className="text-gray-400 text-sm12 mb-1 line-clamp-1"
          title={header}
        >
          {header}
        </div>
        <div className="relative">
          <input
            type="text"
            value={value}
            className="text-sm12 rounded bg-gray-100 w-full border-none px-2 py-0 block text-violet-800 text-ellipsis overflow-hidden pr-8"
            disabled
          />

          {value !== "" && (
            <button
              className="flex items-center justify-center p-1 absolute top-1/2 right-1 -translate-y-1/2"
              type="button"
              onClick={(e: any) => {
                copy(value);
                setIsCopied(id);
              }}
            >
              <IKTSVG
                className={`flex items-center justify-center   transition duration-1000${
                  isCopied !== "" && isCopied === id
                    ? "fill-green-600  hover:fill-green-600"
                    : "fill-violet-500 hover:fill-indigo-500"
                }`}
                path={CloneSVG}
                svgClassName="w-[.7813rem] h-[.6563rem]"
              />
              {/* {isCopied !== "" && isCopied === id ? (
                <span className="text-xs">copied</span>
              ) : (
                <IKTSVG
                  className={`flex items-center justify-center  hover:fill-indigo-500 transition ${
                    isCopied !== "" && isCopied === id
                      ? "fill-green-600"
                      : "fill-violet-500"
                  }`}
                  path={CloneSVG}
                  svgClassName="w-[.7813rem] h-[.6563rem]"
                />
              )} */}
            </button>
          )}
        </div>
      </div>
    );
  };

  // COPY TO CLIPBOARD FOR TEXTAREA
  const CopyToClipboardTextArea = ({ value, id }: any) => {
    return (
      <div className=" md:p-2 py-2 w-full">
        <div className="relative">
          <textarea
            className=" whitespace-pre-line scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full text-sm12 rounded bg-gray-100 w-full border-none px-2 block text-violet-800 resize-none min-h-[8.125rem] py-1"
            disabled
            value={value}
          ></textarea>
          {value?.trim() && (
            <button
              className="flex items-center justify-center p-1 absolute top-0 right-2 "
              type="button"
              onClick={(e: any) => {
                copy(value);
                setIsCopied(id);
              }}
            >
              {isCopied === id ? (
                <span className="text-xs">copied</span>
              ) : (
                <IKTSVG
                  className="flex items-center justify-center fill-violet-500 hover:fill-indigo-500 transition"
                  path={CloneSVG}
                  svgClassName="w-[.7813rem] h-[.6563rem]"
                />
              )}
            </button>
          )}
        </div>
      </div>
    );
  };

  // DOWNLOAD PDF
  async function downloadPDF(file: any, fileName: string) {
    try {
      setLoader(true);
      await Promise.resolve(base64ToPDF(file, fileName));
      setLoader(false);
    } catch (error) {
      console.error(error);
    }
  }

  // OPEN BLOB PDF IN NEW TAB
  function openBlobPDF(item: any) {
    const byteCharacters = atob(item);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create a new blob from the byte array
    const blob = new Blob([byteArray], { type: "application/pdf" });

    // Create a new URL for the blob
    const url = window.URL.createObjectURL(blob);

    // Open the URL in a new tab
    window.open(url, "_blank");

    // Clean up the URL object after opening the PDF
    window.URL.revokeObjectURL(url);
  }
  const legacyCheck = show?.data?.is_legacy_order;

  const PdfTickitCheck =
    show?.data?.ticket_type === "PDF" || show?.data?.ticket_type === "eticket";

  const detailsSchema = Yup.object().shape({
    customer_email: Yup.string().trim().email("Must be a valid email address"),
    customer_contact_number: Yup.string()
      .min(6, "Too short")
      .max(20, "Too long"),
    items: Yup.array().of(
      Yup.object().shape({
        customer_email_address: Yup.string()
          .trim()
          .email("Must be a valid email address"),
        customer_contact_number: Yup.string()
          .min(6, "Too short")
          .max(20, "Too long"),
      })
    ),
  });

  const initialValues = {
    customer_first_name: show?.data?.customer_first_name || "",
    customer_last_name: show?.data?.customer_last_name || "",
    customer_email: show?.data?.customer_email || "",
    customer_contact_number: show?.data?.customer_contact_number || "",
    customer_dob: show?.data?.customer_dob
      ? new Date(show?.data?.customer_dob)
      : null,
    customer_national_id: show?.data?.customer_national_id || "",
    customer_shipping_address_line_1:
      show?.data?.customer_shipping_address_line_1 || "",
    customer_shipping_address_line_2:
      show?.data?.customer_shipping_address_line_2 || "",
    customer_shipping_country: show?.data?.customer_shipping_country || "",
    customer_shipping_postcode: show?.data?.customer_shipping_postcode || "",
    customer_shipping_town: show?.data?.customer_shipping_town || "",
    customer_billing_address_line_1:
      show?.data?.customer_billing_address_line_1 || "",
    customer_billing_address_line_2:
      show?.data?.customer_billing_address_line_2 || "",
    customer_billing_country: show?.data?.customer_billing_country || "",
    customer_billing_postcode: show?.data?.customer_billing_postcode || "",
    customer_billing_town: show?.data?.customer_billing_town || "",
    customer_nationality: show?.data?.customer_nationality || "",
    customer_city_of_birth: show?.data?.customer_city_of_birth || "",
    items:
      show?.data?.items?.map((obj: any) => ({
        id: obj?.id,
        name: obj?.name,
        row: obj?.row,
        seat: obj?.seat,
        customer_email_address: obj?.customer_email_address,
        customer_national_id: obj?.customer_national_id,
        customer_nationality: obj?.customer_nationality,
        customer_contact_number: obj.customer_contact_number,
        customer_birth_place: obj?.customer_birth_place,
        customer_address: obj?.customer_address,
        customer_dob: obj?.customer_dob ? new Date(obj?.customer_dob) : null,
      })) || [],
    order_status_id: show?.data?.internal_order_status,
  };

  // FORMIk
  const formik: any = useFormik({
    initialValues: initialValues,
    validationSchema: detailsSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values: any) => {
      setUpdateLoader(true);

      await updateCustomerDetails({
        ...values,
        customer_dob: values?.customer_dob
          ? moment(values?.customer_dob).format("YYYY-MM-DD")
          : values?.customer_dob,
        order_id: show?.data?.order_id,
        items: values?.items?.map((item: any) => {
          return {
            ...item,
            customer_dob: item?.customer_dob
              ? moment(item?.customer_dob).format("YYYY-MM-DD")
              : item?.customer_dob,
          };
        }),
      })
        .then((response: any) => {
          setUpdateLoader(false);
          if (response) {
            showAlert(
              response?.message,
              response?.data.status === false ? true : false
            );
            layout?.setOrders((pre: any) => {
              return pre?.map((obj: any) => {
                if (obj?.order_id === show?.data?.order_id) {
                  return {
                    ...obj,
                    internal_order_status: formik?.values?.order_status_id,
                  };
                } else {
                  return obj;
                }
              });
            });
            // setShowOrder((prev: any) => ({
            //   show: prev?.show,
            //   data: {
            //     ...prev?.data,
            //     ...response?.data?.payload.customer,
            //     items: response?.data?.payload.items,
            //   },
            // }));
            setOrderDetails((pre: any) => ({
              ...pre,
              ...response?.data?.payload.customer,
              items: response?.data?.payload.items,
              internal_order_status: formik?.values?.order_status_id,
            }));

            setIsCustomerLock(true);
            setIsAddressLock(true);
            setIsTicketLock(true);
            setShippingIsAddressLock(true);
            setIsStatusLock(true);
          }
        })
        .catch((error) => {
          setUpdateLoader(false);
          console.error(error);
        });
    },
  });

  let deliveryByDays = daysDifference(
    parseMMDDYYYY(show?.data?.deliver_by),
    new Date()
  );
  deliveryByDays = isNaN(deliveryByDays) ? 0 : deliveryByDays;

  let { statusColor, dateColor } = getOrderStatusColor({
    ...show?.data,
    deliveryByDays,
  });

  // let pngImagePath = "/media/png/";
  let benefitsRestrictions: any = data?.restrictions;

  return (
    <>
      <form
        onSubmit={(e: any) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <div
          className={`show !scrollbar-thin  !scrollbar-thumb-violet-800 !scrollbar-thumb-rounded-full scrollbar-track-gray-650 !h-full overflow-y-auto overflow-x-hidden outline-none upload-ticket-popup offcanvas s_ticket offcanvas-end fixed bottom-0 top-[3.75rem] right-0 shadow-lg bg-clip-padding  transition-all duration-200 ease-in-out font-medium w-full  z-[50]  max-h-[calc(100%-60px)] bg-gray-650 ${
            coreLayout.asideMenuOpen
              ? "md:max-w-[calc(100%_-_13.875rem)]"
              : "md:max-w-[calc(100%_-_3.75rem)]"
          }`}
        >
          <div className="relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600 h-full">
            <div className=" main-content-wrap  min-h-full bg-gray-650 ">
              <div className="border-t flex justify-between md:pl-8 pl-5 items-center border-b sticky top-0 bg-white z-[60]">
                <div className="flex-1">
                  <span className="md:text-sm15 text-sm12 block font-semibold">
                    {" "}
                    Order ID:{data?.order_id}
                  </span>
                </div>
                <div className="flex flex-wrap">
                  {tabValue === "to_be_fulfilled" && (
                    <>
                      {data?.is_txtrade_pending_order ? (
                        <button
                          className={`group relative flex flex-auto items-center justify-center p-1 md:p-1 w-7 md:w-10 h-[2.562rem] border-l 
                      
                      `}
                          type="button"
                          aria-label="Close"
                          id="infoerroricon"
                        >
                          <IKTSVG
                            path={WarningIcon}
                            className="fill-rose-500"
                            svgClassName="w-4 h-4"
                          />
                          <Tooltip
                            anchorId={`infoerroricon`}
                            content={`Attendee details pending`}
                            place={"left"}
                            variant="light"
                            className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10 `}
                          />
                        </button>
                      ) : (
                        <button
                          className={`relative sadsadsad mail flex flex-auto items-center justify-center p-1 md:p-1 w-7 md:w-10 h-[2.562rem] border-l ${
                            show?.data?.is_legacy_order == "true"
                              ? `pointer-events-none bg-gray-100 hidden`
                              : ""
                          } 
                      
                      `}
                          type="button"
                          onMouseDown={() => {
                            closeFullOrderDetail();
                            handleOrder(data);
                          }}
                          id={"upload-ticekt"}
                        >
                          <IKTSVG
                            className={`flex items-center justify-center fill-violet-500 hover:fill-indigo-500 transition`}
                            path={UploadSVGNew}
                            svgClassName="w-[.8749rem] h-[.7501rem]"
                          />

                          <Tooltip
                            anchorId={`upload-ticekt`}
                            content={`${
                              show?.data?.ticket_type === "mobile"
                                ? "Confirm transfer"
                                : show?.data?.ticket_type === "eticket" ||
                                  show?.data?.ticket_type === "PDF"
                                ? show?.data?.ticket_quantity > 1
                                  ? "Deliver tickets"
                                  : "Deliver ticket"
                                : show?.data?.ticket_type === "mobile-link"
                                ? "Confirm mobile link"
                                : show?.data?.ticket_type === "paper" ||
                                  show?.data?.ticket_type === "season-cards"
                                ? "Confirm shipping"
                                : "Upload ticket"
                            }
                    `}
                            place={"left"}
                            variant="light"
                            className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10 `}
                          />
                        </button>
                      )}
                    </>
                  )}
                  <button
                    className={`group relative sadsadsad mail flex flex-auto items-center justify-center p-1 md:p-1 w-7 md:w-10 h-[2.562rem] border-l`}
                    type="button"
                    onClick={(e: any) => {
                      layout.setFullShow(false);
                      handleMizimize({
                        orderNotes: orderNotes,
                      });
                      // setTimeout(() => {
                      //   display(false);
                      // }, 500);
                    }}
                    id="maximize"
                  >
                    <IKTSVG
                      className={`flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 transition`}
                      path={Minimize}
                      svgClassName="w-[.9758rem] h-[.9758rem]"
                    />

                    <Tooltip
                      anchorId={`maximize`}
                      content={`Minimize order`}
                      place={"left"}
                      variant="light"
                      className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10 `}
                    />
                  </button>

                  <button
                    className={`group relative sadsadsad mail flex flex-auto items-center justify-center p-1 md:p-1 w-7 md:w-10 h-[2.562rem] border-l`}
                    type="button"
                    onMouseDown={() => {
                      closeFullOrderDetail();
                    }}
                    id="Close"
                  >
                    <IKTSVG
                      className={`flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 transition`}
                      path={CrossSVG}
                      svgClassName="w-[.5966rem] h-[.5966rem]"
                    />

                    <Tooltip
                      anchorId={`Close`}
                      content={`Close`}
                      place={"left"}
                      variant="light"
                      className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10 `}
                    />
                  </button>
                </div>
              </div>

              <div className="inner-content p-5 bg-gray-650">
                <div className="flex flex-wrap -mx-2.5">
                  <OrderDetailBox
                    title="Order details"
                    className="flex flex-wrap px-3 py-3"
                    headerClass="lg2:w-1/2 w-full mb-5 px-2.5"
                  >
                    {/* <DetailInfo header="Order ID" value={data?.order_id} /> */}
                    <CopyToClipboard
                      header="Order ID"
                      value={`${data?.order_id}`}
                      id={"orderDetails"}
                      handleClick={(data: any) => {
                        let copiedValues = copy(data?.value);
                        setIsCopied({ id: data?.id, value: copiedValues });
                      }}
                    />
                    <DetailInfo
                      header="Order date"
                      value={moment(
                        data?.order_date_time,
                        "DD-MM-YYYY HH:mm:ss"
                      ).format("DD/MM/YYYY HH:mm:ss")}
                    />

                    {(legacyCheck === "true" ||
                      legacyCheck === true ||
                      isGodAdmin) && (
                      <DetailInfo
                        header="Marketplace"
                        value={data?.marketplace}
                      />
                    )}

                    {isGodAdmin && (
                      <DetailInfo
                        header="Marketplace ID"
                        value={show?.data?.external_order_id}
                      />
                    )}

                    <div className="md:w-1/2 md:p-2 py-2 w-full">
                      <div className="text-gray-400 text-sm12 mb-1">
                        Order status
                      </div>
                      {isEditable ? (
                        <div className="full-dropdown w-full text-sm12 border-violet-900 flex flex-wrap justify-between items-center relative">
                          <TableDropdown
                            options={ticketTypeOptions}
                            name="change_ticket_type"
                            placeholder="Ticket type"
                            value={ticketTypeValue}
                            isClearable={false}
                            handleOnChange={handleDropdownChange}
                            isValueCapital={true}
                            isCapital={true}
                          />

                          <div className="flex ml-2">
                            <button
                              type="button"
                              className={`bg-white hover:bg-indigo-500 border border-gray-300 hover:border-indigo-500 group flex mx-1 items-center justify-center h-6 w-6  text-white transition rounded mr-0`}
                              id={`cancel-ticket-type`}
                              onClick={() => {
                                setTicketTypeValue(null);
                                setButtonShow(false);
                                setIsEditable(false);
                              }}
                            >
                              <Tooltip
                                anchorId={`cancel-ticket-type`}
                                content="Cancel"
                                place="top"
                                variant="light"
                                className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                              />
                              <IKTSVG
                                className="fill-violet-550 transition group-hover:fill-white"
                                path={CrossSVG}
                                svgClassName="w-2.5"
                              />
                            </button>
                            <button
                              type="button"
                              id={`confirm-ticket-type`}
                              className={`${
                                !buttonShow
                                  ? "bg-gray-100 border-gray-100 pointer-events-none"
                                  : "bg-violet-500 hover:bg-indigo-500 border-violet-500"
                              }  border  group flex mx-1 items-center justify-center h-6 w-6  hover:border-indigo-500 text-white transition rounded mr-0`}
                              onClick={() => {
                                handleConfirm(
                                  show?.data?.order_id,
                                  ticketTypeValue?.id
                                );
                              }}
                            >
                              <IKTSVG
                                path={CheckSVG}
                                className={`${
                                  !buttonShow
                                    ? "fill-gray-400"
                                    : "fill-white group-hover:fill-white"
                                } transition `}
                                svgClassName="w-3"
                              />
                              <Tooltip
                                anchorId={`confirm-ticket-type`}
                                content="Confirm"
                                place="top"
                                variant="light"
                                className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10`}
                              />
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className={`flex justify-between rounded`}>
                          <div className={`flex ${dateColor}  flex-1 rounded`}>
                            <div
                              className={`max-lg2:w-1/2 inline-flex px-2.5 py-[3px] text-sm12 text-white rounded-l ${statusColor}`}
                            >
                              {data?.order_status}
                            </div>
                            <div
                              className={`max-lg2:w-1/2 inline-flex px-2.5 py-[3px] text-sm12 text-violet-800 rounded-r `}
                            >
                              {legacyCheck === "true" || legacyCheck === true
                                ? `Legacy Order`
                                : data?.order_status === "Cancelled" &&
                                  data?.internal_order_status !== "" &&
                                  isGodAdmin
                                ? data?.internal_order_status
                                : ticketTypeRenameForSalesReport(
                                    data?.ticket_type,
                                    data?.sub_ticket_type
                                  )}
                            </div>
                          </div>

                          {!isEditable && isTicketTypeChange && (
                            <button
                              type="button"
                              id={`edit-ticket-type`}
                              className={`bg-violet-500  hover:bg-indigo-500 border border-violet-500 group flex items-center justify-center h-6 w-6 mx-2 hover:border-indigo-500 text-white transition rounded mr-0`}
                              onClick={() => setIsEditable(true)}
                            >
                              <IKTSVG
                                path={EditSVG}
                                className={` group-hover:fill-white transition fill-white`}
                                svgClassName="w-3"
                              />
                              <Tooltip
                                anchorId={`edit-ticket-type`}
                                content="Edit"
                                place="top"
                                variant="light"
                                className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                              />
                            </button>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="md:w-1/2 md:p-2 py-2 w-full">
                      <div className="text-gray-400 text-sm12 mb-1">
                        Delivery by
                      </div>
                      {/* <div className="px-2.5 py-[3px] bg-rose-500 bg-opacity-[7%] text-sm12 rounded inline-block">
                        {moment(data?.event_date, "DD-MM-YYYY").format(
                          "DD/MM/YYYY"
                        )}
                      </div> */}
                      <div
                        className={`px-2.5 py-[3px] text-sm12 rounded inline-block ${dateColor}`}
                      >
                        {show?.data?.deliver_by}
                      </div>
                    </div>

                    {show?.data?.delivery_partner_name && (
                      <DetailInfo
                        header="Delivery partner"
                        value={show?.data?.delivery_partner_name}
                      />
                    )}

                    {show?.data?.fulfilled_ticket_files?.shipping_tracking_id
                      ?.shipping_tracking_id && (
                      <DetailInfo
                        header="Shipping tracking ID"
                        value={
                          show?.data?.fulfilled_ticket_files
                            ?.shipping_tracking_id?.shipping_tracking_id
                        }
                      />
                    )}

                    {tabValue === "fulfilled" && (
                      <DetailInfo
                        header="Expected payout date"
                        headerClassName="text-gray-400 text-sm12 mb-1"
                        value={moment(show?.data?.event_date)
                          .add(14, "days")
                          .format("DD/MM/YYYY")}
                        // valueClassName="inline-flex px-2.5 py-[3px] bg-rose-500 bg-opacity-[7%] text-sm12 rounded inline-block"
                      />
                    )}

                    {(legacyCheck === "true" || legacyCheck === true) && (
                      <div className="p-2 w-full">
                        <div className="legency bg-rose-500 text-white rounded w-full text-sm12 text-center px-2.5 py-1">
                          Please fulfill legacy orders via the marketplace
                        </div>
                      </div>
                    )}

                    {tabValue === "to_be_fulfilled" &&
                      show?.data?.ticket_type === "mobile" && (
                        <div className="p-2 w-full">
                          <div
                            className={`legency ${statusColor} text-white rounded w-full text-sm12 text-center px-2.5 py-1`}
                          >
                            Mobile tickets must be delivered directly to the
                            customer's email address or telephone number
                          </div>
                        </div>
                      )}
                  </OrderDetailBox>

                  {/* CUSTOMERS DETAILS */}
                  {(data?.customer_id ||
                    data?.customer_first_name ||
                    data?.customer_last_name ||
                    data?.customer_email ||
                    data?.customer_contact_number ||
                    data?.customer_dob ||
                    data?.customer_national_id ||
                    data?.customer_city_of_birth ||
                    data?.customer_nationality ||
                    isGodAdmin) && (
                    <>
                      <OrderDetailBox
                        title="Customer details"
                        className="flex flex-wrap px-3 py-3"
                        headerClass="lg2:w-1/2 w-full mb-5 px-2.5"
                        lockIcon={
                          isGodAdmin && (
                            <div
                              className="pb-0  lock-icon border-l group w-10"
                              onClick={() => setIsCustomerLock(!isCustomerLock)}
                            >
                              {/* <img
                                // src={isCustomerLock ? LockIcon : LockIconOpen}
                                src={isCustomerLock ? `${pngImagePath}lock.png` : `${pngImagePath}unlock.png` }
                                alt="lockIcon"
                                className="!w-[.9375rem] !h-[.9375rem]"
                              /> */}
                              <IKTSVG
                                svgClassName="!w-[.8125rem] !h-[.8125rem]"
                                path={isCustomerLock ? LockIcon : LockIconOpen}
                              />
                            </div>
                          )
                        }
                      >
                        {data?.customer_id && (
                          <DetailInfo
                            header="Customer ID"
                            value={data?.customer_id}
                          />
                        )}
                        {isCustomerLock === false && isGodAdmin ? (
                          <>
                            {/* EDIT CUSTOMER INFO */}
                            {/* <div className="flex flex-wrap px-3 py-3 gap-2  form_cols attendee-form"> */}
                            <div className="flex gap-1 flex-col attendee-form md:w-1/2 md:p-2 py-2 w-full lock_form">
                              <div className="min-w-[6.25rem] text-sm12 text-">
                                Customer first name
                              </div>
                              <TextInput
                                name={`customer_first_name`}
                                type="text"
                                value={formik?.values?.customer_first_name}
                                required={false}
                                placeholder="Enter first name"
                                className="!h-8"
                                // errorClass={
                                //   formik.touched.details?.[index]?.name &&
                                //   formik.errors.details?.[index]?.name &&
                                //   "border-rose-500"
                                // }
                                handleClearValue={() =>
                                  formik.setFieldValue(
                                    `customer_first_name`,
                                    ""
                                  )
                                }
                                handleOnChange={formik.handleChange}
                              />
                            </div>
                            <div className="flex gap-1 flex-col attendee-form md:w-1/2 md:p-2 py-2 w-full lock_form">
                              <div className="min-w-[6.25rem] text-sm12 text-">
                                Customer last name
                              </div>
                              <TextInput
                                name={`customer_last_name`}
                                type="text"
                                value={formik?.values?.customer_last_name}
                                required={false}
                                placeholder="Enter last name"
                                className="!h-8"
                                // errorClass={
                                //   formik.touched.details?.[index]?.name &&
                                //   formik.errors.details?.[index]?.name &&
                                //   "border-rose-500"
                                // }
                                handleClearValue={() =>
                                  formik.setFieldValue(`customer_last_name`, "")
                                }
                                handleOnChange={formik.handleChange}
                              />
                            </div>
                            <div className="flex gap-1 flex-col attendee-form md:w-1/2 md:p-2 py-2 w-full lock_form">
                              <div className="min-w-[6.25rem] text-sm12 text-">
                                Customer email
                              </div>
                              <div className="flex-1 relative">
                                <TextInput
                                  name={`customer_email`}
                                  type="email"
                                  value={formik?.values?.customer_email}
                                  required={false}
                                  placeholder="Enter Email"
                                  className="!h-8"
                                  errorClass={
                                    formik.touched.customer_email &&
                                    formik.errors.customer_email &&
                                    "border-rose-500"
                                  }
                                  handleClearValue={() =>
                                    formik.setFieldValue(`customer_email`, "")
                                  }
                                  handleOnChange={formik.handleChange}
                                />
                                {formik.touched.customer_email &&
                                  formik.errors.customer_email && (
                                    <div className="text-xs text-rose-500 error-msg ">
                                      {formik.errors.customer_email as string}
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div className="flex gap-1 flex-col attendee-form md:w-1/2 md:p-2 py-2 w-full lock_form">
                              <div className="min-w-[6.25rem] text-sm12 text-">
                                Customer contact number
                              </div>
                              <div className="flex-1 relative">
                                <TextInput
                                  name={`customer_contact_number`}
                                  type="text"
                                  value={
                                    formik?.values?.customer_contact_number
                                  }
                                  required={false}
                                  placeholder="Enter Contact number"
                                  className="!h-8"
                                  errorClass={
                                    formik.touched.customer_contact_number &&
                                    formik.errors.customer_contact_number &&
                                    "border-rose-500"
                                  }
                                  handleClearValue={() =>
                                    formik.setFieldValue(
                                      `customer_contact_number`,
                                      ""
                                    )
                                  }
                                  handleOnChange={formik.handleChange}
                                  handleKeyDown={handleKeyDown}
                                />
                                {formik.touched.customer_contact_number &&
                                  formik.errors.customer_contact_number && (
                                    <div className="text-xs text-rose-500 error-msg ">
                                      {
                                        formik.errors
                                          .customer_contact_number as string
                                      }
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div className="flex gap-1 flex-col attendee-form md:w-1/2 md:p-2 py-2 w-full lock_form">
                              <div className="min-w-[6.25rem] text-sm12 text-">
                                Date of birth
                              </div>
                              <DatePickerField
                                name={`customer_dob`}
                                placeholder="DD/MM/YYYY"
                                disabledKeyboardNavigation={false}
                                value={formik?.values?.customer_dob}
                                id={"dateOfBirth"}
                                isDateRange={false}
                                IsIconRequire={false}
                                // error={
                                //   formik.touched.details?.[index].dob &&
                                //   formik.errors.details?.[index].dob
                                // }
                                handleOnChange={(data: any) => {
                                  formik.handleChange(data);
                                }}
                                // handleOnChange={(data: any) => {
                                //   const date = data?.target?.value
                                //     ? moment(data?.target?.value).format("YYYY-MM-DD")
                                //     : "";
                                //   formik.setFieldValue(`details[${index}].dob`, date);
                                // }}

                                format="dd/MM/yy"
                                placeholderMoveTop={false}
                              />
                            </div>
                            <div className="flex gap-1 flex-col attendee-form md:w-1/2 md:p-2 py-2 w-full lock_form">
                              <div className="min-w-[6.25rem] text-sm12 text-">
                                Customer national ID
                              </div>
                              <div className="flex-1 relative ">
                                <TextInput
                                  name={`customer_national_id`}
                                  type="text"
                                  value={formik?.values?.customer_national_id}
                                  required={false}
                                  placeholder="Enter national ID"
                                  className="!h-8 !bg-white"
                                  // errorClass={
                                  //   formik.touched.customer_contact_number &&
                                  //   formik.errors.customer_contact_number &&
                                  //   "border-rose-500"
                                  // }
                                  handleClearValue={() =>
                                    formik.setFieldValue(
                                      `customer_national_id`,
                                      ""
                                    )
                                  }
                                  handleOnChange={formik.handleChange}
                                />
                              </div>
                            </div>

                            <div className="flex gap-1 flex-col attendee-form md:w-1/2 md:p-2 py-2 w-full lock_form">
                              <div className="min-w-[6.25rem] text-sm12 text-">
                                Customer nationality
                              </div>
                              <div className="flex-1 relative ">
                                <TextInput
                                  name={`customer_nationality`}
                                  type="text"
                                  value={formik?.values?.customer_nationality}
                                  required={false}
                                  placeholder="Enter nationality"
                                  className="!h-8 !bg-white"
                                  // errorClass={
                                  //   formik.touched.customer_contact_number &&
                                  //   formik.errors.customer_contact_number &&
                                  //   "border-rose-500"
                                  // }
                                  handleClearValue={() =>
                                    formik.setFieldValue(
                                      `customer_nationality`,
                                      ""
                                    )
                                  }
                                  handleOnChange={formik.handleChange}
                                />
                              </div>
                            </div>
                            <div className="flex gap-1 flex-col attendee-form md:w-1/2 md:p-2 py-2 w-full lock_form">
                              <div className="min-w-[6.25rem] text-sm12 text-">
                                Customer city of birth
                              </div>
                              <div className="flex-1 relative ">
                                <TextInput
                                  name={`customer_city_of_birth`}
                                  type="text"
                                  value={formik?.values?.customer_city_of_birth}
                                  required={false}
                                  placeholder="Enter city of birth"
                                  className="!h-8 !bg-white"
                                  // errorClass={
                                  //   formik.touched.customer_contact_number &&
                                  //   formik.errors.customer_contact_number &&
                                  //   "border-rose-500"
                                  // }
                                  handleClearValue={() =>
                                    formik.setFieldValue(
                                      `customer_city_of_birth`,
                                      ""
                                    )
                                  }
                                  handleOnChange={formik.handleChange}
                                />
                              </div>
                            </div>
                            {/* </div> */}
                          </>
                        ) : (
                          <>
                            {(data?.customer_first_name ||
                              data?.customer_last_name) && (
                              <CopyToClipboard
                                header="Customer name"
                                value={`${data?.customer_first_name} ${data?.customer_last_name}`}
                                id="customer_name"
                              />
                            )}

                            {data?.customer_email && (
                              <CopyToClipboard
                                header="Customer email"
                                value={
                                  data?.customer_email
                                    ? data?.customer_email
                                    : ""
                                }
                                id="customer_email"
                              />
                            )}

                            {data?.customer_contact_number && (
                              <CopyToClipboard
                                header="Customer phone"
                                value={
                                  data?.customer_contact_number
                                    ? data?.customer_contact_number
                                    : ""
                                }
                                id="customer_phone"
                              />
                            )}

                            {data?.customer_dob && (
                              <CopyToClipboard
                                header="Customer DOB"
                                value={moment(data?.customer_dob).format(
                                  "DD/MM/YYYY"
                                )}
                                id="customer_dob"
                              />
                            )}

                            {data?.customer_national_id && (
                              <CopyToClipboard
                                header="Customer National ID"
                                value={data?.customer_national_id}
                                id="customer_id"
                              />
                            )}

                            {data?.customer_city_of_birth && (
                              <CopyToClipboard
                                header="Customer city of birth"
                                value={data?.customer_city_of_birth}
                                id="customer_city_of_birth"
                              />
                            )}

                            {data?.customer_nationality && (
                              <CopyToClipboard
                                header="Customer Nationality"
                                value={data?.customer_nationality}
                                id="customer_nationality"
                              />
                            )}
                          </>
                        )}
                      </OrderDetailBox>
                    </>
                  )}
                </div>

                {/* SECOND LAYER */}
                <div className="table-block border rounded mb-5 bg-white">
                  <div className="title border-b md:px-5 px-3 py-[9px] flex justify-between">
                    <span className="md:text-sm15 text-sm12 block font-semibold">
                      {" "}
                      Tickets ordered{" "}
                    </span>
                    {!show?.data?.is_txtrade_pending_order &&
                      tabValue === "to_be_fulfilled" &&
                      legacyCheck !== true && (
                        <button
                          type="button"
                          className={`group inline-flex group items-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 ${
                            data?.is_legacy_order == "true"
                              ? ` opacity-30 pointer-events-none text-gray-500 hidden`
                              : ""
                          }
                        
                        `}
                          // onClick={handleOrder}
                          onMouseDown={() => {
                            closeFullOrderDetail();
                            handleOrder(data);
                          }}
                        >
                          <IKTSVG
                            className={`flex items-center justify-center fill-white transition mr-2 ${
                              isGodAdmin && "fill-gray-400"
                            }`}
                            path={UploadSVGNew}
                            svgClassName="w-3.5 h-3"
                          />
                          {show?.data?.ticket_type === "mobile"
                            ? "Confirm transfer"
                            : show?.data?.ticket_type === "eticket" ||
                              show?.data?.ticket_type === "PDF"
                            ? show?.data?.ticket_quantity > 1
                              ? "Deliver"
                              : "Deliver"
                            : show?.data?.ticket_type === "mobile-link"
                            ? "Confirm mobile link"
                            : show?.data?.ticket_type === "paper" ||
                              show?.data?.ticket_type === "season-cards"
                            ? "Confirm shipping"
                            : "Upload ticket"}
                        </button>
                      )}
                  </div>
                  <div className="inventory-table sales-table overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
                    <table className="w-full text-xs text-left">
                      <thead className="text-xs text-gray-400">
                        <tr className="border-b">
                          <th className="md:px-4 px-3 py-3 font-medium whitespace-nowrap w-[21.875rem] min-w-[15.625rem]">
                            <div className="w-full pr-3">Event name</div>
                          </th>
                          <th className="md:px-4 px-3 py-3 font-medium whitespace-nowrap w-[15.625rem] ">
                            <div className="w-full pr-4">Event venue</div>
                          </th>
                          <th className="md:px-4 px-3 py-3 font-medium whitespace-nowrap w-[15.625rem]">
                            <div className="w-full pr-4">Event date</div>
                          </th>
                          <th className="md:px-4 px-3 py-3 font-medium whitespace-nowrap w-[15.625rem]">
                            <div className="w-full pr-4">Seat details</div>
                          </th>
                          <th className="md:px-4 px-3 py-3 font-medium whitespace-nowrap w-[12.5rem]">
                            <div className="w-full pr-4">Ticket type</div>
                          </th>
                          <th className="md:px-4 px-3 py-3 font-medium whitespace-nowrap w-[12.5rem]">
                            <div className="w-full pr-4">Quantity</div>
                          </th>
                          <th className="md:px-4 px-3 py-3 font-medium whitespace-nowrap w-[12.5rem]">
                            <div className="w-full pr-4">Ticket price</div>
                          </th>
                          <th className="md:px-4 px-3 py-3 font-medium whitespace-nowrap w-[12.5rem]">
                            <div className="w-full pr-4">Order value</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="border-b">
                          <DataCell title={data?.event_description} />
                          <DataCell title={data?.venue} />
                          <DataCell
                            title={
                              data?.event_date
                                ? moment(data?.event_date).format(
                                    "ddd, DD MMM YYYY, HH:mm"
                                  )
                                : null
                            }
                          />
                          <DataCell title={data?.seating_location} />
                          <DataCell
                            title={ticketTypeRenameForSalesReport(
                              data?.ticket_type,
                              data?.sub_ticket_type
                            )}
                            capitalize={false}
                          />
                          <DataCell
                            title={
                              data?.ticket_quantity
                                ? data?.ticket_quantity
                                : "0"
                            }
                          />
                          <DataCell
                            title={
                              data?.ticket_price
                                ? `${
                                    isGodAdmin
                                      ? SYMBOLS[data.ticket_currency]
                                      : coreLayout.currencySymbol
                                  }${decimalNumber(data?.ticket_price)}
                              `
                                : "-"
                            }
                          />
                          <DataCell
                            title={
                              data?.transaction_value
                                ? `${
                                    isGodAdmin
                                      ? SYMBOLS[data.ticket_currency]
                                      : coreLayout.currencySymbol
                                  }${decimalNumber(data?.transaction_value)}`
                                : "-"
                            }
                          />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* BENEFITS */}
                <div className="table-block border rounded mb-5 bg-white">
                  <div className="title border-b md:px-5 px-3 py-[9px] flex justify-between">
                    <span className="md:text-sm15 text-sm12 block font-semibold">
                      {" "}
                      Benefits/Restrictions
                    </span>
                  </div>
                  <div className={`flex flex-wrap px-3 py-3`}>
                    {benefitsRestrictions?.length > 0 ? (
                      benefitsRestrictions?.map(
                        (restriction: any, restKey: number) => {
                          return (
                            <div
                              className="single_list flex items-center text-xs p-0 md:w-1/4 w-full px-[.3125rem] py-1"
                              key={`restriction_${restKey}`}
                            >
                              - {restriction}
                            </div>
                          );
                        }
                      )
                    ) : (
                      <h5 className="font-medium single_list flex items-center text-xs p-0 md:w-1/4 w-full px-[.375rem] py-1">
                        N/A
                      </h5>
                    )}
                  </div>
                </div>

                {/* THIRD LAYER */}
                <div className="ticket-detail border rounded mb-5 bg-white">
                  <div
                    className={`title   flex flex-wrap ${
                      isGodAdmin ? " justify-start gap-2 " : " justify-between "
                    } border-b justify-between`}
                  >
                    <span className="md:text-sm15 text-sm12 block font-semibold md:px-5 px-3  py-[9px]">
                      {" "}
                      Attendee details{" "}
                      {data?.fulfilled_ticket_files?.shipping_tracking_id
                        ?.shipping_tracking_id &&
                        `(${data?.fulfilled_ticket_files?.shipping_tracking_id?.shipping_tracking_id})`}
                    </span>
                    {isGodAdmin && (
                      <div
                        className=" lock-icon w-10 border-l group"
                        onClick={() => setIsTicketLock(!isTicketLock)}
                      >
                        {/* <img
                          // src={isTicketLock ? LockIcon : LockIconOpen}
                          src={isTicketLock ? `${pngImagePath}lock.png` : `${pngImagePath}unlock.png` }
                          alt="lockIcon"
                          className="!w-[.9375rem] !h-[.9375rem]"
                        /> */}
                        <IKTSVG
                          svgClassName="!w-[.8125rem] !h-[.8125rem] group-hover:fill-indigo-500"
                          path={isTicketLock ? LockIcon : LockIconOpen}
                        />
                      </div>
                    )}
                  </div>

                  {isTicketLock === false && isGodAdmin ? (
                    <>
                      <div
                        className="  flex flex-wrap relative md:-right-[1px] lg2:right-0"
                        // key={index}
                      >
                        {/* EDIT TICKIT INFO */}
                        {formik?.values?.items?.map((obj: any, index: any) => {
                          return (
                            <div
                              className={`flex flex-wrap px-3 py-3 -mx-0.625rem  form_cols attendee-form lg2:w-1/2 md:w-1/2 w-full p-3 md:border-r lg2:border-b-0 ${
                                index > 1 ? `md:border-t` : ``
                              }`}
                            >
                              <div className="flex gap-1 flex-col px-2 py-2 md:w-1/2 w-full lock_form">
                                <div className="min-w-[6.25rem] text-sm12 text-">
                                  Ticket {index + 1} name
                                </div>
                                <TextInput
                                  name={`items[${index}].name`}
                                  type="text"
                                  value={obj?.name}
                                  required={false}
                                  placeholder="Enter ticket name"
                                  className="!h-8"
                                  // errorClass={
                                  //   formik.touched.details?.[index]?.name &&
                                  //   formik.errors.details?.[index]?.name &&
                                  //   "border-rose-500"
                                  // }
                                  handleClearValue={() =>
                                    formik.setFieldValue(
                                      `items[${index}].name`,
                                      ""
                                    )
                                  }
                                  handleOnChange={formik.handleChange}
                                />
                              </div>
                              <div className="flex gap-1 flex-col px-2 py-2 md:w-1/2 w-full lock_form">
                                <div className="min-w-[6.25rem] text-sm12 text-">
                                  Row
                                </div>
                                <TextInput
                                  name={`items[${index}].row`}
                                  type="text"
                                  value={obj?.row}
                                  required={false}
                                  placeholder="Enter row name"
                                  className="!h-8"
                                  // errorClass={
                                  //   formik.touched.details?.[index]?.name &&
                                  //   formik.errors.details?.[index]?.name &&
                                  //   "border-rose-500"
                                  // }
                                  handleClearValue={() =>
                                    formik.setFieldValue(
                                      `items[${index}].row`,
                                      ""
                                    )
                                  }
                                  handleOnChange={formik.handleChange}
                                />
                              </div>
                              <div className="flex gap-1 flex-col px-2 py-2 md:w-1/2 w-full lock_form">
                                <div className="min-w-[6.25rem] text-sm12 text-">
                                  Seat
                                </div>
                                <TextInput
                                  name={`items[${index}].seat`}
                                  type="text"
                                  value={obj?.seat}
                                  required={false}
                                  placeholder="Enter seat name"
                                  className="!h-8"
                                  // errorClass={
                                  //   formik.touched.details?.[index]?.name &&
                                  //   formik.errors.details?.[index]?.name &&
                                  //   "border-rose-500"
                                  // }
                                  handleClearValue={() =>
                                    formik.setFieldValue(
                                      `items[${index}].seat`,
                                      ""
                                    )
                                  }
                                  handleOnChange={formik.handleChange}
                                />
                              </div>
                              <div className="flex gap-1 flex-col px-2 py-2 md:w-1/2 w-full lock_form">
                                <div className="min-w-[6.25rem] text-sm12 text-">
                                  Customer email address
                                </div>
                                <div className="flex-1 relative">
                                  <TextInput
                                    name={`items[${index}].customer_email_address`}
                                    type="text"
                                    value={obj?.customer_email_address}
                                    required={false}
                                    placeholder="Enter customer email"
                                    className="!h-8"
                                    handleClearValue={() =>
                                      formik.setFieldValue(
                                        `items[${index}].customer_email_address`,
                                        ""
                                      )
                                    }
                                    handleOnChange={formik.handleChange}
                                  />
                                  {formik.touched?.items?.[index]
                                    ?.customer_email_address &&
                                    formik.errors?.items?.[index]
                                      ?.customer_email_address && (
                                      <div className="text-xs text-rose-500 error-msg ">
                                        {
                                          formik.errors?.items?.[index]
                                            ?.customer_email_address as string
                                        }
                                      </div>
                                    )}
                                </div>
                              </div>
                              <div className="flex gap-1 flex-col px-2 py-2 md:w-1/2 w-full lock_form">
                                <div className="min-w-[6.25rem] text-sm12 text-">
                                  Customer national ID
                                </div>
                                <TextInput
                                  name={`items[${index}].customer_national_id`}
                                  type="text"
                                  value={obj?.customer_national_id}
                                  required={false}
                                  placeholder="Enter national ID"
                                  className="!h-8"
                                  handleClearValue={() =>
                                    formik.setFieldValue(
                                      `items[${index}].customer_national_id`,
                                      ""
                                    )
                                  }
                                  handleOnChange={formik.handleChange}
                                />
                              </div>

                              <div className="flex gap-1 flex-col px-2 py-2 md:w-1/2 w-full lock_form">
                                <div className="min-w-[6.25rem] text-sm12 text-">
                                  Customer nationality
                                </div>
                                <TextInput
                                  name={`items[${index}].customer_nationality`}
                                  type="text"
                                  value={obj?.customer_nationality}
                                  required={false}
                                  placeholder="Enter customer nationality"
                                  className="!h-8"
                                  handleClearValue={() =>
                                    formik.setFieldValue(
                                      `items[${index}].customer_nationality`,
                                      ""
                                    )
                                  }
                                  handleOnChange={formik.handleChange}
                                />
                              </div>

                              <div className="flex gap-1 flex-col px-2 py-2 md:w-1/2 w-full lock_form">
                                <div className="min-w-[6.25rem] text-sm12 text-">
                                  Customer contact number
                                </div>
                                <div className="flex-1 relative">
                                  <TextInput
                                    name={`items[${index}].customer_contact_number`}
                                    type="text"
                                    value={obj?.customer_contact_number}
                                    required={false}
                                    placeholder="Enter customer number "
                                    className="!h-8"
                                    handleClearValue={() =>
                                      formik.setFieldValue(
                                        `items[${index}].customer_contact_number`,
                                        ""
                                      )
                                    }
                                    errorClass={
                                      formik.touched?.items?.[index]
                                        ?.customer_contact_number &&
                                      formik.errors?.items?.[index]
                                        ?.customer_contact_number &&
                                      "border-rose-500"
                                    }
                                    handleOnChange={formik.handleChange}
                                  />
                                  {formik.touched?.items?.[index]
                                    ?.customer_contact_number &&
                                    formik.errors?.items?.[index]
                                      ?.customer_contact_number && (
                                      <div className="text-xs text-rose-500 error-msg ">
                                        {
                                          formik.errors?.items?.[index]
                                            ?.customer_contact_number as string
                                        }
                                      </div>
                                    )}
                                </div>
                              </div>

                              <div className="flex gap-1 flex-col px-2 py-2 md:w-1/2 w-full lock_form">
                                <div className="min-w-[6.25rem] text-sm12 text-">
                                  Customer birth place
                                </div>
                                <TextInput
                                  name={`items[${index}].customer_birth_place`}
                                  type="text"
                                  value={obj?.customer_birth_place}
                                  required={false}
                                  placeholder="Enter customer birth place"
                                  className="!h-8"
                                  handleClearValue={() =>
                                    formik.setFieldValue(
                                      `items[${index}].customer_birth_place`,
                                      ""
                                    )
                                  }
                                  handleOnChange={formik.handleChange}
                                />
                              </div>

                              <div className="flex gap-1 flex-col px-2 py-2 md:w-1/2 w-full lock_form">
                                <div className="min-w-[6.25rem] text-sm12 text-">
                                  Customer address
                                </div>
                                <TextInput
                                  name={`items[${index}].customer_address`}
                                  type="text"
                                  value={obj?.customer_address}
                                  required={false}
                                  placeholder="Enter customer address"
                                  className="!h-8"
                                  handleClearValue={() =>
                                    formik.setFieldValue(
                                      `items[${index}].customer_address`,
                                      ""
                                    )
                                  }
                                  handleOnChange={formik.handleChange}
                                />
                              </div>
                              <div className="flex gap-1 flex-col px-2 py-2 md:w-1/2 w-full lock_form">
                                <div className="min-w-[6.25rem] text-sm12 text-">
                                  Customer date of birth
                                </div>
                                <DatePickerField
                                  name={`items[${index}].customer_dob`}
                                  placeholderMoveTop={false}
                                  disabledKeyboardNavigation={false}
                                  value={obj?.customer_dob}
                                  id={"dateOfBirth"}
                                  isDateRange={false}
                                  IsIconRequire={false}
                                  handleOnChange={(data: any) => {
                                    formik.handleChange(data);
                                  }}
                                  placeholder="DD/MM/YYYY"
                                  format="dd/MM/yy"
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <>
                      {/* TICKET LOOP */}
                      <div className="flex flex-wrap relative md:-right-[1px] lg2:right-0">
                        {show?.data?.items &&
                          show?.data?.items.map((item: any, index: number) => {
                            return (
                              <React.Fragment key={index}>
                                <div
                                  className="lg2:w-1/4 md:w-1/2 w-full p-3 md:border-r lg2:border-b-0 border-b"
                                  key={index}
                                >
                                  {item?.name?.trim() && (
                                    <CopyToClipboard
                                      header={`Ticket ${index + 1} name`}
                                      value={item.name}
                                      className="md:p-2 py-2 w-full"
                                      id={`ticket-name-${index}`}
                                    />
                                  )}

                                  <div className="flex flex-wrap w-full">
                                    <DetailInfo
                                      header="Row"
                                      value={item.row ? item.row : "0"}
                                    />
                                    <DetailInfo
                                      header="Seat"
                                      value={item.seat ? item.seat : "N/A"}
                                    />
                                  </div>

                                  {/* MOBILE LINK */}
                                  {item?.mobile_link?.trim() && (
                                    <>
                                      <div
                                        className={`md:w-1/2 md:p-2 py-2 w-full`}
                                      >
                                        <div className="text-gray-400 text-sm12 mb-1 line-clamp-1">
                                          Link
                                        </div>
                                        <div className="relative inline-flex overflow-hidden text-white rounded group">
                                          <button
                                            type="button"
                                            className="flex items-center text-white bg-violet-500 hover:bg-indigo-500 transition font-medium !rounded-l text-sm13 px-2.5 py-0.5"
                                            onClick={() => {
                                              window.open(
                                                item?.mobile_link,
                                                "_blank"
                                              );
                                            }}
                                          >
                                            <IKTSVG
                                              path={externalLink}
                                              className="w-[.6875rem] h-3 mr-2 fill-white"
                                              svgClassName="w-[.6875rem] h-3"
                                            ></IKTSVG>
                                            Link
                                          </button>
                                          <button
                                            title="Copy link"
                                            type="button"
                                            className="flex items-center text-white bg-gray-200 transition font-medium !rounded-r text-sm13 px-2.5 py-0.5"
                                            onClick={(e: any) => {
                                              let data = copy(
                                                item?.mobile_link
                                              );
                                              setCopyData({
                                                id: index,
                                                value: data,
                                              });
                                              setTimeout(() => {
                                                setCopyData(undefined);
                                              }, 1000);
                                            }}
                                          >
                                            <IKTSVG
                                              path={CloneSVG}
                                              className={`w-[.6875rem] h-3  
                                        
                                          ${
                                            copyData?.id === index
                                              ? "fill-green-600  hover:fill-green-600"
                                              : "fill-violet-500 hover:fill-indigo-500"
                                          }
                                        `}
                                              svgClassName="w-[.6875rem] h-3"
                                            ></IKTSVG>
                                          </button>
                                        </div>
                                      </div>
                                    </>
                                    // <CopyToClipboard
                                    //   header={`Mobile link`}
                                    //   className="md:p-2 py-2 w-full"
                                    //   value={item?.mobile_link}
                                    //   id={`mobile-link-${index}`}
                                    //   handleClick={(data: any) => {
                                    //     let copiedValues = copy(data?.value);
                                    //     setIsCopied({
                                    //       id: data?.id,
                                    //       value: copiedValues,
                                    //     });
                                    //   }}
                                    // />
                                  )}
                                  {/* header={`Ticket ${index + 1} DOB`} */}
                                  {/* DOB & CONTACT */}
                                  <div className={`flex flex-wrap w-full`}>
                                    {item.customer_dob && (
                                      <CopyToClipboard
                                        header={`Customer ticket ${
                                          index + 1
                                        } DOB`}
                                        value={
                                          item?.customer_dob
                                            ? moment(item.customer_dob).format(
                                                "DD/MM/YYYY"
                                              )
                                            : ``
                                        }
                                        id={index}
                                        handleClick={(data: any) => {
                                          let copiedValues = copy(data?.value);
                                          setIsCopied({
                                            id: data?.id,
                                            value: copiedValues,
                                          });
                                        }}
                                      />
                                    )}
                                    {item.customer_contact_number && (
                                      <CopyToClipboard
                                        header={`Customer ticket ${
                                          index + 1
                                        } contact phone`}
                                        value={
                                          item.customer_contact_number
                                            ? item.customer_contact_number
                                            : ""
                                        }
                                        className={`${
                                          item.customer_dob &&
                                          item.customer_contact_number
                                            ? "sm:w-1/2"
                                            : item.customer_dob ||
                                              item.customer_contact_number
                                            ? "sm:w-full"
                                            : ""
                                        } w-full md:p-2 py-2`}
                                        id={`ticket-contact-info-${index}`}
                                      />
                                    )}
                                  </div>

                                  <div className="flex flex-wrap w-full">
                                    {/* EMAIL ADDRESS */}
                                    {item.customer_email_address && (
                                      <CopyToClipboard
                                        header={`Customer ticket ${
                                          index + 1
                                        } email`}
                                        value={
                                          item.customer_email_address
                                            ? item.customer_email_address
                                            : ""
                                        }
                                        className="sm:w-1/2 w-full md:p-2 py-2"
                                        id={`ticket-email-${index}`}
                                      />
                                    )}

                                    {/* NATIONALITY ID */}
                                    {item.customer_national_id && (
                                      <CopyToClipboard
                                        header={`Customer ticket ${
                                          index + 1
                                        } national ID`}
                                        value={
                                          item.customer_national_id
                                            ? item.customer_national_id
                                            : ""
                                        }
                                        className="sm:w-1/2 w-full md:p-2 py-2"
                                        id={`ticket-id-${index}`}
                                      />
                                    )}
                                  </div>

                                  {/* NATIONALITY */}
                                  <div className="flex flex-wrap w-full">
                                    {item.customer_nationality && (
                                      <CopyToClipboard
                                        header={`Customer ticket ${
                                          index + 1
                                        } nationality`}
                                        value={
                                          item.customer_nationality
                                            ? item.customer_nationality
                                            : ""
                                        }
                                        className="sm:w-full w-full md:p-2 py-2"
                                        id={`ticket-nationality-${index}`}
                                      />
                                    )}
                                  </div>

                                  <div className="flex flex-wrap w-full">
                                    {item.customer_birth_place && (
                                      <CopyToClipboard
                                        header={`Customer ticket ${
                                          index + 1
                                        } birth place`}
                                        value={
                                          item.customer_birth_place
                                            ? item.customer_birth_place
                                            : ""
                                        }
                                        className="sm:w-full w-full md:p-2 py-2"
                                        id={`customer_birth_place-${index}`}
                                      />
                                    )}
                                  </div>

                                  <div className="flex flex-wrap w-full">
                                    {item.customer_address && (
                                      <CopyToClipboard
                                        header={`Customer ticket ${
                                          index + 1
                                        } address`}
                                        value={
                                          item.customer_address
                                            ? item.customer_address
                                            : ""
                                        }
                                        className="sm:w-full w-full md:p-2 py-2"
                                        id={`customer_address-${index}`}
                                      />
                                    )}
                                  </div>

                                  {PdfTickitCheck &&
                                    show?.data?.fulfilled_ticket_files?.e_tickets?.map(
                                      (eticket: any, rIndex: any) => {
                                        if (item?.id === eticket?.item_id) {
                                          return (
                                            <React.Fragment key={rIndex}>
                                              <div className="flex flex-wrap w-full">
                                                <div className=" md:p-2 py-2 w-full">
                                                  <button
                                                    type="button"
                                                    className="group inline-flex group items-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 false"
                                                    onClick={() => {
                                                      if (
                                                        eticket?.is_url === 0
                                                      ) {
                                                        downloadPDF(
                                                          eticket?.file,
                                                          "ticket.pdf"
                                                        );
                                                        openBlobPDF(
                                                          eticket?.file
                                                        );
                                                      } else if (
                                                        eticket?.is_url === 1
                                                      ) {
                                                        window.open(
                                                          eticket?.file
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    {loader ? (
                                                      <IKTSVG
                                                        className="flex items-center justify-center fill-violet-500 transition animate-spin"
                                                        path={processSVG}
                                                      />
                                                    ) : (
                                                      <IKTSVG
                                                        className="flex items-center justify-center fill-white transition mr-2 rotate-180"
                                                        path={UploadSVG}
                                                        svgClassName="w-[.8125rem] h-[.6875rem]"
                                                      />
                                                    )}
                                                    Download ticket
                                                  </button>
                                                </div>
                                              </div>
                                            </React.Fragment>
                                          );
                                        }
                                      }
                                    )}
                                </div>
                              </React.Fragment>
                            );
                          })}
                      </div>
                    </>
                  )}
                </div>

                {/* for mobile */}
                {show?.data?.fulfilled_ticket_files?.mobile_transfer_proofs
                  ?.length > 0 && (
                  <div className="ticket-detail border  mb-5 bg-white">
                    <div className="title  md:px-5 px-3  py-[9px] flex flex-wrap justify-between items-center border-b">
                      <span className="md:text-sm15 text-sm12 block font-semibold">
                        Mobile transfer proofs
                      </span>
                    </div>
                    <div className="flex flex-wrap w-full">
                      <div className=" md:px-5 px-3  py-[9px]  w-full flex gap-2.5 flex-wrap">
                        {show?.data?.fulfilled_ticket_files?.mobile_transfer_proofs?.map(
                          (item: any, index: number) => {
                            return (
                              <React.Fragment key={index}>
                                <div className="flex flex-wrap ">
                                  <button
                                    type="button"
                                    className="group inline-flex group items-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 false"
                                    onClick={() => {
                                      window.open(item);
                                    }}
                                  >
                                    {loader ? (
                                      <IKTSVG
                                        className="flex items-center justify-center fill-violet-500 transition animate-spin"
                                        path={processSVG}
                                      />
                                    ) : (
                                      <IKTSVG
                                        className="flex items-center justify-center fill-white transition mr-2 rotate-180"
                                        path={UploadSVG}
                                      />
                                    )}
                                    Download file
                                  </button>
                                </div>
                              </React.Fragment>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {/* addtional file here */}
                {data?.additional_file && (
                  <div className="ticket-detail border rounded mb-5 bg-white">
                    <div className="title  md:px-5 px-3  py-[9px] flex flex-wrap justify-between items-center border-b">
                      <span className="md:text-sm15 text-sm12 block font-semibold">
                        Additional file
                      </span>
                    </div>
                    <div className="flex flex-wrap relative md:-right-[1px] lg2:right-0">
                      <div className="flex flex-wrap w-full">
                        <div className="md:w-1/2 md:px-5 px-3  py-[9px] w-full">
                          <button
                            type="button"
                            className="group inline-flex group items-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 false"
                            onClick={() => {
                              if (data?.additional_file) {
                                window.open(data?.additional_file);
                              }
                            }}
                          >
                            {loader ? (
                              <IKTSVG
                                className="flex items-center justify-center fill-violet-500 transition animate-spin"
                                path={processSVG}
                              />
                            ) : (
                              <IKTSVG
                                className="flex items-center justify-center fill-white transition mr-2 rotate-180"
                                path={UploadSVG}
                              />
                            )}
                            Download file
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="flex flex-wrap -m-2.5">
                  {/* BILLING ADDRESS */}
                  {(isGodAdmin || checkBillingAddress) && (
                    <OrderDetailBox
                      title="Billing address"
                      headerClass="md:w-1/2 w-full p-2.5"
                      className="flex flex-wrap px-3 py-3 font-medium"
                      lockIcon={
                        <>
                          {isGodAdmin && (
                            <div
                              className=" pb-0 lock-icon flex justify-start w-10 flex items-center justify-center border-l group"
                              onClick={() => setIsAddressLock(!isAddressLock)}
                            >
                              {/* <img
                                // src={isAddressLock ? LockIcon : LockIconOpen}
                                src={isAddressLock ? `${pngImagePath}lock.png` : `${pngImagePath}unlock.png` }
                                alt="lockIcon"
                                className="!w-[.9375rem] !h-[.9375rem]"
                              /> */}
                              <IKTSVG
                                svgClassName="!w-[.8125rem] !h-[.8125rem]"
                                path={isAddressLock ? LockIcon : LockIconOpen}
                              />
                            </div>
                          )}
                        </>
                      }
                    >
                      {isAddressLock === false && isGodAdmin ? (
                        <>
                          {/* EDIT BILING ADDRESS */}
                          <div className="flex flex-wrap px-3 py-3 gap-2 form_cols attendee-form lock_form">
                            <div className="flex gap-1 flex-col">
                              <div className="min-w-[6.25rem] text-sm12 text-">
                                Billing address line 1
                              </div>
                              <TextInput
                                name={`customer_billing_address_line_1`}
                                type="text"
                                value={
                                  formik?.values
                                    ?.customer_billing_address_line_1
                                }
                                required={false}
                                placeholder="Enter address line 1"
                                className="!h-8"
                                // errorClass={
                                //   formik.touched.details?.[index]?.name &&
                                //   formik.errors.details?.[index]?.name &&
                                //   "border-rose-500"
                                // }
                                handleClearValue={() =>
                                  formik.setFieldValue(
                                    `customer_billing_address_line_1`,
                                    ""
                                  )
                                }
                                handleOnChange={formik.handleChange}
                              />
                            </div>
                            <div className="flex gap-1 flex-col">
                              <div className="min-w-[6.25rem] text-sm12 text-">
                                Billing address line 2
                              </div>
                              <TextInput
                                name={`customer_billing_address_line_2`}
                                type="text"
                                value={
                                  formik?.values
                                    ?.customer_billing_address_line_2
                                }
                                required={false}
                                placeholder="Enter address line 2"
                                className="!h-8"
                                // errorClass={
                                //   formik.touched.details?.[index]?.name &&
                                //   formik.errors.details?.[index]?.name &&
                                //   "border-rose-500"
                                // }

                                handleClearValue={() =>
                                  formik.setFieldValue(
                                    `customer_billing_address_line_2`,
                                    ""
                                  )
                                }
                                handleOnChange={formik.handleChange}
                              />
                            </div>
                            <div className="flex gap-1 flex-col">
                              <div className="min-w-[6.25rem] text-sm12 text-">
                                Billing address town
                              </div>
                              <TextInput
                                name={`customer_billing_town`}
                                type="text"
                                value={formik?.values?.customer_billing_town}
                                required={false}
                                placeholder="Enter address town"
                                className="!h-8"
                                // errorClass={
                                //   formik.touched.details?.[index]?.name &&
                                //   formik.errors.details?.[index]?.name &&
                                //   "border-rose-500"
                                // }
                                handleClearValue={() =>
                                  formik.setFieldValue(
                                    `customer_billing_town`,
                                    ""
                                  )
                                }
                                handleOnChange={formik.handleChange}
                              />
                            </div>
                            <div className="flex gap-1 flex-col">
                              <div className="min-w-[6.25rem] text-sm12 text-">
                                Billing address country
                              </div>
                              <TextInput
                                name={`customer_billing_country`}
                                type="text"
                                value={formik?.values?.customer_billing_country}
                                required={false}
                                placeholder="Enter address country"
                                className="!h-8"
                                // errorClass={
                                //   formik.touched.details?.[index]?.name &&
                                //   formik.errors.details?.[index]?.name &&
                                //   "border-rose-500"
                                // }
                                handleClearValue={() =>
                                  formik.setFieldValue(
                                    `customer_billing_country`,
                                    ""
                                  )
                                }
                                handleOnChange={formik.handleChange}
                              />
                            </div>
                            <div className="flex gap-1 flex-col">
                              <div className="min-w-[6.25rem] text-sm12 text-">
                                Billing address postcode
                              </div>
                              <TextInput
                                name={`customer_billing_postcode`}
                                type="text"
                                value={
                                  formik?.values?.customer_billing_postcode
                                }
                                required={false}
                                placeholder="Enter address postcode"
                                className="!h-8"
                                // errorClass={
                                //   formik.touched.details?.[index]?.name &&
                                //   formik.errors.details?.[index]?.name &&
                                //   "border-rose-500"
                                // }
                                handleClearValue={() =>
                                  formik.setFieldValue(
                                    `customer_billing_postcode`,
                                    ""
                                  )
                                }
                                handleOnChange={formik.handleChange}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <CopyToClipboardTextArea
                          id="billing"
                          // value={`${
                          //   show?.data?.customer_billing_address_line_1
                          //     ? `${show?.data?.customer_billing_address_line_1},`
                          //     : " "
                          // }
                          //   ${
                          //     show?.data?.customer_billing_address_line_2
                          //       ? `${show?.data?.customer_billing_address_line_2},`
                          //       : " "
                          //   }
                          //   ${
                          //     show?.data?.customer_billing_town
                          //       ? `${show?.data?.customer_billing_town},`
                          //       : " "
                          //   }
                          //   ${
                          //     show?.data?.customer_billing_postcode
                          //       ? `${show?.data?.customer_billing_postcode},`
                          //       : " "
                          //   }
                          //   ${
                          //     show?.data?.customer_billing_country
                          //       ? `${show?.data?.customer_billing_country}`
                          //       : " "
                          //   }
                          //   `}
                          value={
                            !checkBillingAddress
                              ? "N/A"
                              : (show?.data?.customer_billing_address_line_1
                                  ? `${show?.data?.customer_billing_address_line_1},\n`
                                  : "") +
                                (show?.data?.customer_billing_address_line_2
                                  ? `${show?.data?.customer_billing_address_line_2},\n`
                                  : "") +
                                (show?.data?.customer_billing_town
                                  ? `${show?.data?.customer_billing_town},\n`
                                  : "") +
                                (show?.data?.customer_billing_postcode
                                  ? `${show?.data?.customer_billing_postcode},\n`
                                  : "") +
                                (show?.data?.customer_billing_country
                                  ? show?.data?.customer_billing_country
                                  : "")
                          }
                        />
                      )}
                    </OrderDetailBox>
                  )}

                  {/* SHIPPING ADDRESS */}
                  {(isGodAdmin || checkShippingAddress) && (
                    <OrderDetailBox
                      title="Shipping address"
                      headerClass="md:w-1/2 w-full p-2.5"
                      className="flex flex-wrap px-3 py-3 font-medium"
                      lockIcon={
                        isGodAdmin && (
                          <div
                            className=" pb-0 lock-icon flex justify-start w-10 flex items-center justify-center border-l group"
                            onClick={() =>
                              setShippingIsAddressLock(!isShippingAddressLock)
                            }
                          >
                            {/* <img
                              // src={ isShippingAddressLock ? LockIcon : LockIconOpen }
                              src={isShippingAddressLock ? `${pngImagePath}lock.png` : `${pngImagePath}unlock.png` }
                              alt="lockIcon"
                              className="!w-[.9375rem] !h-[.9375rem]"
                            /> */}
                            <IKTSVG
                              svgClassName="!w-[.8125rem] !h-[.8125rem]"
                              path={
                                isShippingAddressLock ? LockIcon : LockIconOpen
                              }
                            />
                          </div>
                        )
                      }
                    >
                      {isShippingAddressLock === false && isGodAdmin ? (
                        <>
                          {/* EDIT SHIPPING ADDRESS */}
                          <div className="flex flex-wrap px-3 py-3 gap-2  form_cols attendee-form lock_form">
                            <div className="flex gap-1 flex-col">
                              <div className="min-w-[6.25rem] text-sm12 text-">
                                Shipping address line 1
                              </div>
                              <TextInput
                                name={`customer_shipping_address_line_1`}
                                type="text"
                                value={
                                  formik?.values
                                    ?.customer_shipping_address_line_1
                                }
                                required={false}
                                placeholder="Enter address line 1"
                                className="!h-8"
                                // errorClass={
                                //   formik.touched.details?.[index]?.name &&
                                //   formik.errors.details?.[index]?.name &&
                                //   "border-rose-500"
                                // }
                                handleClearValue={() =>
                                  formik.setFieldValue(
                                    `customer_shipping_address_line_1`,
                                    ""
                                  )
                                }
                                handleOnChange={formik.handleChange}
                              />
                            </div>
                            <div className="flex gap-1 flex-col">
                              <div className="min-w-[6.25rem] text-sm12 text-">
                                Shipping address line 2
                              </div>
                              <TextInput
                                name={`customer_shipping_address_line_2`}
                                type="text"
                                value={
                                  formik?.values
                                    ?.customer_shipping_address_line_2
                                }
                                required={false}
                                placeholder="Enter address line 2"
                                className="!h-8"
                                // errorClass={
                                //   formik.touched.details?.[index]?.name &&
                                //   formik.errors.details?.[index]?.name &&
                                //   "border-rose-500"
                                // }
                                handleClearValue={() =>
                                  formik.setFieldValue(
                                    `customer_shipping_address_line_2`,
                                    ""
                                  )
                                }
                                handleOnChange={formik.handleChange}
                              />
                            </div>
                            <div className="flex gap-1 flex-col">
                              <div className="min-w-[6.25rem] text-sm12 text-">
                                Shipping address town
                              </div>
                              <TextInput
                                name={`customer_shipping_town`}
                                type="text"
                                value={formik?.values?.customer_shipping_town}
                                required={false}
                                placeholder="Enter address town"
                                className="!h-8"
                                // errorClass={
                                //   formik.touched.details?.[index]?.name &&
                                //   formik.errors.details?.[index]?.name &&
                                //   "border-rose-500"
                                // }
                                handleClearValue={() =>
                                  formik.setFieldValue(
                                    `customer_shipping_town`,
                                    ""
                                  )
                                }
                                handleOnChange={formik.handleChange}
                              />
                            </div>
                            <div className="flex gap-1 flex-col">
                              <div className="min-w-[6.25rem] text-sm12 text-">
                                Shipping address country
                              </div>
                              <TextInput
                                name={`customer_shipping_country`}
                                type="text"
                                value={
                                  formik?.values?.customer_shipping_country
                                }
                                required={false}
                                placeholder="Enter address country"
                                className="!h-8"
                                // errorClass={
                                //   formik.touched.details?.[index]?.name &&
                                //   formik.errors.details?.[index]?.name &&
                                //   "border-rose-500"
                                // }
                                handleClearValue={() =>
                                  formik.setFieldValue(
                                    `customer_shipping_country`,
                                    ""
                                  )
                                }
                                handleOnChange={formik.handleChange}
                              />
                            </div>
                            <div className="flex gap-1 flex-col">
                              <div className="min-w-[6.25rem] text-sm12 text-">
                                Shipping address postcode
                              </div>
                              <TextInput
                                name={`customer_shipping_postcode`}
                                type="text"
                                value={
                                  formik?.values?.customer_shipping_postcode
                                }
                                required={false}
                                placeholder="Enter address postcode"
                                className="!h-8"
                                // errorClass={
                                //   formik.touched.details?.[index]?.name &&
                                //   formik.errors.details?.[index]?.name &&
                                //   "border-rose-500"
                                // }
                                handleClearValue={() =>
                                  formik.setFieldValue(
                                    `customer_shipping_postcode`,
                                    ""
                                  )
                                }
                                handleOnChange={formik.handleChange}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <CopyToClipboardTextArea
                          id="shipping"
                          //   value={`${
                          //     show?.data?.customer_billing_address_line_1
                          //       ? `${show?.data?.customer_billing_address_line_1},`
                          //       : " "
                          //   }
                          // ${
                          //   show?.data?.customer_billing_address_line_2
                          //     ? `${show?.data?.customer_billing_address_line_2},`
                          //     : " "
                          // }
                          // ${
                          //   show?.data?.customer_billing_town
                          //     ? `${show?.data?.customer_billing_town},`
                          //     : " "
                          // }
                          // ${
                          //   show?.data?.customer_billing_postcode
                          //     ? `${show?.data?.customer_billing_postcode},`
                          //     : " "
                          // }
                          // ${
                          //   show?.data?.customer_billing_country
                          //     ? `${show?.data?.customer_billing_country}`
                          //     : " "
                          // }
                          // `}
                          value={
                            !checkShippingAddress
                              ? "N/A"
                              : (show?.data?.customer_shipping_address_line_1
                                  ? `${show.data.customer_shipping_address_line_1},\n`
                                  : "") +
                                (show?.data?.customer_shipping_address_line_2
                                  ? `${show.data.customer_shipping_address_line_2},\n`
                                  : "") +
                                (show?.data?.customer_shipping_town
                                  ? `${show.data.customer_shipping_town},\n`
                                  : "") +
                                (show?.data?.customer_shipping_postcode
                                  ? `${show.data.customer_shipping_postcode},\n`
                                  : "") +
                                (show?.data?.customer_shipping_country
                                  ? show.data.customer_shipping_country
                                  : "")
                          }
                        />
                      )}
                    </OrderDetailBox>
                  )}

                  {/* FIFTH LAYER */}
                  <OrderDetailBox
                    title="Order status"
                    headerClass="md:w-1/2 w-full p-2.5"
                    className="flex flex-wrap px-3 py-3 font-medium"
                    lockIcon={
                      isGodAdmin && (
                        <div
                          className=" pb-0 lock-icon flex justify-start w-10 flex items-center justify-center border-l group"
                          onClick={() => {
                            setTicketTypeValue(null);
                            setButtonShow(false);
                            setIsEditable(false);
                            setIsStatusLock(!isStatusLock);

                            if (!isStatusLock) {
                              formik.setFieldValue(
                                "order_status_id",
                                show?.data?.internal_order_status
                              );
                            }
                          }}
                        >
                          {/* <img
                            // src={ isShippingAddressLock ? LockIcon : LockIconOpen }
                            src={isShippingAddressLock ? `${pngImagePath}lock.png` : `${pngImagePath}unlock.png` }
                            alt="lockIcon"
                            className="!w-[.9375rem] !h-[.9375rem]"
                          /> */}
                          <IKTSVG
                            svgClassName="!w-[.8125rem] !h-[.8125rem] group-hover:fill-indigo-500"
                            path={isStatusLock ? LockIcon : LockIconOpen}
                          />
                        </div>
                      )
                    }
                  >
                    {/* <div className=" md:p-2 lg:mr-28 md:mr-10 md:w-auto w-1/2">
                      <div className="text-gray-400 text-sm12 mb-1">
                        Order status
                      </div>
                      {legacyCheck === "true" || legacyCheck === true ? (
                        <div className={``}>
                          <div
                            className={`inline-flex px-2.5 py-[3px] text-sm12 text-white rounded-l ${statusColor}`}
                          >
                            {data?.order_status}
                          </div>
                          <div
                            className={`inline-flex px-2.5 py-[3px] text-sm12 text-violet-800 rounded-r capitalize ${dateColor}`}
                          >
                            Legacy order
                          </div>
                        </div>
                      ) : (
                        <button
                          className={`px-2.5 py-[3px] ${
                            legacyCheck === "true" || legacyCheck === true
                              ? `bg-rose-500`
                              : data?.order_status === "Commissionable" ||
                                data?.order_status === "Paid"
                              ? `bg-green-600`
                              : `bg-violet-500`
                          }  text-sm12 text-white rounded pointer-events-none`}
                        >
                          {data?.order_status}
                        </button>
                      )}
                    </div> */}

                    {/* ORDER STATUS */}
                    <div className=" md:p-2 max-md:py-2 lg:mr-28 md:mr-10 w-full">
                      <div className="text-gray-400 text-sm12 mb-1">
                        Order status
                      </div>
                      {/* {isEditable1 ? (
                        <div className="w-full text-sm12 border-violet-900 flex flex-wrap items-center relative">
                          {!isStatusLock && (
                            <TableDropdown
                              options={ticketTypeOptions}
                              name="change_ticket_type"
                              placeholder="Ticket Type"
                              value={ticketTypeValue}
                              isClearable={false}
                              handleOnChange={handleDropdownChange}
                              isValueCapital={true}
                              isCapital={true}
                            />
                          )}

                          <div className="flex ml-2">
                            {!isStatusLock && (
                              <>
                                {buttonShow && (
                                  <button
                                    type="button"
                                    id={`confirm-ticket-type`}
                                    className={`bg-violet-500  hover:bg-indigo-500 border border-violet-500 group flex mx-1 items-center justify-center h-6 w-6  hover:border-indigo-500 text-white transition rounded mr-0`}
                                    onClick={() => {
                                      handleConfirm(
                                        show?.data?.order_id,
                                        ticketTypeValue?.id
                                      );
                                    }}
                                  >
                                    <IKTSVG
                                      path={CheckSVG}
                                      className={`group-hover:fill-white transition fill-white`}
                                      svgClassName="w-3"
                                    />
                                    <Tooltip
                                      anchorId={`confirm-ticket-type`}
                                      content="Confirm"
                                      place="top"
                                      variant="light"
                                      className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                    />
                                  </button>
                                )}
                                <button
                                  type="button"
                                  className={`bg-violet-500  hover:bg-indigo-500 border border-violet-500 group flex mx-1 items-center justify-center h-6 w-6  hover:border-indigo-500 text-white transition rounded mr-0`}
                                  id={`cancel-ticket-type`}
                                  onClick={() => {
                                    setTicketTypeValue(null);
                                    setButtonShow(false);
                                    setisEditable1(false);
                                  }}
                                >
                                  <Tooltip
                                    anchorId={`cancel-ticket-type`}
                                    content="Cancel"
                                    place="top"
                                    variant="light"
                                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                  />
                                  <IKTSVG
                                    className="fill-white transition"
                                    path={CrossSVG}
                                    svgClassName="w-2.5"
                                  />
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      ) : ( */}
                      <div className={"flex text-gray-400 text-sm12"}>
                        <div
                          className={`inline-flex px-2.5 py-[3px] ${statusColor} text-sm12 text-white rounded-l`}
                        >
                          {data?.order_status}
                        </div>
                        <div
                          className={`inline-flex px-2.5 py-[3px] text-sm12 text-violet-800 rounded-r ${dateColor}`}
                        >
                          {legacyCheck === "true" || legacyCheck === true ? (
                            <span className="capitalize">Legacy order</span>
                          ) : data?.order_status === "Cancelled" &&
                            data?.internal_order_status !== "" &&
                            isGodAdmin ? (
                            data?.internal_order_status
                          ) : (
                            ticketTypeRenameForSalesReport(
                              data?.ticket_type,
                              data?.sub_ticket_type
                            )
                          )}
                        </div>
                        {/* {!isEditable1 &&
                            isTicketTypeChange &&
                            !isStatusLock && (
                              <button
                                type="button"
                                id={`edit-ticket-type`}
                                className={`bg-violet-500  hover:bg-indigo-500 border border-violet-500 group flex mx-1 items-center justify-center h-6 w-6  hover:border-indigo-500 text-white transition rounded mr-0`}
                                onClick={() => setisEditable1(true)}
                              >
                                <IKTSVG
                                  path={EditSVG}
                                  className={` group-hover:fill-white transition fill-white`}
                                  svgClassName="w-3"
                                />
                                <Tooltip
                                  anchorId={`edit-ticket-type`}
                                  content="Edit"
                                  place="top"
                                  variant="light"
                                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                />
                              </button>
                            )} */}
                      </div>
                      {/* )} */}
                    </div>

                    {isGodAdmin && (
                      <div className=" md:w-1/2 w-full md:p-2 py-2">
                        <div className="flex gap-1">
                          {" "}
                          <p className="text-gray-400 text-sm12 flex flex-inline mb-1">
                            Internal order status
                          </p>{" "}
                          {/* <span
                            className="ml-2 cursor-pointer"
                            onClick={() => setIsStatusLock(!isStatusLock)}
                          >
                            <IKTSVG
                              svgClassName="!w-[.8125rem] !h-[.8125rem]"
                              path={isStatusLock ? LockIcon : LockIconOpen}
                            />
                          </span> */}
                        </div>

                        {isGodAdmin && !isStatusLock ? (
                          <div className="w-full text-sm12 border-violet-900 flex flex-wrap items-center relative">
                            <TableDropdown
                              options={convertToFormattedData(
                                optionsData?.data?.internal_order_statuses
                              )}
                              name="order_status_id"
                              placeholder="Order status"
                              isOrderStatusDropdown="true"
                              value={convertToFormattedData(
                                optionsData?.data?.internal_order_statuses
                              )
                                ?.flatMap((group: any) => group.options)
                                .find(
                                  (option: any) =>
                                    option.name ===
                                    formik?.values?.order_status_id
                                )}
                              isClearable={true}
                              handleOnChange={(e: any) => {
                                formik.setFieldValue(
                                  `order_status_id`,
                                  e.target.value?.id
                                );
                                formik.setFieldValue(
                                  `order_status`,
                                  e.target.value?.description
                                );
                              }}
                              // getOptionLabel="name"
                              // getOptionValue="id"
                              isCapital={true}
                              isValueCapital={true}
                            />
                          </div>
                        ) : (
                          <p className="text-sm12 mt-1">
                            {formik?.values?.order_status_id
                              ? formik?.values?.order_status_id
                              : "-"}
                          </p>
                        )}
                      </div>
                    )}

                    <div className=" md:p-2 py-2 md:w-auto w-1/2">
                      <div className="text-gray-400 text-sm12 mb-1">
                        Delivery By
                      </div>
                      <div
                        className={`px-2.5 py-[3px] text-sm12 rounded inline-block ${dateColor}`}
                      >
                        {/* {moment(data?.order_datetime, "DD-MM-YYYY").format(
                          "DD/MM/YYYY"
                        )} */}
                        {data?.deliver_by}
                      </div>
                    </div>
                  </OrderDetailBox>

                  {/* FIFTH LAYER - PART 2*/}
                  <OrderDetailBox
                    title="Payment details"
                    headerClass="md:w-1/2 w-full p-2.5"
                    className="flex flex-wrap px-3 py-3 font-medium"
                  >
                    <SubDetailInfo
                      header="Transaction value"
                      value={
                        data?.transaction_value
                          ? `${SYMBOLS?.[data?.ticket_currency]}${decimalNumber(
                              data?.transaction_value
                            )}`
                          : "-"
                      }
                    />
                    <SubDetailInfo header="Payment method" value="-" />
                    <SubDetailInfo header=" Transaction ID" value={"-"} />
                  </OrderDetailBox>

                  {/* SIXTH LAYER */}
                  <OrderDetailBox
                    title="Order notes"
                    headerClass="md:w-1/2 w-full p-2.5 mb-5"
                    className="md:p-5 p-3 max-h-72 overflow-y-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full"
                  >
                    <div
                      className={`add-note-btn ${
                        orderNotes?.length <= 0 ? "" : "mb-5"
                      } ${noteLoader ? `shimmer-effect` : ``}`}
                    >
                      {!orderText?.show && (
                        <button
                          type="button"
                          className="group inline-flex group items-center text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 false"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#detailCanvas"
                          aria-controls="detailCanvas"
                          onClick={HadleAddOrderNote}
                        >
                          <IKTSVG
                            className="min-w-[0.75rem] max-w-[0.75rem] mr-1 flex items-center justify-center fill-violet-500 group-hover:fill-white"
                            path={AddSVG}
                            svgClassName={`fill-violet-500`}
                          />
                          Add note
                        </button>
                      )}

                      {orderText?.show && (
                        <div
                          className={`add-note-btn  ${
                            noteLoader ? `shimmer-effect` : ``
                          }`}
                        >
                          <div className="m-auto">
                            {isGodAdmin && (
                              <div
                                className={`flex items-center gap-2 font-medium pb-2.5 ${
                                  orderText?.confirmed
                                    ? "pointer-events-none"
                                    : ""
                                }`}
                              >
                                <div className="inline-flex items-center font-medium rounded text-sm13 single_radio leading-5">
                                  <input
                                    type="radio"
                                    name="orderType"
                                    value="internal"
                                    checked={
                                      orderText?.orderType === "internal"
                                        ? true
                                        : false
                                    }
                                    disabled={
                                      orderText?.confirmed ? true : false
                                    }
                                    id="orderNoteInternal"
                                    onChange={(e: any) => {
                                      setOrderText((prev: any) => {
                                        return {
                                          ...prev,
                                          orderType: "internal",
                                        };
                                      });
                                    }}
                                  />
                                  <label
                                    htmlFor="orderNoteInternal"
                                    className="ml-1"
                                  >
                                    Internal
                                  </label>
                                </div>
                                <div className="inline-flex items-center font-medium rounded text-sm13 single_radio leading-5">
                                  <input
                                    type="radio"
                                    name="orderType"
                                    value="external"
                                    checked={
                                      orderText?.orderType === "external"
                                        ? true
                                        : false
                                    }
                                    id="orderNoteExternal"
                                    disabled={
                                      orderText?.confirmed ? true : false
                                    }
                                    onChange={(e: any) => {
                                      setOrderText((prev: any) => {
                                        return {
                                          ...prev,
                                          orderType: "external",
                                        };
                                      });
                                    }}
                                  />
                                  <label
                                    htmlFor="orderNoteExternal"
                                    className="ml-1"
                                  >
                                    External
                                  </label>
                                </div>
                              </div>
                            )}
                            <input
                              className={` font-medium transition bg-gray-100 focus:border-indigo-500 focus:bg-white outline-none peer
                                border border-gray-100 whitespace-normal text-sm12 rounded w-full px-2 block text-violet-800 resize-none py-1 leading-4`}
                              value={orderText?.note}
                              name="note"
                              disabled={orderText?.confirmed ? true : false}
                              onChange={(e: any) => handleOrderText(e)}
                              placeholder={Messages?.orderNotePlaceholder}
                            />

                            {orderText?.confirmed ? (
                              <div className="flex flex-wrap mt-2.5 gap-x-2">
                                <div className="conf_text flex flex-wrap w-full items-center gap-2.5">
                                  <p className="text-sm12 ">
                                    Are you sure you want to add this
                                    {orderText?.orderType === "internal"
                                      ? " INTERNAL"
                                      : " EXTERNAL"}{" "}
                                    note?
                                  </p>
                                  <button
                                    type="button"
                                    className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
                                    onClick={cancleOrderText}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="button"
                                    className="border border-violet-500 hover:border-indigo-500 text-violet-800 text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
                                    onClick={(e: any) => addOrderText(e)}
                                  >
                                    Confirm
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <>
                                <div className="flex flex-wrap mt-2.5 gap-x-2">
                                  {/* RESET */}
                                  <button
                                    type="button"
                                    className="group flex items-center justify-center h-6 w-6 border border-gray-300 hover:border-violet-500 text-white bg-white hover:bg-violet-500 transition rounded"
                                    data-tooltip-id={`reset-order-note`}
                                    onClick={() => {
                                      setOrderText((prev: any) => {
                                        return {
                                          show: true,
                                          note: "",
                                          orderType: "internal",
                                        };
                                      });
                                    }}
                                  >
                                    <IKTSVG
                                      className="fill-violet-500 group-hover:fill-white transition"
                                      path={ReloadIconSVG}
                                      svgClassName="w-[.75rem] h-[.75rem]"
                                    />
                                    <Tooltip
                                      id={`reset-order-note`}
                                      content={`Reset`}
                                      place="top"
                                      variant="light"
                                      className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10 "
                                    />
                                  </button>

                                  {/* CANCEL */}
                                  <button
                                    type="button"
                                    data-tooltip-id={`cancel-order-note`}
                                    className="group flex items-center justify-center h-6 w-6 border border-gray-300 hover:border-violet-500 text-white bg-white hover:bg-violet-500 transition rounded"
                                    onClick={cancleOrderText}
                                  >
                                    <IKTSVG
                                      className="fill-violet-500 group-hover:fill-white transition"
                                      path={CrossSVG}
                                      svgClassName="w-[.625rem] h-[.625rem]"
                                    />
                                    <Tooltip
                                      id={`cancel-order-note`}
                                      content={`Cancel`}
                                      place="top"
                                      variant="light"
                                      className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10 "
                                    />
                                  </button>

                                  {/* SAVE */}
                                  <button
                                    type="button"
                                    data-tooltip-id={`save-order-note`}
                                    disabled={orderText?.note ? false : true}
                                    onClick={(e: any) => {
                                      setOrderText((prev: any) => {
                                        return {
                                          ...prev,
                                          confirmed: isGodAdmin ? true : false,
                                        };
                                      });

                                      !isGodAdmin && addOrderText(e);
                                    }}
                                    className={`${
                                      !orderText?.note
                                        ? "pointer-events-none bg-gray-100"
                                        : ""
                                    } group flex items-center justify-center h-6 w-6 border  border-gray-300 hover:border-violet-500 text-white bg-white hover:bg-violet-500 transition rounded`}
                                  >
                                    <IKTSVG
                                      className="fill-violet-500 group-hover:fill-white transition"
                                      path={CheckSVG}
                                      svgClassName="w-[.75rem] h-[.625rem]"
                                    />
                                    <Tooltip
                                      id={`save-order-note`}
                                      content={`${
                                        isGodAdmin ? "Save" : "Confirm"
                                      }`}
                                      place="top"
                                      variant="light"
                                      className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10 "
                                    />
                                  </button>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )}

                      {/* {orderText?.show && (
                        <div className="m-auto">
                          <textarea
                            className={`PLACEHOLDER:truncate font-medium transition bg-gray-100 focus:border-indigo-500 focus:bg-violet-300/50 peer
                      border border-gray-300 whitespace-normal text-sm12 rounded w-full px-2 block text-violet-800 resize-none py-1`}
                            value={orderText?.note}
                            onChange={(e: any) => handleOrderText(e)}
                            placeholder={Messages?.orderNotePlaceholder}
                          ></textarea>
                          <button
                            className="mt-2 border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
                            onClick={cancleOrderText}
                          >
                            Cancel
                          </button>

                          <button
                            onClick={(e: any) => addOrderText(e)}
                            className={`mt-2 group inline-flex group items-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 false mx-2`}
                          >
                            Save
                          </button>
                        </div>
                      )} */}
                    </div>
                    {orderNotes?.map((note: any) => {
                      return (
                        <div className="note-block">
                          <div
                            className={`mb-4 ${
                              noteLoader ? `shimmer-effect` : ``
                            }`}
                          >
                            <span className="text-sm12 font-medium mb-1 block text-gray-400">
                              {note?.type === 1
                                ? "Internal"
                                : note?.type === 2
                                ? "Penalty"
                                : note?.type === 3
                                ? "External"
                                : note?.type === 4 && note?.user_name}
                              {" - "}
                              {moment(note?.date).format("DD/MM/YYYY HH:mm")}
                            </span>
                            <div className="px-2 py-1 bg-gray-100 flex justify-between rounded whitespace-pre-line ">
                              <p className="text-sm12 break-words overflow-hidden">
                                {note?.note}
                              </p>
                              {note?.type !== 2 && (
                                <span
                                  id={`note-${note?.index}`}
                                  data-tooltip-id={`note-${note?.index}`}
                                  className="mt-1 mr-0.5 cursor-pointer transition right-0 top-0"
                                  onClick={(e: any) =>
                                    deleteOrderText({ index: note?.index })
                                  }
                                >
                                  <TooltipPortal
                                    id={`note-${note?.index}`}
                                    content="Delete"
                                    className="text-center !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-[55] max-w-[9rem] cursor-default"
                                  />
                                  <IKTSVG
                                    className={`fill-violet-500 hover:fill-indigo-500 transition`}
                                    svgClassName="w-[.5rem] h-[.5rem]"
                                    path={CrossSVG}
                                  />
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {/* {orderNotes?.length <= 0 && (
                      <div className="text-sm font-medium text-center my-4 p-2 bg-white rounded ">
                        No note available
                      </div>
                    )} */}
                  </OrderDetailBox>

                  {isGodAdmin &&
                    (isAddressLock === false ||
                      isCustomerLock === false ||
                      isTicketLock === false ||
                      isShippingAddressLock === false ||
                      isStatusLock === false) && (
                      <div className="w-full p-2.5  ">
                        <div className="btns flex flex-wrap items-center justify-end tx-pay-module gap-3.5 bg-white py-3 px-3 border rounded">
                          <>
                            <button
                              type="button"
                              className="btn  text-sm13 font-medium py-1 px-2.5"
                              onClick={() => {
                                closeFullOrderDetail();
                              }}
                            >
                              Cancel
                            </button>

                            <button
                              // type="button"
                              type="submit"
                              className={` ${
                                updateLoader &&
                                "pointer-events-none !bg-gray-300"
                              } text-sm13 font-medium py-1 px-2.5 bg-green-600 hover:bg-indigo-500 !text-white rounded border-hidden	 hover:border-hidden	 flex`}
                              onClick={() => {}}
                            >
                              {updateLoader && (
                                <IKTSVG
                                  className="flex items-center justify-center fill-violet-500 transition animate-spin mr-2"
                                  path={processSVG}
                                />
                              )}
                              Update
                            </button>
                          </>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
            {/* <div className="relative p-4 opacity-0">...</div> */}
          </div>
        </div>
      </form>

      {progressLoader && <ProgressBardLoader secondCounter={2} />}
    </>
  );
};

export default FullOrderDetail;
