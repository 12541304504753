const CopyLink = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.742"
      height="13.492"
      viewBox="0 0 15.742 13.492"
      {...props}
    >
      <g
        id="Group_22556"
        data-name="Group 22556"
        transform="translate(0.977 0.977)"
      >
        <path
          id="Path_49180"
          data-name="Path 49180"
          d="M8.164,16.415l-.129.129a3,3,0,0,1-4.243,0l-.257-.257a3,3,0,0,1,0-4.243L6.043,9.536a3,3,0,0,1,4.243,0l.257.257A2.988,2.988,0,0,1,11.4,11.54"
          transform="translate(-2.657 -5.657)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_49181"
          data-name="Path 49181"
          d="M9.688,10.54a2.987,2.987,0,0,0,.855,1.746l.257.257a3,3,0,0,0,4.243,0l2.507-2.507a3,3,0,0,0,0-4.243l-.257-.257a3,3,0,0,0-4.243,0l-.129.129"
          transform="translate(-4.415 -4.657)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};

export default CopyLink;
