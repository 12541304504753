import { useContext } from "react";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import {
  addCommaInNumber,
  getCurrencySymbol,
} from "../../../helpers/Functions";
import { SalesContext } from "../core/SalesProvider";

const SalesCountBoxes = ({
  name,
  countValue,
  ticketCount,
  isCheckbox,
  extraProp = false,
  postKey,
  shimmerLoader,
  salesOptions,
  descriptionString = "tickets",
}: any) => {
  const layout = useContext(SalesContext);
  const coreLayout = useContext(LayoutContext);
  const isGodAdmin = coreLayout?.allowedAccess?.account_info?.is_god_admin;

  function isGodadminBasedCurrency(Array: any) {
    const teamArr = Array;
    if (teamArr === undefined || teamArr?.length === 0) {
      return coreLayout.currencySymbol;
    } else if (teamArr?.length > 1) {
      return coreLayout.currencySymbol;
    } else {
      return getCurrencySymbol(teamArr?.[0]?.base_currency);
    }
  }

  return (
    <>
      <div className="w-full sm:w-1/2 lg:w-1/3 xl:min-w-[16.66%] mb-4 px-2.5">
        <label
          htmlFor={postKey}
          className={`border form-check items-center rounded bg-white group block min-w-[9.875rem] ${
            layout.salesFilterHandle?.ticket_type?.[postKey] && "active-style"
          } [&.active-style]:bg-indigo-500/5 [&.active-style]:border-indigo-500  ${
            shimmerLoader && "shimmer-effect"
          } ${
            isCheckbox &&
            "hover:bg-indigo-500/5 hover:border-indigo-500 hover:text-indigo-500 cursor-pointer"
          } `}
        >
          <div className={` px-2.5 py-2 h-full  `}>
            <div className="flex justify-between items-center">
              <div>
                <h3
                  className={`md:text-lg md:leading-[1.3125rem] ${
                    layout.salesFilterHandle?.ticket_type?.[postKey] &&
                    "active-style"
                  } [&.active-style]:text-indigo-500`}
                >
                  {extraProp && isGodAdmin
                    ? isGodadminBasedCurrency(
                        layout?.salesFilterHandle?.team_id
                      )
                    : extraProp && !isGodAdmin && coreLayout.currencySymbol}

                  {countValue ? addCommaInNumber(countValue) : "0"}
                </h3>
              </div>
              {ticketCount ? (
                <h5 className="flex items-center">
                  <span
                    className={`flex items-center bg-gray-100 text-xxs font-semibold px-2 py-0.5 rounded ${
                      layout.salesFilterHandle?.ticket_type?.[postKey] &&
                      "active-style"
                    } [&.active-style]:text-indigo-500 [&.active-style]:bg-indigo-500/5 ml-1`}
                  >
                    {ticketCount ? addCommaInNumber(ticketCount) : "0"}{" "}
                    {descriptionString}
                  </span>
                </h5>
              ) : (
                ""
              )}
            </div>
            <div className="flex justify-between items-center pt-1.5">
              <p
                className={`text-xs text-gray-500 ${
                  isCheckbox && "group-hover:text-indigo-500"
                } ${
                  layout.salesFilterHandle?.ticket_type?.[postKey] &&
                  "active-style"
                } [&.active-style]:text-indigo-500 leading-[.9375rem]`}
              >
                {name ? name : "0"}
              </p>

              {isCheckbox && (
                <div className="flex">
                  <input
                    className="form-check-input h-3 w-3 rounded-sm bg-gray-100 border-gray-300 checked:bg-indigo-500 transition ml-2 cursor-pointer border checked:border-indigo-500 hover:border-indigo-500 group-hover:border-indigo-500 focus:ring-0 focus:ring-offset-0"
                    type="checkbox"
                    name={postKey}
                    id={postKey}
                    checked={
                      layout.salesFilterHandle?.ticket_type?.[postKey]
                        ? true
                        : false
                    }
                    // checked={
                    //   layout.salesFilterHandle?.ticket_type &&
                    //   layout.salesFilterHandle?.ticket_type?.some(
                    //     (obj: any) => obj.id === postKey
                    //   )
                    // }
                    onChange={(e: any) => {
                      // layout.setSalesFilterHandle((current: any) => {
                      //   // console.log(salesOptions?.ticket_type);
                      //   let final = {
                      //     ...current,
                      //     ticket_type: {
                      //       ...current.ticket_type,
                      //       [e.target.name]: e.target.checked,
                      //     },
                      //   };
                      //   let selectedKeys = Object.keys(
                      //     final?.ticket_type
                      //   ).filter((key) => final?.ticket_type[key]);

                      //   let filteredOptions = salesOptions?.ticket_type.filter(
                      //     (option: any) => selectedKeys.includes(option.id)
                      //   );
                      //   final = {
                      //     ...final,
                      //     ticket_type: filteredOptions,
                      //   };

                      //   return final;
                      // });
                      if (e.target?.checked) {
                        layout.setSalesFilterHandle((current: any) => {
                          return {
                            ...current,
                            ticket_type: {
                              ...current.ticket_type,
                              [e.target.name]: e.target.checked,
                            },
                            ticket_type_dropdown: [
                              ...(current?.ticket_type_dropdown &&
                              Array.isArray(current?.ticket_type_dropdown)
                                ? current?.ticket_type_dropdown
                                : []),
                              layout?.salesOptions?.ticket_type?.find(
                                (option: any) => {
                                  return option.id === postKey;
                                }
                              ),
                            ],
                          };
                        });
                      } else {
                        layout.setSalesFilterHandle((current: any) => {
                          return {
                            ...current,
                            ticket_type: {
                              ...current.ticket_type,
                              [e.target.name]: e.target.checked,
                            },
                            ticket_type_dropdown:
                              current?.ticket_type_dropdown?.filter(
                                (item: any) => item?.id != e.target.name
                              ),
                          };
                        });
                      }

                      layout.setIsSelectAll(false);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </label>
      </div>
    </>
  );
};

export default SalesCountBoxes;
