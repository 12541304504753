const Tab = ({ title, active, handleActive }: any) => {
  return (
    <div
      className={`${
        title && active
          ? "bg-opacity-100 z-10 after:bg-menuCorner after:bg-contain after:absolute after:left-full after:bottom-0 after:w-2 after:h-2 after:transition after:duration-200 after:brightness-0 after:invert after:-rotate-90 before:bg-menuCorner before:bg-contain before:absolute before:right-full before:bottom-0 before:w-2 before:h-2 before:transition before:duration-200 before:brightness-0 before:invert before:rotate-180"
          : "bg-opacity-60"
      } relative single-tab lg:px-4 px-2.5 py-[.5625rem] lg2:max-w-[T20.938rem] min-w-[10.625rem lg:whitespace-normal whitespace-nowrap flex justify-between items-center bg-white rounded-t cursor-pointer mr-[1px] w-full gap-2 hover:bg-opacity-100`}
      onClick={() => handleActive(title)}
    >
      <h4
        className={`lg:text-sm15 text-sm14 font-semibold ${
          title && active ? "" : ""
        }`}
      >
        {title}
      </h4>
    </div>
  );
};

export default Tab;
