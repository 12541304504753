import { IKTSVG } from "components/ui/IKTSVG";
import EDITSVG from "../../../../../../assets/media/icons/other_icons/edit-square.svg";

import { LayoutContext } from "components/core/LayoutProvider";
import { useContext } from "react";
import { Tooltip } from "react-tooltip";

const TableEditAction = ({
  sIndex,
  list,
  matchedView,
  handleOnEditClick = () => {},
  isTxtrade,
}: any) => {
  const globalLayout: any = useContext(LayoutContext);

  return (
    <>
      <td
        className={`md:sticky relative right-0 ml-auto p-0 icon_td md:w-[4rem] md:max-w-[4rem] md:min-w-[4rem] w-[4rem] max-w-[4rem] min-w-[4rem]`}
      >
        <div
          className={`${
            !isTxtrade ? "pointer-events-none" : ""
          } bg-opacity-[7%] ${matchedView && "bg-indigo-500 bg-opacity-[7%]"}`}
        >
          <div
            className="flex left-shad relative w-full h-full border-l"
            id="left-shad-list"
          >
            <div className="flex items-center justify-center p-1 md:p-1 w-8  md:flex-auto flex-1 max-md:hidden">
              <button
                className={`${
                  !isTxtrade ? "!bg-gray-500" : ""
                } group inline-flex group items-center justify-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 gap-2 p-1 w-7 h-7  `}
                type="button"
                id={`record-view-tooltip${sIndex}`}
                data-bs-toggle="offcanvas"
                data-bs-target="#level_confi_popup"
                aria-controls="level_confi_popup_label"
                onClick={() => {
                  handleOnEditClick(list);
                }}
              >
                <Tooltip
                  anchorId={`record-view-tooltip${sIndex}`}
                  content={`${"Edit"}`}
                  place={`${sIndex === 0 ? "left" : "top"}`}
                  variant="light"
                  className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
                    sIndex === 0 ? "mt-0" : "mt-1.5"
                  }`}
                />
                <IKTSVG
                  className={`flex items-center justify-center fill-white group-hover:fill-white transition  `}
                  svgClassName="w-4 h-[.6875rem]"
                  path={EDITSVG}
                />
              </button>
            </div>
          </div>
        </div>
      </td>
      {globalLayout?.isMobile && (
        <td
          className={`sticky right-0 ml-auto p-0 icon_td w-[2.5rem] max-w-[2.5rem] min-w-[2.5rem]`}
        >
          <div
            className={` bg-opacity-[7%] ${
              matchedView && "bg-indigo-500 bg-opacity-[7%]"
            }`}
          >
            <div
              className="flex left-shad relative w-full h-full border-l"
              id="left-shad-list"
            >
              <div className="flex items-center justify-center p-1 md:p-1 w-8  md:flex-auto flex-1">
                <button
                  className={` group inline-flex group items-center justify-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 gap-2 p-1 w-7 h-7  `}
                  type="button"
                  id={`record-view-tooltip${sIndex}`}
                  data-bs-toggle="offcanvas"
                  data-bs-target="#level_confi_popup"
                  aria-controls="level_confi_popup_label"
                  //   onClick={() => {
                  //     layout.setSellerLevelEditData(list);
                  //   }}
                >
                  <Tooltip
                    anchorId={`record-view-tooltip${sIndex}`}
                    content={`${"Edit"}`}
                    place={`${sIndex === 0 ? "left" : "top"}`}
                    variant="light"
                    className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
                      sIndex === 0 ? "mt-0" : "mt-1.5"
                    }`}
                  />
                  <IKTSVG
                    className={`flex items-center justify-center fill-white group-hover:fill-white transition`}
                    svgClassName="w-4 h-[.6875rem]"
                    path={EDITSVG}
                  />
                </button>
              </div>
            </div>
          </div>
        </td>
      )}
    </>
  );
};

export default TableEditAction;
