/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  getSellerLevelNumberWithColor,
  LoadImages,
} from "helpers/AssetHelpers";
import { upperCase } from "lodash";
import moment from "moment";
import {
  Fragment,
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SVG from "react-inlinesvg";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import AddListingsOutline from "../../../assets/media/icons/add-listings-outline.svg";
import DashboardOutline from "../../../assets/media/icons/dashboard-outline.svg";
import TixLogo from "../../../assets/media/icons/menu_icons/TixLogo";
import Plug1 from "../../../assets/media/icons/menu_icons/api-connector.svg";
import SellerLevelIcon from "../../../assets/media/icons/menu_icons/sellerLevelsIcon.svg";
import TXAccountSVG from "../../../assets/media/icons/menu_icons/txAccount1.svg";
import wallet from "../../../assets/media/icons/menu_icons/walletnBanking.svg";
import MyListingsOutline from "../../../assets/media/icons/my-listings-outline.svg";
import NotificationOutlineWithout from "../../../assets/media/icons/notification-outline-without.svg";
import NotificationOutline from "../../../assets/media/icons/notification-outline.svg";
import SubscribeIcon from "../../../assets/media/icons/other_icons/user1.svg";
import ReportsOutline from "../../../assets/media/icons/reports-outline.svg";
import SalesOutline from "../../../assets/media/icons/sales-outline.svg";
import salesWithoutOutline from "../../../assets/media/icons/sales-without-outline.svg";
import arrowDown from "../../../assets/media/icons/standard_icons/chevron-down.svg";
import TixStockLogoFull from "../../../assets/media/icons/tixstock-logo-light.svg";
import TxPayOutline from "../../../assets/media/icons/tx-pay-outline.svg";
import TxTradeOutline from "../../../assets/media/icons/tx-trade-outline.svg";

import { addCommaInNumber, decimalNumber } from "../../../helpers/Functions";
import {
  isMobile,
  LISTINGS_UTILS,
  QUERIES,
  SYMBOLS,
} from "../../../helpers/const";
import {
  getMarkAsPinned,
  getMarkAsUnpinned,
} from "../../../pages/notifications/core/requests";
import { LayoutContext } from "../../core/LayoutProvider";
import PusherSetup from "../../core/PusherSetup";
import { getNotificationListing } from "../../core/requests";
import { KTSVG } from "../../ui/KTSVG";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// Import Swiper React components

// Import Swiper styles
import { IKTSVG } from "components/ui/IKTSVG";

// import required modules
import { SALES_NEST_ROUTES } from "pages/Sales/core/const";

import {
  SUBSCRIBE_ROUTE,
  SUBSCRIBE_TAB,
} from "pages/subscribe/personal_details/core/const";
import { TX_PAY_TAB_ROUTES, TX_TAB } from "pages/tx_pay/core/const";
import AsideMenuItem from "./AsideMenuItem";
import { ShowSubmenu } from "./ShowSubmenu";
import { UserSellerInfo } from "./UserSellerInfo/UserSellerInfo";
import { getSellerTitle } from "./UserSellerInfo/utils";

// import required modules
const AsideDefault = ({ setUserInfo, userInfo }: any) => {
  const [menuopen, setmenuopen] = useState(false); // only for mobile
  const [showMenu, setShowMenu] = useState(false); // show all menu instead of load after another
  const layout = useContext(LayoutContext);
  let tabValue = layout.tabValue;
  const catMenu = useRef<any>(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const authUser = layout.authUser;
  const access = layout.allowedAccess?.allowed_access;
  const userSpecific = access?.some(
    (item: any) => item.id === "user-specific-data" && item.status === true
  );
  const globalLayout = useContext(LayoutContext);
  const { asideMenuOpen, setAsideMenuOpen } = globalLayout;

  useEffect(() => {
    if (!isMobile) {
      localStorage.setItem("sideMenuOpen", String(asideMenuOpen));
    }
  }, [asideMenuOpen]);

  const notificationStatuts =
    layout.allowedAccess?.account_info?.is_read_new_notifications;
  const isGodAdmin = layout.allowedAccess?.account_info?.is_god_admin;

  const closeOpenMenus = (e: any) => {
    if (e.target?.closest("button")?.id === "text-clear-btn") {
      return;
    }

    if (
      catMenu.current &&
      // asideMenuOpen &&
      !catMenu.current.contains(e.target)
    ) {
      if (isMobile) {
        setAsideMenuOpen(false);
      }
      setUserInfo(false);
      layout?.setNotificationId(undefined);
    }

    if (
      layout?.notificationId &&
      !catMenu?.current?.contains(e.target) &&
      isMobile
    ) {
      layout?.setNotificationId(undefined);
      setUserInfo(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);

    return () => {
      document.removeEventListener("mousedown", closeOpenMenus);
    };
  }, [isMobile]);

  const virtualCardCounts = useMemo(
    () =>
      addCommaInNumber(
        layout?.globalCount?.tx_pay_count?.virtual_cards_count ?? 0
      ),
    [layout?.globalCount?.tx_pay_count?.virtual_cards_count]
  );

  const walletCounts = useMemo(() => {
    const availableCounts =
      layout?.globalCount?.tx_pay_count?.available_amount ?? 0;

    return layout.currencyLabel && `${SYMBOLS[layout.currencyLabel]}${decimalNumber(availableCounts)}`;
  }, [
    layout?.globalCount?.tx_pay_count?.virtual_cards_count,
    layout.currencyLabel,
  ]);

  useEffect(() => {
    setTimeout(() => {
      setShowMenu(true);
    }, 500);
  }, []);

  // useEffect(() => {
  //   setOpen(false); // Close the navigation panel
  // }, [pathname]);

  // GET NOTIFICATIONS LISTING
  const {
    isLoading: notificationsLoading,
    data: listingData,
    refetch,
  } = useQuery(
    [
      `${QUERIES.NOTIFICATIONS_LISTING}`,
      tabValue,
      layout.newNotification,
      layout.notificationId,
    ],
    () =>
      getNotificationListing({
        tab_name: tabValue,
        team_member: [authUser?.id],
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled:
        layout.newNotification !== undefined ||
        layout.notificationId !== undefined,
      onError: (err) => {},
    }
  );

  useEffect(() => {
    if (listingData?.data?.activities) {
      layout?.setActivities(
        listingData?.data?.activities?.map((activity: any) => {
          return {
            ...activity,
          };
        })
      );
    }
  }, [listingData, tabValue, listingData?.data?.activities]);

  useEffect(() => {
    if (listingData?.data?.notifications) {
      layout?.setNotifications(
        listingData?.data?.notifications?.map((notification: any) => {
          return {
            ...notification,
          };
        })
      );
    }
  }, [listingData, tabValue]);

  const handleUnpin = (e: any, id: any) => {
    getMarkAsUnpinned([id])
      .then((response) => {
        if (response.data.status === true) {
          layout.setNotificationId(layout.notificationId + 1);
          // layout.setNotifications(layout.notifications.map((notification: any) => {
          //   if (notification.selected) {
          //     notification.selected = false
          //     return {
          //       ...notification,
          //       is_pinned: false
          //     }
          //   }
          //   else {
          //     return notification
          //   }
          // }))
        }
      })
      .catch((error) => {});
  };

  const handlePin = (e: any, id: any) => {
    getMarkAsPinned([id])
      .then((response) => {
        if (response.data.status === true) {
          layout.setNotificationId(id);
          layout.setNotifications(
            layout.notifications.map((notification: any) => {
              if (notification.selected) {
                notification.selected = false;
                return {
                  ...notification,
                  is_pinned: true,
                };
              } else {
                return notification;
              }
            })
          );
        }
      })
      .catch((error) => {});
  };

  // MEMOIZED ACCESS
  const memoizedAccess = useMemo(() => access, [access]);

  // NAVIGATE TO DASHBOARD MODULE
  const handleClick = () => {
    navigate("../", { replace: true });
  };

  const closeOpenMainMenu = (e: any) => {
    if (catMenu.current && menuopen && !catMenu.current.contains(e.target)) {
      setmenuopen(!menuopen);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMainMenu);
    return () => {
      document.removeEventListener("mousedown", closeOpenMainMenu);
    };
  }, [menuopen]);

  // Submenu  design fixed
  useEffect(() => {
    const menuItems = document.querySelectorAll(
      "[data-menu-id]"
    ) as NodeListOf<HTMLElement>;
    const menuUl = document.querySelector(".menu-ul") as HTMLElement;
    const MenuWrap = document.querySelector(
      ".notification_class"
    ) as HTMLElement;
    let currentSubmenu: HTMLElement | null = null;
    let activeMenuItem: HTMLElement | null = null;

    const updateSubmenuPosition = () => {
      if (currentSubmenu && activeMenuItem) {
        const { offsetTop } = activeMenuItem;
        const scrollTop = menuUl.scrollTop;
        const scrollLeft = MenuWrap.offsetWidth;

        currentSubmenu.style.top = `${offsetTop - scrollTop}px`;
        currentSubmenu.style.left = `${scrollLeft}px`;
      }
    };

    const handleClick = (event: Event) => {
      event.stopPropagation();
      const menuItem = event.currentTarget as HTMLElement;
      const menuId = menuItem.getAttribute("data-menu-id");

      if (currentSubmenu) {
        currentSubmenu.style.display = "none";
        if (currentSubmenu.getAttribute("data-submenu-id") === menuId) {
          currentSubmenu = null;
          activeMenuItem = null;
          return;
        }
      }

      if (menuId) {
        const submenuToShow = document.querySelector(
          `[data-submenu-id="${menuId}"]`
        ) as HTMLElement;
        if (submenuToShow) {
          const { offsetTop } = menuItem;
          const scrollTop = menuUl.scrollTop;
          const scrollLeft = MenuWrap.offsetWidth;

          submenuToShow.style.top = `${offsetTop - scrollTop}px`;
          submenuToShow.style.left = `${scrollLeft}px`;

          submenuToShow.style.display = "block";
          currentSubmenu = submenuToShow;
          activeMenuItem = menuItem;

          updateSubmenuPosition();
        }
      }
    };

    const handleBodyClick = () => {
      if (currentSubmenu) {
        currentSubmenu.style.display = "none";
        currentSubmenu = null;
        activeMenuItem = null;
      }
    };

    menuItems.forEach((menuItem) => {
      menuItem.addEventListener("click", handleClick);
    });

    if (menuUl) {
      menuUl.addEventListener("scroll", updateSubmenuPosition);
    }

    document.body.addEventListener("click", handleBodyClick);

    return () => {
      menuItems.forEach((menuItem) => {
        menuItem.removeEventListener("click", handleClick);
      });
      if (menuUl) {
        menuUl.removeEventListener("scroll", updateSubmenuPosition);
      }
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, [layout.allowedAccess?.allowed_access]);

  useEffect(() => {
    if (userInfo || layout.notificationId === 1) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [userInfo, layout.notificationId === 1]);

  return (
    <>
      <PusherSetup refetch={() => layout.setIsPusherCall(true)} />
      <div
        className={`flex fixed z-[99999999] notification_class md:pointer-events-auto pointer-events-none ${
          asideMenuOpen && "menuActive"
        } ${
          !(layout?.notificationId || userInfo) &&
          "after:bg-menuCorner after:bg-cover after:absolute after:left-full after:top-0 after:w-4 after:h-4 after:transition after:duration-200 before:bg-menuCorner before:bg-cover before:absolute before:left-full before:bottom-0 before:w-4 before:h-4 before:transition before:duration-200 before:-scale-y-100"
        } ${menuopen ? "before:block" : "before:hidden md:before:block"}`}
        ref={catMenu}
      >
        <div
          className={`${
            asideMenuOpen
              ? "w-[11.6875rem] md:w-[13.875rem] open-menu"
              : "w-[3.75rem] close-menu overflow-x-hidden"
          } flex flex-col menu-wrap h-dvh pt-1.5 relative duration-200 bg-violet-600 overflow-x-auto scrollbar-none scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full`}
        >
          <div
            className="flex md:hidden gap-x-4 items-center cursor-pointer logo pointer-events-auto px-2.5"
            // onClick={handleClick}
            onClick={() => {
              setAsideMenuOpen(false);
              setmenuopen(menuopen ? false : true);
              layout.setNotificationId(undefined);
              setUserInfo(false);
            }}
          >
            <TixLogo className={`cursor-pointer duration-500`} />
          </div>
          <div
            className="md:flex gap-x-4 items-center justify-center cursor-pointer logo hidden p-2.5 pl-[.9375rem]"
            onClick={handleClick}
          >
            <div className="relative w-full min-h-7 h-7">
              <SVG
                src={TixStockLogoFull}
                className={`h-7 min-h-7 absolute left-0 top-0 duration-0 w-[11.875rem] ${
                  asideMenuOpen ? "opacity-100" : "opacity-0"
                } `}
              />
              <TixLogo
                className={`cursor-pointer h-7 w-[1.9rem] absolute duration-0 left-0 top-0 ${
                  asideMenuOpen ? "opacity-0" : "opacity-100"
                }`}
              />
            </div>
          </div>

          <ul
            className={`menu-ul pt-1 ${
              menuopen ? "active-menu" : ""
            } pointer-events-auto ${
              !showMenu ? `hidden` : ``
            } bg-violet-600 px-2.5 overflow-auto scrollbar-none scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full !pb-16 md:pb-2.5`}
          >
            <>
              <div
                className="group user_logo  flex items-center relative mb-2.5 w-full gap-x-2.5"
                data-tip
                id="par_id"
                onClick={() => {
                  setUserInfo(!userInfo);
                  layout.setNotificationId(undefined);
                  if (isMobile) {
                    setAsideMenuOpen(false);
                  }
                }}
              >
                {asideMenuOpen ? (
                  <div
                    className={`flex flex-row justify-between items-center w-full h-11 hover:bg-violet-400 px-2.5 !transition-none rounded cursor-pointer ${
                      userInfo && "bg-violet-400"
                    }`}
                  >
                    <div className="flex flex-col gap-y-[0.1875rem]">
                      <span
                        className={`text-sm13 origin-left capitalize text-white font-medium leading-[1.0625rem] text-truncate transition-none`}
                      >
                        {/* Scott Welch */}
                        {layout.allowedAccess?.account_info?.firstname}{" "}
                        {layout.allowedAccess?.account_info?.surname}
                      </span>
                      {layout.isTxTradeUser !== 1 && (
                        <p
                          className={`text-xxs  ${
                            getSellerLevelNumberWithColor(
                              layout.allowedAccess?.team_data?.seller_level
                            ) === "S"
                              ? "text-yellow-100"
                              : "text-green-500"
                          } leading-[0.875rem] ${
                            userInfo && "!text-white"
                          } text-truncate transition-none`}
                        >
                          {getSellerTitle(
                            layout.allowedAccess?.team_data?.seller_level?.title
                          )}
                        </p>
                      )}
                    </div>
                    <div>
                      {/* <SVG src={RightArrow} className="h-[0.5625rem] w-[0.2813rem]" /> */}
                      <IKTSVG
                        className={`stroke-white `}
                        path={arrowDown}
                        svgClassName={`w-[.7813rem] h-[.6563rem] -rotate-90 ${
                          userInfo && "rotate-90"
                        }`}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="relative !transition-none">
                    {layout.isTxTradeUser !== 1 && (
                      <div
                        className={`${
                          getSellerLevelNumberWithColor(
                            layout.allowedAccess?.team_data?.seller_level
                          ) === "S"
                            ? "bg-sellerNotificationYellow"
                            : "bg-sellerNotification"
                        } absolute top-0 right-[0.1875rem] w-[0.8125rem] h-[0.9131rem] flex items-center justify-center text-violet-800 bg-cover font-medium text-xxs leading-[0.875rem]   `}
                      >
                        {/* {layout.allowedAccess?.team_data?.seller_level?.id}
                         */}
                        {getSellerLevelNumberWithColor(
                          layout.allowedAccess?.team_data?.seller_level
                        )}
                      </div>
                    )}
                    <div className="w-10 h-11 flex items-center justify-center text-white bg-avatarShape bg-cover text-sm15 font-semibold min-w-[2.5rem] cursor-pointer">
                      {upperCase(
                        layout.allowedAccess?.account_info?.firstname?.charAt(0)
                      )}
                      {upperCase(
                        layout.allowedAccess?.account_info?.surname?.charAt(0)
                      )}
                    </div>
                  </div>
                )}
              </div>
              {layout.allowedAccess?.account_info && (
                <Tooltip
                  anchorId={"par_id"}
                  html={`<div><h2 class="name">${`${layout.allowedAccess?.account_info?.firstname} ${layout.allowedAccess?.account_info?.surname}`}</h2> 
                  <!-- <span class="type">Accounts</span> -->
                  </div>`}
                  place="right"
                  variant="light"
                  className="   !bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-1000 -ml-1.5 no-angle z-[1] max-lg:hidden"
                  positionStrategy="fixed"
                />
              )}

              {/* Minimise */}
              <li
                id={"menuTooltip"}
                className="flex rounded p-2 mb-[0.0625rem] cursor-pointer overflow-hidden hover:bg-light-white text-gray-300 text-sm15 items-center gap-x-3 hover:bg-violet-400/50 transition"
                onClick={() => {
                  setAsideMenuOpen((prev: any) => !prev);
                  if (isMobile) {
                    setUserInfo(false);
                    layout.setNotificationId(undefined);
                  }
                }}
              >
                <img
                  src={LoadImages(`menu_icons/minimize-outline.svg`)}
                  alt=""
                  className={`w-6 h-6 ${
                    asideMenuOpen === false ? "scale-x-[-1]" : ""
                  }`}
                />
                <span
                  className={`${
                    !asideMenuOpen ? "invisible" : ""
                  } origin-left duration-200 text-white font-semibold text-sm13 leading-[1.0625rem]`}
                >
                  {asideMenuOpen ? (
                    "Minimise"
                  ) : (
                    <span className="invisible">&nbsp</span>
                  )}
                </span>
                {!asideMenuOpen && (
                  <Tooltip
                    anchorId={"menuTooltip"}
                    content={"Expand"}
                    place="right"
                    variant="light"
                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-1000 -ml-1.5 no-angle z-[1] max-lg:hidden"
                    positionStrategy="fixed"
                  />
                )}
              </li>
              {/* Dashboard */}
              <Suspense fallback={<div>Loading...</div>}>
                <AsideMenuItem
                  state={asideMenuOpen}
                  title="Dashboard"
                  svgName={DashboardOutline}
                  path={layout.isTxTradeUser === 1 ? "/dashboard" : "/"}
                  width={"w-[1.5rem]"}
                  height={"h-[1.5rem]"}
                  sellerInfoOpen={userInfo}
                />
              </Suspense>
              {/* Notifications */}
              <Suspense fallback={<div>Loading...</div>}>
                <AsideMenuItem
                  state={asideMenuOpen}
                  title="Notifications"
                  svgName={
                    notificationStatuts
                      ? NotificationOutlineWithout
                      : NotificationOutline
                  }
                  path={"#"}
                  width={"w-[1.5rem]"}
                  height={"h-[1.5rem]"}
                  setUserInfo={setUserInfo}
                  separator={true}
                  setAsideMenuOpen={setAsideMenuOpen}
                  sellerInfoOpen={userInfo}
                />
              </Suspense>
              {/* Add Inventory */}

              {!isGodAdmin && (
                <Suspense fallback={<div>Loading...</div>}>
                  {memoizedAccess &&
                    memoizedAccess?.find(
                      (item: any) => item.id === "inventory" && item.status
                    ) && (
                      <Fragment key={`inventory`}>
                        <AsideMenuItem
                          state={asideMenuOpen}
                          title={LISTINGS_UTILS?.addListings?.name}
                          svgName={AddListingsOutline}
                          path={LISTINGS_UTILS?.addListings?.url}
                          width={"w-[1.5rem]"}
                          height={"h-[1.5rem]"}
                          sellerInfoOpen={userInfo}
                        />
                      </Fragment>
                    )}
                </Suspense>
              )}

              {/* Inventory */}
              <Suspense fallback={<div>Loading...</div>}>
                {memoizedAccess &&
                  memoizedAccess?.find(
                    (item: any) => item.id === "inventory" && item.status
                  ) && (
                    <Fragment key={`inventory`}>
                      <AsideMenuItem
                        state={asideMenuOpen}
                        title={LISTINGS_UTILS?.myListings.name}
                        svgName={MyListingsOutline}
                        path={LISTINGS_UTILS?.myListings.url}
                        width={"w-[1.5rem]"}
                        height={"h-[1.5rem]"}
                        sellerInfoOpen={userInfo}
                      />
                    </Fragment>
                  )}
              </Suspense>

              {/* Sales */}
              <Suspense fallback={<div>Loading...</div>}>
                {memoizedAccess &&
                  memoizedAccess?.find(
                    (item: any) => item.id === "shipping" && item.status
                  ) && (
                    <Fragment key={`shipping`}>
                      {isGodAdmin &&
                      memoizedAccess?.find(
                        (item: any) =>
                          item.id === "instruction_letters" && item.status
                      ) ? (
                        <AsideMenuItem
                          setOpen={setAsideMenuOpen}
                          state={asideMenuOpen}
                          title="Sales"
                          svgName={
                            layout?.globalCount?.sales_count?.new_sales_count >
                            0
                              ? salesWithoutOutline
                              : SalesOutline
                          }
                          // svgName={SalesOutlineWithout}
                          path={`/sales/${SALES_NEST_ROUTES.PENDING.path}`}
                          showSubmenu={[
                            {
                              name: "Pending",
                              path: `/sales/${SALES_NEST_ROUTES.PENDING.path}`,
                              count: {
                                value: layout?.globalCount?.sales_count
                                  ?.orders_count
                                  ? addCommaInNumber(
                                      layout?.globalCount?.sales_count
                                        ?.orders_count
                                    )
                                  : 0,
                                show: true,
                              },
                            },
                            {
                              name: "Awaiting Delivery",
                              path: `/sales/${SALES_NEST_ROUTES.AWAITING_DELIVERY.path}`,
                              count: {
                                value: layout?.globalCount?.sales_count
                                  ?.to_be_fulfilled_tab
                                  ? addCommaInNumber(
                                      layout?.globalCount?.sales_count
                                        ?.to_be_fulfilled_tab
                                    )
                                  : 0,
                                show: true,
                              },
                            },
                            ...(isGodAdmin
                              ? [
                                  {
                                    name: "Open Orders",
                                    path: `/sales/${SALES_NEST_ROUTES.OUT_TO_OFFER.path}`,
                                    count: {
                                      value: layout?.globalCount?.sales_count
                                        ?.offer_order_tab
                                        ? addCommaInNumber(
                                            layout?.globalCount?.sales_count
                                              ?.offer_order_tab
                                          )
                                        : 0,
                                      show: true,
                                    },
                                  },
                                ]
                              : []),
                            {
                              name: "Delivered",
                              path: `/sales/${SALES_NEST_ROUTES.DELIVERED.path}`,
                              count: {
                                value: layout?.globalCount?.sales_count
                                  ?.fulfilled_tab
                                  ? addCommaInNumber(
                                      layout?.globalCount?.sales_count
                                        ?.fulfilled_tab
                                    )
                                  : 0,
                                show: true,
                              },
                            },
                            {
                              name: "Completed",
                              path: `/sales/${SALES_NEST_ROUTES.COMPLETED.path}`,
                              count: {
                                value: layout?.globalCount?.sales_count
                                  ?.completed_tab
                                  ? addCommaInNumber(
                                      layout?.globalCount?.sales_count
                                        ?.completed_tab
                                    )
                                  : 0,
                                show: true,
                              },
                            },
                            {
                              name: "Cancelled",
                              path: `/sales/${SALES_NEST_ROUTES.CANCELLED.path}`,
                              count: {
                                value: layout?.globalCount?.sales_count
                                  ?.cancelled_tab
                                  ? addCommaInNumber(
                                      layout?.globalCount?.sales_count
                                        ?.cancelled_tab
                                    )
                                  : 0,
                                show: true,
                              },
                            },
                          ]}
                          width={"w-[1.5rem]"}
                          height={"h-[1.5rem]"}
                          sellerInfoOpen={userInfo}
                        />
                      ) : (
                        <AsideMenuItem
                          setOpen={setAsideMenuOpen}
                          state={asideMenuOpen}
                          title="Sales"
                          svgName={
                            layout?.globalCount?.sales_count?.new_sales_count >
                            0
                              ? SalesOutline
                              : salesWithoutOutline
                          }
                          path={`/sales/${SALES_NEST_ROUTES.PENDING.path}`}
                          showSubmenu={[
                            {
                              name: "Pending",
                              path: `/sales/${SALES_NEST_ROUTES.PENDING.path}`,
                              count: {
                                value: layout?.globalCount?.sales_count
                                  ?.orders_count
                                  ? addCommaInNumber(
                                      layout?.globalCount?.sales_count
                                        ?.orders_count
                                    )
                                  : 0,
                                show: true,
                              },
                            },
                            {
                              name: "Awaiting Delivery",
                              path: `/sales/${SALES_NEST_ROUTES.AWAITING_DELIVERY.path}`,
                              count: {
                                value: layout?.globalCount?.sales_count
                                  ?.to_be_fulfilled_tab
                                  ? addCommaInNumber(
                                      layout?.globalCount?.sales_count
                                        ?.to_be_fulfilled_tab
                                    )
                                  : 0,
                                show: true,
                              },
                            },
                            ...(isGodAdmin
                              ? [
                                  {
                                    name: "Open Orders",
                                    path: `/sales/${SALES_NEST_ROUTES.OUT_TO_OFFER.path}`,
                                    count: {
                                      value: layout?.globalCount?.sales_count
                                        ?.out_to_offer_count
                                        ? addCommaInNumber(
                                            layout?.globalCount?.sales_count
                                              ?.out_to_offer_count
                                          )
                                        : 0,
                                      show: true,
                                    },
                                  },
                                ]
                              : []),
                            {
                              name: "Delivered",
                              path: `/sales/${SALES_NEST_ROUTES.DELIVERED.path}`,
                              count: {
                                value: layout?.globalCount?.sales_count
                                  ?.fulfilled_tab
                                  ? addCommaInNumber(
                                      layout?.globalCount?.sales_count
                                        ?.fulfilled_tab
                                    )
                                  : 0,
                                show: true,
                              },
                            },
                            {
                              name: "Completed",
                              path: `/sales/${SALES_NEST_ROUTES.COMPLETED.path}`,
                              count: {
                                value: layout?.globalCount?.sales_count
                                  ?.completed_tab
                                  ? addCommaInNumber(
                                      layout?.globalCount?.sales_count
                                        ?.completed_tab
                                    )
                                  : 0,
                                show: true,
                              },
                            },
                            {
                              name: "Cancelled",
                              path: `/sales/${SALES_NEST_ROUTES.CANCELLED.path}`,
                              count: {
                                value: layout?.globalCount?.sales_count
                                  ?.cancelled_tab
                                  ? addCommaInNumber(
                                      layout?.globalCount?.sales_count
                                        ?.cancelled_tab
                                    )
                                  : 0,
                                show: true,
                              },
                            },
                          ]}
                          width={"w-[1.5rem]"}
                          height={"h-[1.5rem]"}
                          sellerInfoOpen={userInfo}
                        />
                      )}
                    </Fragment>
                  )}
              </Suspense>

              {/* Reports */}
              <Suspense fallback={<div>Loading...</div>}>
                {memoizedAccess &&
                  memoizedAccess?.find(
                    (item: any) => item.id === "reports" && item.status
                  ) && (
                    <Fragment key="reports">
                      <AsideMenuItem
                        setOpen={setAsideMenuOpen}
                        state={asideMenuOpen}
                        title="Reports"
                        svgName={ReportsOutline}
                        path="/reports"
                        width={"w-[1.5rem]"}
                        height={"h-[1.5rem]"}
                        separator={true}
                        sellerInfoOpen={userInfo}
                      />
                    </Fragment>
                  )}
              </Suspense>

              {/* TX Pay */}
              <Suspense fallback={<div>Loading...</div>}>
                {memoizedAccess &&
                  (memoizedAccess?.find(
                    (item: any) => item.id === "txpay" && item.status
                  ) || memoizedAccess?.find(
                    (item: any) => item.id === "virtual-cards" && item.status
                  )) && (
                    <AsideMenuItem
                      state={asideMenuOpen}
                      setOpen={setAsideMenuOpen}
                      title="TX Pay"
                      svgName={TxPayOutline}
                      path={`/tx-pay${TX_PAY_TAB_ROUTES.wallet}`}
                      width={"w-[1.5rem]"}
                      height={"h-[1.5rem]"}
                      showSubmenu={[
                        ...(memoizedAccess && memoizedAccess?.find(
                          (item: any) => item.id === "txpay" && item.status)
                          ? [
                              {
                                name: TX_TAB.wallet,
                                path: `/tx-pay${TX_PAY_TAB_ROUTES.wallet}`,
                                count: {
                                  value: walletCounts,
                                  show: true, // Enable this object
                                },
                              },
                            ]
                          : []),
                      
                        ...(memoizedAccess && memoizedAccess?.find(
                          (item: any) => item.id === "virtual-cards" && item.status)
                          ? [
                              {
                                name: TX_TAB.virtualCard,
                                path: `/tx-pay${TX_PAY_TAB_ROUTES.virtualCard}`,
                                count: {
                                  value: virtualCardCounts,
                                  show: !isGodAdmin, // Enable based on isGodAdmin
                                },
                              },
                            ]
                          : [])]}
                      sellerInfoOpen={userInfo}
                    />
                  )}
              </Suspense>

              {/* TX-TRADE */}
              <Suspense fallback={<div>Loading...</div>}>
                {memoizedAccess &&
                  memoizedAccess?.find(
                    (item: any) => item.id === "txtrade" && item.status
                  ) && (
                    <AsideMenuItem
                      setOpen={setAsideMenuOpen}
                      state={asideMenuOpen}
                      title="TX Trade"
                      svgName={TxTradeOutline}
                      path={layout.isTxTradeUser === 1 ? "/" : "/tx-trade"}
                      width={"w-[1.5rem]"}
                      height={"h-[1.5rem]"}
                      separator={isGodAdmin && true}
                      showSubmenu={
                        memoizedAccess &&
                        memoizedAccess?.find(
                          (item: any) => item.id === "txtrade" && item.status
                        ) &&
                        isGodAdmin &&
                        layout?.isTxTradeCustomize
                          ? [
                              {
                                name: "TX Trade",
                                path: "/tx-trade",
                                count: {
                                  value: undefined,
                                  show: false,
                                },
                              },
                              {
                                name: "Customise",
                                path: "/tx-trade-customise",
                                count: {
                                  value: undefined,
                                  show: false,
                                },
                              },
                            ]
                          : undefined
                      }
                      sellerInfoOpen={userInfo}
                    />
                  )}
              </Suspense>

              {/* API Connector */}
              <Suspense fallback={<div>Loading...</div>}>
                {memoizedAccess &&
                  memoizedAccess?.find(
                    (item: any) => item.id === "api-connector" && item.status
                  ) && (
                    <Fragment key="API-connector">
                      <AsideMenuItem
                        state={asideMenuOpen}
                        setOpen={setAsideMenuOpen}
                        title="API Connector"
                        svgName={Plug1}
                        path="/API-connector"
                        width={"w-[1.5rem]"}
                        height={"h-[1.5rem]"}
                        sellerInfoOpen={userInfo}
                      />
                    </Fragment>
                  )}
              </Suspense>

              {/* <Suspense fallback={<div>Loading...</div>}>
                {memoizedAccess &&
                  memoizedAccess?.find(
                    (item: any) => item.id === "settings" && item.status
                  ) && (
                    <AsideMenuItem
                      state={open}
                      title="Settings"
                      svgName={SettingSVG}
                      path={["/settings", "/instruction-letter"]}
                      width={"w-[1.375rem]"}
                      height={"h-[1.125rem]"}
                    />
                  )}
              </Suspense> */}
              {/* <Suspense fallback={<div>Loading...</div>}>
                <AsideMenuItem
                  state={open}
                  title="FAQ"
                  svgName={faqSVG}
                  path={"https://help.tixstock.com"}
                  width={"w-[1.375rem]"}
                  height={"h-[1.3125rem]"}
                  showSubmenu={[
                    {
                      name: "Help center",
                      path: "https://help.tixstock.com",
                      externalLink: true,
                      count: {
                        value: undefined,
                        show: false,
                      },
                    },
                    {
                      name: "TX Trade policy",
                      path: "/faq/tx-trade-policy",
                      count: {
                        value: undefined,
                        show: false,
                      },
                    },
                  ]}
                />
              </Suspense> */}
              <Suspense fallback={<div>Loading...</div>}>
                {memoizedAccess &&
                  memoizedAccess?.find(
                    (item: any) => item.id === "subscribers" && item.status
                  ) && (
                    <AsideMenuItem
                      setOpen={setAsideMenuOpen}
                      state={asideMenuOpen}
                      title="Subscribers"
                      svgName={SubscribeIcon}
                      path={`/subscribe${SUBSCRIBE_ROUTE.subscribeDetails}`}
                      showSubmenu={[
                        {
                          name: SUBSCRIBE_TAB.subscribeDetails,
                          path: `/subscribe${SUBSCRIBE_ROUTE.subscribeDetails}`,
                        },
                        {
                          name: SUBSCRIBE_TAB.referral,
                          path: `/subscribe${SUBSCRIBE_ROUTE.referral}`,
                        },
                      ]}
                      width={"w-6"}
                      height={"h-6"}
                      sellerInfoOpen={userInfo}
                    />
                  )}
              </Suspense>

              <Suspense fallback={<div>Loading...</div>}>
                {memoizedAccess &&
                  memoizedAccess?.find(
                    (item: any) => item.id === "seller-levels" && item.status
                  ) && (
                    <AsideMenuItem
                      setOpen={setAsideMenuOpen}
                      state={asideMenuOpen}
                      title="Seller Levels"
                      svgName={SellerLevelIcon}
                      path={"/seller-levels"}
                      showSubmenu={[
                        {
                          name: "Configuration",
                          path: "/seller-levels/configuration",
                          count: {
                            value: undefined,
                            show: false,
                          },
                        },
                        {
                          name: "Movement",
                          path: "/seller-levels/movement",
                          count: {
                            value: undefined,
                            show: false,
                          },
                        },
                      ]}
                      width={"w-6"}
                      height={"h-6"}
                      sellerInfoOpen={userInfo}
                    />
                  )}
              </Suspense>

              {/* Wallets and Banking */}
              <Suspense fallback={<div>Loading...</div>}>
                {memoizedAccess &&
                  memoizedAccess?.find(
                    (item: any) => item.id === "subscribers" && item.status
                  ) && (
                    <Fragment key="Wallets & Banking">
                      <AsideMenuItem
                        setOpen={setAsideMenuOpen}
                        state={asideMenuOpen}
                        title="Wallets & Banking"
                        svgName={wallet}
                        path="/wallet-&-bank-details"
                        width={"w-[1.5rem]"}
                        height={"h-[1.5rem]"}
                        sellerInfoOpen={userInfo}
                      />
                    </Fragment>
                  )}
              </Suspense>

              {/* TX Accounts */}
              {isGodAdmin &&
                memoizedAccess &&
                memoizedAccess?.find(
                  (item: any) => item.id === "txaccounts" && item.status
                ) && (
                  <Suspense fallback={<div>Loading...</div>}>
                    <AsideMenuItem
                      state={asideMenuOpen}
                      setOpen={setAsideMenuOpen}
                      title="TX Accounts"
                      svgName={TXAccountSVG}
                      path="/tx-account"
                      width={"w-[1.5rem]"}
                      height={"h-[1.5rem]"}
                      sellerInfoOpen={userInfo}
                    />
                  </Suspense>
                )}
            </>
          </ul>

          {/** Sub menu */}
          <div className="submenu-main">
            <ShowSubmenu
              title="FAQ"
              showSubmenu={[
                {
                  name: "Help center",
                  path: "https://help.tixstock.com",
                  externalLink: true,
                  count: {
                    value: undefined,
                    show: false,
                  },
                },
                {
                  name: "TX Trade policy",
                  path: "/faq/tx-trade-policy",
                  count: {
                    value: undefined,
                    show: false,
                  },
                },
              ]}
            />

            <ShowSubmenu
              title="Subscribers"
              showSubmenu={[
                {
                  name: "Personal/company details",
                  path: "/personal-details",
                  count: {
                    value: undefined,
                    show: false,
                  },
                },
                // {
                //   name: "Wallets & Bank accounts",
                //   path: "/wallet-&-bank-details",
                //   count: {
                //     value: undefined,
                //     show: false,
                //   },
                // },
                {
                  name: "Seller levels",
                  path: "/seller-levels",
                  count: {
                    value: undefined,
                    show: false,
                  },
                },
              ]}
            />
          </div>
          {/* Help Chat  */}
          {/* <div
            className={`absolute bottom-0 ${
              menuopen ? "left-0" : "-left-[3.75rem]"
            }  w-full md:static help-chat-module bg-violet-600 mt-auto p-2.5 z-50 transition-all duration-200`}
          >
            <div className="relative flex items-center p-2 bg-green-600 text-sm13 font-medium text-white rounded">
              <IKTSVG
                path={HelpIcon}
                className="w-6 min-w-6 h-6 flex items-center justify-center mr-2"
                svgClassName="w-6 h-6"
              />
              {open && <span>Help</span>}
              <span className="absolute right-[calc(100%_-_2.875rem)] -top-2 min-w-4 h-4 p-0.5 leading-none text-center text-xxs rounded-full bg-rose-500 border-[.0938rem] border-violet-600">
                2
              </span>
            </div>
          </div> */}
        </div>

        <div
          className={`fixed ${
            userInfo
              ? `${
                  asideMenuOpen
                    ? "md:left-[13.875rem] left-[11.6875rem] max-md:w-[calc(100vw-11.6875rem)] overflow-auto"
                    : "left-[3.75rem] max-md:w-[calc(100vw-3.75rem)]"
                }`
              : "-left-full "
          }  transition-all duration-200 -z-10`}
        >
          <UserSellerInfo isMenuOpen={asideMenuOpen} />
        </div>
        <div
          className={`fixed ${
            layout?.notificationId
              ? `${
                  asideMenuOpen
                    ? "md:left-[13.875rem] left-[11.6875rem] max-md:w-[calc(100vw-11.6875rem)] overflow-auto"
                    : "left-[3.75rem] max-md:w-[calc(100vw-3.75rem)]"
                }`
              : "-left-full "
          }  transition-all duration-200 -z-10`}
        >
          <div className="absolute arrow top-0 left-0 w-5 h-5 bg-violet-600"></div>
          <div className="absolute arrow bottom-0 left-0 w-5 h-5 bg-violet-600"></div>
          <div className="md:w-[20.8125rem] w-[19.625rem] h-dvh flex flex-col relative duration-400 bg-white border-l pointer-events-auto rounded-l-2xl">
            <div className="">
              <div className="flex items-start justify-between p-5 border-b border-gray-200">
                <h2 className="text-sm18 font-semibold">Notifications</h2>
                <button
                  className={`text-sm12 font-medium leading-[0.9375rem] px-2 py-1 text-white bg-violet-500 hover:bg-indigo-500 rounded`}
                  onClick={() => {
                    navigate("../notifications", { replace: true });
                    layout?.setNotificationId(undefined);
                    let logo: any = document.querySelector(".logo");
                    logo && logo.click();
                  }}
                >
                  View all
                </button>
              </div>
              {/* <div className="flex px-5 py-5 border-b border-white/10">
                <button
                  type="button"
                  className="flex items-center text-sm13 font-medium hover:underline text-white"
                  onClick={() => {
                    navigate("../notifications", { replace: true });
                    layout?.setNotificationId(undefined);
                    let logo: any = document.querySelector(".logo");
                    logo && logo.click();
                  }}
                >
                  <SVG className="w-3.5 mr-2.5" src={Alerts} />
                  View all
                </button>
              </div> */}
              <div className="p-5 pb-3 border-b border-gray-200">
                <button
                  className={`w-full py-3 px-[0.9375rem] text-xs font-medium border  rounded transition flex items-center justify-between mb-2.5 hover:border-indigo-500 hover:bg-white ${
                    layout?.tabValue === "notification"
                      ? "border-indigo-500"
                      : "bg-green-600/[0.07] border-green-600/20"
                  }`}
                  onClick={() => layout.setTabValue("notification")}
                >
                  {listingData?.data?.new_notification > 1
                    ? "Notifications"
                    : "Notification"}
                  <span className="text-sm15 font-medium leading-[1.1875rem]">
                    {listingData?.data?.new_notification
                      ? addCommaInNumber(listingData?.data?.new_notification)
                      : ""}
                  </span>
                </button>
                <button
                  className={`w-full flex items-center justify-between py-3 px-[0.9375rem] text-xs font-medium  border border-gray-200 rounded transition mb-2 hover:border-indigo-500 hover:bg-white ${
                    layout?.tabValue === "activity"
                      ? "border-indigo-500"
                      : "border-gray-200"
                  }`}
                  onClick={() => layout.setTabValue("activity")}
                >
                  {layout?.tabValue === "activity" &&
                  listingData?.data?.new_activities > 1
                    ? "Activity log"
                    : "Activity log"}
                  <span className="text-sm15 font-medium leading-[1.1875rem]">
                    {listingData?.data?.new_activities
                      ? addCommaInNumber(listingData?.data?.new_activities)
                      : ""}
                  </span>
                </button>
                {/* {listingData?.data?.new_notification > 0 ? (
                  <span className="notification-count text-sm12 font-medium leading-[0.9375rem] text-gray-500">
                    {` You have ${
                      listingData?.data?.new_activities +
                      listingData?.data?.new_notification
                    } notifications`}
                  </span>
                ) : null} */}
              </div>
            </div>

            {notificationsLoading ? (
              <div className="text-center py-10 opacity-40">
                {/* Loading... */}
                <KTSVG
                  className=" flex items-center justify-center"
                  svgClassName="w-4 h-4 animate-spin"
                  path="other_icons/white_process_fill.svg"
                />
              </div>
            ) : (
              <div className="notification-list overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
                <ul>
                  {layout?.tabValue === "notification" ||
                  listingData?.data?.notifications?.length > 0 ? (
                    listingData?.data?.notifications.map(
                      (notification: any, index: any) => (
                        <li
                          className={`pt-2 px-5 transition hover:bg-violet-100/10 ${
                            notification.is_pinned ? "pinned" : ""
                          } ${!notification.is_pinned ? "unpinned" : ""}`}
                        >
                          {notification.is_pinned ? (
                            <>
                              <div className="pb-2 border-b border-sky-blue-500 text-xxs font-medium cursor-pointer inner-pin ">
                                {/* <Tooltip
                                  anchorId={`record-unpin-tooltip${index}`}
                                  content="Mark as Unpinned"
                                  place="top"
                                  variant="light"
                                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                                /> */}
                                <div
                                  className="text-sky-blue-500 mb-1 group"
                                  id={`record-unpin-tooltip${index}`}
                                  onClick={(e: any) =>
                                    handleUnpin(e, notification.id)
                                  }
                                >
                                  <KTSVG
                                    className="inline-flex fill-sky-blue-500 text-center mr-1"
                                    svgClassName="w-[.4375rem] h-2.5"
                                    path="standard_icons/pin-icon.svg"
                                  />
                                  <span className="px-1 group-hover:hidden">
                                    Pinned
                                  </span>
                                  <span className="px-1 hidden group-hover:inline-block">
                                    Unpin
                                  </span>
                                  <span className="text-gray-400">
                                    {moment(notification.created_at).format(
                                      "DD MMM"
                                    )}
                                  </span>
                                </div>
                                <div
                                  className="text-gray-600"
                                  dangerouslySetInnerHTML={{
                                    __html: notification.text,
                                  }}
                                ></div>
                              </div>
                            </>
                          ) : notification.is_new ? (
                            <>
                              <div
                                className={`pb-2 border-b text-xxs font-medium cursor-pointer inner-pin ${
                                  notification.is_pinned && "border-sky-500"
                                }`}
                              >
                                <Tooltip
                                  anchorId={`record-pin-tooltip${index}`}
                                  content="Mark as Pinned"
                                  place="top"
                                  variant="light"
                                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                                />
                                <div className="group">
                                  <div
                                    className="group-hover:opacity-100 group-hover:visible group-hover:inline-flex opacity-0 invisible hidden overflow-hidden items-center mb-1"
                                    id={`record-pin-tooltip${index}`}
                                    onClick={(e: any) =>
                                      handlePin(e, notification.id)
                                    }
                                  >
                                    <KTSVG
                                      className="inline-flex fill-sky-blue-500 text-center mr-1"
                                      svgClassName="w-[.5rem] h-[.625rem]"
                                      path="standard_icons/pin-icon.svg"
                                    />
                                    <span className="px-1 text-sky-blue-500">
                                      Pin To Top
                                    </span>
                                    <span className="text-green-600">
                                      {moment(notification.created_at).format(
                                        "DD MMM"
                                      )}
                                    </span>
                                  </div>
                                  <div
                                    className="flex items-center mr-1 mb-1 text-green-600 group-hover:hidden"
                                    id={`record-pin-tooltip${index}`}
                                  >
                                    <span className="w-2 h-2 rounded-full bg-green-600 mr-1.5"></span>
                                    <span className="px-1 ">New</span>
                                    {moment(notification.created_at).format(
                                      "DD MMM"
                                    )}
                                  </div>
                                </div>
                                <div
                                  className="text-gray-600"
                                  dangerouslySetInnerHTML={{
                                    __html: notification.text,
                                  }}
                                ></div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                className="pb-2 border-b text-xxs font-medium border-white/10 cursor-pointer"
                                onClick={(e: any) =>
                                  handlePin(e, notification.id)
                                }
                              >
                                <Tooltip
                                  anchorId={`record-pin-tooltip${index}`}
                                  content="Mark as Pinned"
                                  place="top"
                                  variant="light"
                                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                                />
                                <div
                                  className="group-hover:opacity-100 group-hover:visible group-hover:inline-flex opacity-0 invisible hidden overflow-hidden items-center"
                                  id={`record-pin-tooltip${index}`}
                                >
                                  <KTSVG
                                    className="inline-flex fill-sky-blue-500 text-center mr-1"
                                    svgClassName="w-1.5 h-2"
                                    path="standard_icons/pin-icon.svg"
                                  />
                                  <span className="pr-1 text-sky-blue-500">
                                    Pin To Top
                                  </span>
                                </div>
                                <span className="text-gray-500">
                                  {moment(notification.created_at).format(
                                    "DD MMM"
                                  )}
                                </span>
                                <div
                                  className="text-gray-600"
                                  dangerouslySetInnerHTML={{
                                    __html: notification.text,
                                  }}
                                ></div>
                              </div>
                            </>
                          )}
                        </li>
                      )
                    )
                  ) : layout?.tabValue === "activity" &&
                    layout?.activities?.length > 0 ? (
                    layout?.activities?.map((activity: any, aIndex: number) => (
                      <li className="pt-2 px-5 transition" key={aIndex}>
                        {activity.is_pinned ? (
                          <div className="pb-2 border-b text-xxs font-medium border-white/10">
                            <div className="text-sky-blue-500 mb-1">
                              <KTSVG
                                className="inline-flex fill-sky-blue-500 text-center mr-1"
                                svgClassName="w-1.5 h-2"
                                path="standard_icons/pin-icon.svg"
                              />
                              {moment(activity.created_at).format("DD MMM")}
                            </div>
                            <div
                              className="text-gray-600"
                              dangerouslySetInnerHTML={{
                                __html: activity.text,
                              }}
                            ></div>
                          </div>
                        ) : activity.is_new ? (
                          <div className="pb-2 border-b text-xxs font-medium ">
                            <div className="flex items-center mr-1 text-green-600 mb-1">
                              <span className="w-2 h-2 rounded-full bg-green-600 mr-1.5"></span>
                              <span className="px-1">New</span>
                              {moment(activity.created_at).format("DD MMM")}
                            </div>
                            <div
                              className="text-gray-600"
                              dangerouslySetInnerHTML={{
                                __html: activity.text,
                              }}
                            ></div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </li>
                    ))
                  ) : (
                    <p className="text-white text-center text-xs py-4">
                      No records found at the moment.
                    </p>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* {userInfo || layout?.notificationId ? (
        <div className="w-[calc(100vw--3.75rem)] z-100 bg-black bg-opacity-70 fixed top-0 left-0 h-full"></div>
      ) : (
        ""
      )} */}
    </>
  );
};

export default AsideDefault;
