import { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import infoIcon from "../../assets/media/icons/other_icons/info.svg";
import TooltipPortal from "../../pages/tx_trade/table/component/TooltipPortal";
import { IKTSVG } from "../ui/IKTSVG";
import { KTSVG } from "../ui/KTSVG";

const Checkbox = ({
  id,
  handleOnChange,
  label,
  labelclass,
  tooltip = undefined,
  name,
  checked = false,
  reff,
  WithIcon = false,
  AddinventoryHand = false,
  content = "Price per exchange",
  extraClass,
  Icon = false,
  IconSize = false,
  TooltipContent = false,
  activeViolet = false,
  TooltipID = false,
}: any) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const checkBoxHandle = (e: any) => {
    setIsChecked(e.target.checked);
    let event = { target: { name: e.target.name, checked: e.target.checked } };
    handleOnChange(event);
  };

  return (
    <label
      className={`${extraClass} ${
        isChecked &&
        AddinventoryHand &&
        (activeViolet
          ? "border-indigo-500 hover:!border-indigo-500"
          : "border-green-600 hover:!border-green-600 focus-within:!border-green-600")
      } ${
        WithIcon && "overflow-hidden"
      } group h-[1.875rem] py-1 px-2 pl-2.5 form-check flex flex-row-reverse justify-between items-center  !rounded border border-gray-300 hover:border-indigo-500 focus-within:border-indigo-500 transition cursor-pointer relative w-full`}
      htmlFor={id}
    >
      {WithIcon && (
        <div
          className={`icon_block absolute left-0 top-0 w-[1.875rem] h-full flex items-center justify-center border-r  ${
            isChecked && AddinventoryHand
              ? activeViolet
                ? "border-indigo-500"
                : "border-green-500"
              : "bg-transparent group-hover:border-indigo-500 group-focus-within:border-indigo-500"
          }`}
        >
          <IKTSVG
            path={Icon}
            svgClassName={`${IconSize} ${
              isChecked
                ? `${
                    activeViolet ? "fill-indigo-500" : "fill-green-600"
                  } ${IconSize}`
                : `fill-violet-800 group-hover:fill-indigo-500 group-focus-within:fill-indigo-500`
            }`}
          />
        </div>
      )}
      <div className="flex items-center">
        <input
          className={`form-check-input h-3 w-3 rounded-sm bg-gray-100 checked:bg-indigo-500 transition ml-2 cursor-pointer border checked:border-indigo-500 border-gray-300 hover:border-indigo-500 focus-within:border-indigo-500 focus:ring-0 focus:ring-offset-0 ${
            isChecked &&
            AddinventoryHand &&
            (activeViolet
              ? "checked:group-hover:border-indigo-500 checked:!border-indigo-500 checked:!bg-indigo-500"
              : "checked:group-hover:border-green-600 checked:!border-green-600 checked:!bg-green-500")
          }`}
          type="checkbox"
          value=""
          name={name}
          id={id}
          onChange={(e: any) => {
            checkBoxHandle(e);
          }}
          checked={isChecked}
          ref={reff}
        />
      </div>
      <div
        className={`flex items-center ${
          WithIcon && "pl-7 w-[calc(100%_-_1rem)]"
        }`}
      >
        <div className={`inline-flex items-center ${WithIcon && "w-full"}`}>
          <label
            className={`form-check-label inline-block font-medium text-xs  cursor-pointer flex items-center w-full ${labelclass} ${
              isChecked && AddinventoryHand
                ? activeViolet
                  ? "text-indigo-500"
                  : "text-green-600"
                : "text-violet-800 group-hover:text-indigo-500 group-focus-within:text-indigo-500"
            } `}
            htmlFor={id}
          >
            <span className="inline-block  truncate">
              {label ? label : "label"}
            </span>
            {WithIcon && TooltipContent && (
              <>
                <div data-tooltip-id={`infoTooltip${id}`}>
                  <IKTSVG
                    path={infoIcon}
                    className={`ml-1 fill-grey-400 block ${
                      isChecked && "fill-green-600/20"
                    }`}
                    svgClassName={` hover:fill-indigo-500 w-3.5 h-3.5 ${
                      isChecked
                        ? activeViolet
                          ? "fill-gray-400"
                          : "fill-green-600/50"
                        : "fill-gray-400"
                    }`}
                  />
                </div>
                <TooltipPortal
                  id={`infoTooltip${id}`}
                  content={TooltipContent ? TooltipContent : ""}
                  className="text-center !bg-white !opacity-100 shadow !px-1.5 !py-0.5 font-medium  !text-xs z-[99999] max-w-[9rem] cursor-default"
                />
              </>
            )}
          </label>
        </div>
        {tooltip && (
          <div className={`ml-2 mt-[.0625rem]   `}>
            <Tooltip
              anchorId="ppe-tooltip"
              content={content}
              place="top"
              variant="light"
              className="!bg-white !opacity-100 !py-0.5 !px-1.5 font-medium !text-xxs z-10"
              clickable
            />
            <div id="ppe-tooltip">
              <KTSVG
                path="other_icons/question-grey.svg"
                svgClassName="fill-gray-400 hover:fill-indigo-500"
              />
            </div>
          </div>
        )}
      </div>
    </label>
  );
};

export default Checkbox;
