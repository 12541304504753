/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import Card from "../../../components/ui/Card";
import { KTSVG } from "../../../components/ui/KTSVG";
import { getNotificationListing } from "../../notifications/core/requests";
import { DashboardContext } from "../core/DashboardProvider";

const NotificationsCard = ({
  notificationsData,
  notificationMetaData,
  dashboardLoading,
  selectedTeams,
  refetch,
}: any) => {
  const layout = useContext(DashboardContext);
  const notificationData = layout?.notificationData;
  const navigate = useNavigate();
  const [metaData, setMetaData] = useState<any>();
  const [error, setError] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState<any>([]);

  // ROUTE TO NOTIFICATION
  const handleClick = (data: string = "") => {
    const a = document.createElement("a");
    a.href = "/notifications";
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const handleActivityView = () => {
    navigate("/notifications", { replace: true, state: "activity" });
  };

  const handleNotificationView = () => {
    navigate("/notifications", { replace: true });
  };

  const fetchData = async () => {
    setError(null);
    try {
      let prevPage: number = page + 1;
      getNotificationListing({
        tabValue: "notification",
        page: prevPage,
        team_id: selectedTeams,
        filters: {
          per_page: 20,
        },
      }).then((response: any) => {
        if (response) {
          layout.setNotificationData((prevItems: any) => [
            ...prevItems,
            ...response?.data?.notifications,
          ]);
          setPage(prevPage);
          setMetaData(response?.meta);
        }
      });
    } catch (error) {
      setError(error);
    } finally {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    setMetaData(notificationMetaData);
  }, [notificationMetaData]);

  return (
    <div className="lg2:w-1/2 w-full md:px-2 md:mb-4 mb-2">
      <Card
        title="Notifications & Activity"
        threeDot={true}
        handleClick={() => handleClick("")}
        cardTitle="Notifications"
      >
        {/* Card sub Heading */}
        <div className="divide-gray-200 divide-y-2 ">
          <div className="flex flex-nowrap justify-between p-5 -mx-2.5 overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
            <div className="px-2.5 max-md:min-w-[max-content] md:w-1/2 w-full">
              <div className=" outline outline-1 outline-gray-200 bg-white rounded px-2.5 py-[.5625rem]">
                <div className="flex flex-wrap sm:flex-nowrap justify-between items-center">
                  <h3 className="md:text-lg md:leading-[1.3125rem]">
                    {notificationsData?.new_notification.toLocaleString()}
                  </h3>
                  {notificationsData?.new_notification !== 0 && (
                    <h5 className="flex items-center">
                      <button
                        type="button"
                        className="bg-violet-500 text-white text-xxs font-medium px-2 py-0.5 rounded hover:bg-indigo-500"
                        title="Notification"
                        onClick={() => handleNotificationView()}
                      >
                        <span>View</span>
                      </button>
                    </h5>
                  )}
                </div>

                <p className="flex items-center text-xs w-full sm:w-auto font-medium text-gray-500 pt-1.5 leading-[.9375rem] pr-2">
                  <span className="flex-1 items-center mr-2">
                    New notification
                  </span>
                </p>
              </div>
            </div>
            <div className="px-2.5 max-md:min-w-[max-content] md:w-1/2 w-full">
              <div className=" outline outline-1 outline-gray-200 bg-white rounded px-2.5 py-[.5625rem]">
                <div className="flex flex-wrap sm:flex-nowrap justify-between items-center">
                  <h3 className="md:text-lg md:leading-[1.3125rem]">
                    {notificationsData?.new_activities.toLocaleString()}
                  </h3>
                  {notificationsData?.new_activities !== 0 && (
                    <h5 className="flex items-center">
                      <button
                        type="button"
                        className="bg-violet-500 text-white text-xxs font-medium px-2 py-0.5 rounded hover:bg-indigo-500"
                        title="Notifiaction: Activity log"
                        onClick={() => handleActivityView()}
                      >
                        <span>View</span>
                      </button>
                    </h5>
                  )}
                </div>
                <p className="flex items-center text-xs w-full sm:w-auto font-medium text-gray-500 pt-1.5 leading-[.9375rem] pr-2">
                  <span className="flex-1 items-center mr-2">Activity log</span>
                </p>
              </div>
            </div>
          </div>
          {/* Card Body */}
          {/* <div className="flow-root outline-black"> */}
          {notificationData?.length > 0 ? (
            <div
              id="notificationCard"
              // h-[100px]
              // className="divide-y divide-gray-200 max-h-50 overflow-y-auto w-full  overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full"
              className="no-scroll flow-root sm:pb-0 pb-3 max-h-[13.25rem] outline-black overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full"
            >
              <InfiniteScroll
                dataLength={
                  notificationData?.length ? notificationData?.length : 0
                }
                next={() => fetchData()}
                hasMore={metaData?.current_page < metaData?.last_page}
                loader={Array.from({ length: 3 }, (v, i) => (
                  <div
                    className={`accordion font-medium px-5 mb-2 ${
                      i === 0 && "mt-2"
                    } `}
                    key={i}
                  >
                    <div
                      className={`accordion-item bg-white !rounded overflow-hidden shimmer-effect`}
                      style={{ height: "30px" }}
                    ></div>
                  </div>
                ))}
                scrollThreshold="20px"
                scrollableTarget="notificationCard"
              >
                {/* <div className="flow-root "> */}
                <ul className="max-h-50 overflow-y-auto ">
                  {notificationData &&
                    notificationData?.length > 0 &&
                    notificationData?.map((notification: any, index: any) =>
                      notification?.is_pinned ? (
                        <li
                          key={index}
                          className="hover:bg-green-600/10 px-4 cursor-pointer transition"
                          onClick={() => {
                            navigate("../notifications", {
                              replace: true,
                              state: notification,
                            });
                            // navigate("../notifications", { replace: true });
                            // layout?.setNotificationId(notification.id);
                          }}
                        >
                          <div className="border-b border-sky-blue-500 flex items-center py-1.5 space-x-4">
                            <div className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
                              <p
                                title={notification?.text?.replace(
                                  /<[^>]*>/g,
                                  ""
                                )}
                                className=" text-xs font-medium text-gray-900 md overflow-hidden text-ellipsis"
                                dangerouslySetInnerHTML={{
                                  __html: notification?.text,
                                }}
                              ></p>
                            </div>
                            <div className="inline-flex items-center text-base font-medium text-gray-900">
                              <span className="flex items-center text-sky-blue-500 text-xs font-medium px-2.5 rounded">
                                <KTSVG
                                  className="fill-sky-blue-500 mr-1.5"
                                  path="standard_icons/pin-icon.svg"
                                />
                                {moment(
                                  new Date(notification?.created_at)
                                ).format("DD MMM")}
                              </span>
                            </div>
                          </div>
                        </li>
                      ) : notification?.is_new ? (
                        <li
                          key={index}
                          className="hover:bg-green-600/10 px-4 cursor-pointer transition"
                          onClick={() => {
                            navigate("../notifications", {
                              replace: true,
                              state: notification,
                            });
                            // layout?.setNotificationId(notification.id);
                          }}
                        >
                          <div className="border-b border-gray-100 flex items-center py-1.5 space-x-4">
                            <div className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
                              <p
                                title={notification?.text?.replace(
                                  /<[^>]*>/g,
                                  ""
                                )}
                                className="text-xs font-medium text-gray-900 md overflow-hidden text-ellipsis"
                                dangerouslySetInnerHTML={{
                                  __html: notification?.text,
                                }}
                              ></p>
                            </div>
                            <div className="inline-flex items-center text-base font-medium text-gray-900">
                              <span className="flex items-center text-green-600 text-xs font-medium px-2.5 rounded">
                                <KTSVG
                                  className="fill-green-600 mr-1.5"
                                  path="standard_icons/n-icon.svg"
                                />
                                {moment(notification?.created_at).format(
                                  "DD MMM"
                                )}
                              </span>
                            </div>
                          </div>
                        </li>
                      ) : (
                        <li
                          className="hover:bg-green-600/10 px-4 cursor-pointer transition"
                          onClick={() => {
                            navigate("../notifications", {
                              replace: true,
                              state: notification,
                            });
                          }}
                        >
                          <div className="border-b border-gray-100 flex items-center py-1.5 space-x-4">
                            <div className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
                              <p
                                title={notification?.text?.replace(
                                  /<[^>]*>/g,
                                  ""
                                )}
                                className="text-xs font-medium text-gray-900 md overflow-hidden text-ellipsis"
                                dangerouslySetInnerHTML={{
                                  __html: notification?.text,
                                }}
                              ></p>
                            </div>
                            <div className="inline-flex items-center text-base font-medium text-gray-900">
                              <span className="text-xs font-medium px-2.5 text-gray-500 rounded">
                                {moment(notification?.created_at).format(
                                  "DD MMM"
                                )}
                              </span>
                            </div>
                          </div>
                        </li>
                      )
                    )}
                </ul>
                {/* </div> */}
              </InfiniteScroll>
            </div>
          ) : dashboardLoading ? (
            <div className="py-2 text-center">
              <KTSVG
                className="text-center inline-block fill-violet-500 transition animate-spin"
                path="other_icons/process.svg"
              />
            </div>
          ) : (
            // <KTSVG
            //   className="flex items-center justify-center fill-violet-500 transition animate-spin"
            //   path="other_icons/process.svg"
            // />
            <div className="text-center text-sm py-4">
              No records could be found at the moment.
            </div>
          )}

          {/* </div> */}
        </div>
      </Card>
    </div>
  );
};

export default NotificationsCard;
