const DataCell = ({
  value,
  subValue,
  classname = "",
  width = "",
  padding,
  noCapitalize,
  noEllips = false,
  evtName = false,
}: any) => {
  return (
    <>
      <td
        className={`p-1.5 font-medium min-h-[2.5rem] h-[2.5rem]  ${padding} ${evtName}`}
      >
        <span className={`${noEllips ? " " : "ellips-text"}`}>
          <p
            className={`${width} ${classname} ${
              noCapitalize === "yes" ? "" : "capitalize"
            } ${value === "-" && "text-left"} `}
            title={value ? value : ""}
          >
            {value}
          </p>
          {subValue && (
            <small className="text-xxs text-gray-500">{subValue}</small>
          )}
        </span>
      </td>
    </>
  );
};

export default DataCell;
