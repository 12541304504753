import { useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { storeFields } from "../../../components/core/requests";
import { outSideClick } from "../../../helpers/AssetHelpers";
import { existInLocalstorage } from "../../../helpers/Functions";
import { QUERIES } from "../../../helpers/const";
import { TXContext } from "../core/TXProvider";
import DraggableFilters from "./DraggableFilters";

const Header = ({ title, parent, tab }: any) => {
  const globalLayout: any = useContext(LayoutContext);
  const layout = useContext(TXContext);

  let headerFilter =
    tab === "Wallet"
      ? "saveTxPayFilters"
      : tab === "Virtual Cards"
      ? "saveTxPayVirtualCardFilters"
      : "";

  //ADDING & REMOVING LISTENERS FOR OUTSIDE CLICK FOR DROPDOWNS
  useEffect(() => {
    document.addEventListener("mousedown", (e: any) =>
      outSideClick(e, headerFilter)
    );

    return () => {
      document.removeEventListener("mousedown", (e: any) =>
        outSideClick(e, headerFilter)
      );
    };
  }, [tab]);

  let payload: any = [
    {
      key: headerFilter,
      value: layout.filterItems,
    },
  ];

  let isFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.[headerFilter];

  //   STORE FILTER ARRAY
  const { refetch } = useQuery(
    [`${QUERIES.STORE_FIELD}`],
    () => storeFields(payload),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,
      onError: (err) => {},
      onSettled(data, error) {
        // console.log(data);
      },
    }
  );

  useEffect(() => {
    if (layout.filterItems && existInLocalstorage(headerFilter)) {
      layout.setFilterItems(existInLocalstorage(headerFilter));
      setTimeout(() => {
        refetch();
        localStorage.removeItem(headerFilter);
      }, 3000);
    }
  }, []);

  useEffect(() => {
    if (isFilterExistInDB) {
      layout.setFilterItems(isFilterExistInDB);
      layout.setDraggedItems(isFilterExistInDB);
    }
  }, [isFilterExistInDB]);

  return (
    <>
      <div className="ba_item relative max-md:hidden">
        <span
          className="sel_item block py-0.5 pr-6 pl-3 text-sm13 font-medium [&[aria-expanded='true']]:text-indigo-500"
          data-te-collapse-init
          data-te-collapse-collapsed
          data-te-target={`#${headerFilter}`}
          aria-expanded="false"
          aria-controls={headerFilter}
        >
          {title}
        </span>
        <div
          id={headerFilter}
          className="!visible ba_dd absolute top-full bg-white rounded shadow-lt left-0 min-w-[240px] w-full hidden z-20"
          data-te-collapse-item
          aria-labelledby={headerFilter}
          data-te-parent={`#filters`}
        >
          <DraggableFilters
            refetch={refetch}
            headerFilter={headerFilter}
            tab={tab}
            isFilterExistInDB={isFilterExistInDB}
          />
        </div>
      </div>
    </>
  );
};

export default Header;
