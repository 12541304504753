import { getSelectedTeamMember } from "pages/inventory/core/_functions";
import { createContext, PropsWithChildren, useContext, useState } from "react";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { getHeaderFilter } from "../../../helpers/AssetHelpers";
import { NOTIFICATION_FILTER_ITEMS } from "../../../helpers/const";
import { tabConditions } from "../../tx_account/core/functions";

export interface LayoutContextModel {
  tabValue: any;
  setTabValue: (data: any) => void;

  notifications: Array<any>;
  setNotifications: (data: any) => void;

  activities: Array<any>;
  setActivities: (data: any) => void;

  notificationsFilterHandle: any;
  setNotificationsFilterHandle: (data: any) => void;

  activitiesFilterHandle: any;
  setActivitiesFilterHandle: (data: any) => void;

  dateRange: any;
  setDateRange: (data: any) => void;

  filterItems: Array<any>;
  setFilterItems: (data: any) => void;

  draggedItems: Array<any>;
  setDraggedItems: (data: any) => void;

  selectedNotifications: Array<any>;
  setSelectedNotifications: (data: any) => void;

  refetchNotification: Array<any>;
  setRefetchNotification: (data: any) => void;
}

const NotificationsContext = createContext<LayoutContextModel>({
  tabValue: [],
  setTabValue: (data: any) => {},

  notifications: [],
  setNotifications: (data: any) => {},

  activities: [],
  setActivities: (data: any) => {},

  notificationsFilterHandle: {},
  setNotificationsFilterHandle: (data: any) => {},

  activitiesFilterHandle: {},
  setActivitiesFilterHandle: (data: any) => {},

  dateRange: {},
  setDateRange: (data: any) => {},

  filterItems: [],
  setFilterItems: (data: any) => {},

  draggedItems: [],
  setDraggedItems: (data: any) => {},

  selectedNotifications: [],
  setSelectedNotifications: (data: any) => {},

  refetchNotification: [],
  setRefetchNotification: (data: any) => {},
});

const NotificationsProvider = ({ children }: PropsWithChildren) => {
  const globalLayout = useContext(LayoutContext);
  const isGodAdmin = globalLayout?.allowedAccess?.account_info?.is_god_admin;
  const access = globalLayout.allowedAccess?.allowed_access;
  const userSpecific = access?.some(
    (item: any) => item.id === "user-specific-data" && item.status === true
  );

  const [tabValue, setTabValue] = useState<any>("notification");

  const tabCondition = tabConditions(tabValue, "filter");

  // FILTER - CONDITION
  let isFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.[
      `tx_notification_filter_${tabCondition}`
    ];

  let items = getHeaderFilter(
    `tx_notification_filter_${tabCondition}`,
    isFilterExistInDB,
    NOTIFICATION_FILTER_ITEMS
  );

  items = items.filter((obj: any) => {
    return obj?.tab?.includes(tabCondition);
  });

  if (isGodAdmin) {
    // NOTHING CHANGE
    // items = isFilterExistInDB || JSON.parse(saved) || SALES_FILTER_ITEMS;
  } else if (userSpecific) {
    items = items?.filter(
      (item: any) => item.name !== "Team members" && item.name !== "All teams"
    );
  } else {
    items = items?.filter((item: any) => item.name !== "All teams");
  }

  const [notifications, setNotifications] = useState([]);
  const [activities, setActivities] = useState([]);
  const [dateRange, setDateRange] = useState<any>();
  const [notificationsFilterHandle, setNotificationsFilterHandle] =
    useState<any>(() => ({
      per_page: 20,
      teammembers: getSelectedTeamMember(globalLayout?.allowedAccess),
    }));
  const [activitiesFilterHandle, setActivitiesFilterHandle] = useState<any>({
    activities: [],
    per_page: 20,
  });

  // FILTER - CONDITION
  // let isFilterExistInDB =
  //   globalLayout?.allowedAccess?.ui_field_settings?.[
  //     `tx_notification_filter_${tabCondition}`
  //   ];

  // let item = getHeaderFilter(
  //   `tx_account_filter_${tabCondition}`,
  //   isFilterExistInDB,
  //   TX_ACCOUNT_FILTER
  // );

  const [filterItems, setFilterItems] = useState(items);

  const [draggedItems, setDraggedItems] = useState([]);
  const [selectedNotifications, setSelectedNotifications] = useState([]);
  const [refetchNotification, setRefetchNotification] = useState([]);

  const value: LayoutContextModel = {
    tabValue,
    setTabValue,

    notifications,
    setNotifications,

    activities,
    setActivities,

    notificationsFilterHandle,
    setNotificationsFilterHandle,

    activitiesFilterHandle,
    setActivitiesFilterHandle,

    dateRange,
    setDateRange,

    filterItems,
    setFilterItems,

    draggedItems,
    setDraggedItems,

    selectedNotifications,
    setSelectedNotifications,

    refetchNotification,
    setRefetchNotification,
  };

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
};

export { NotificationsContext, NotificationsProvider };
