import { useEffect, useState } from "react";
import warning from "../../../../assets/media/icons/standard_icons/warning.svg";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import { addCommaInNumber } from "../../../../helpers/Functions";

interface CheckBoxInterface {
  title?: string;
  count?: any;
  isCheckbox?: boolean;
  handleOnChange?: any;
  id?: string;
  name?: string;
  checked?: any;
  trackedLoading?: any;
  icon?: any;
}

const CheckboxFilter: React.FC<CheckBoxInterface> = ({
  title,
  count,
  isCheckbox,
  handleOnChange,
  id,
  name,
  checked,
  trackedLoading,
  icon = false,
}) => {
  const [isChecked, setIsChecked] = useState(Boolean);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const checkBoxHandle = (e: any) => {
    setIsChecked(e.target.checked);
    let event = { target: { name: e.target.name, checked: e.target.checked } };
    handleOnChange(event);
  };

  return (
    <div className="w-full lg:w-1/3 mb-5 px-2.5 lg:max-w-[16.1875rem] xl:min-w-[16.66%]">
      <label
        className={`${
          isChecked ? "active-style" : "normal-style"
        } border form-check items-center rounded bg-white group block min-w-[9.875rem] [&.active-style]:bg-indigo-500/5 [&.active-style]:border-indigo-500 max-h-[3.75rem] ${
          isCheckbox &&
          "hover:bg-indigo-500/5 hover:border-indigo-500 hover:text-indigo-500 cursor-pointer"
        }  ${trackedLoading && "shimmer-effect"} `}
      >
        <div
          className={`px-2.5 pt-[.4375rem] pb-[.5625rem] h-full font-medium ${
            icon && "flex justify-between items-start"
          }   `}
        >
          <div className="w-full">
            <div className="flex justify-between items-center">
              <h3
                className={`md:text-lg md:leading-6 ${
                  isChecked ? "text-indigo-500" : ""
                }`}
              >
                {count ? addCommaInNumber(count) : "0"}
              </h3>
              {icon && count > 0 && (
                <IKTSVG
                  className={`flex items-center justify-center fill-rose-500  group-hover:fill-indigo-500 transition `}
                  path={warning}
                  svgClassName="w-[1rem] h-[1rem]"
                />
              )}
            </div>
            <div className="flex justify-between items-center w-full">
              <p
                className={`${
                  isCheckbox &&
                  "group-hover:text-indigo-500  [&.active-style]:text-indigo-500"
                } ${
                  isChecked
                    ? "text-xs text-indigo-500 pt-1"
                    : "text-xs text-gray-500 pt-1"
                }`}
              >
                {title}
              </p>

              {isCheckbox && (
                <div className="flex">
                  <input
                    className="form-check-input h-3 w-3 rounded-sm bg-gray-100 border-gray-300 checked:bg-indigo-500 transition ml-2 cursor-pointer border checked:border-indigo-500 hover:border-indigo-500 focus:ring-0 focus:ring-offset-0`}"
                    type="checkbox"
                    name={name}
                    id={id}
                    checked={isChecked ? isChecked : false}
                    onChange={(e: any) => {
                      checkBoxHandle(e);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </label>
    </div>
  );
};

export default CheckboxFilter;
