import clsx from "clsx";

import { LayoutContext } from "components/core/LayoutProvider";
import DropDownField from "components/formComponent/DropDownField";
import Textfield from "components/formComponent/Textfield";
import { IKTSVG } from "components/ui/IKTSVG";
import { KTSVG } from "components/ui/KTSVG";
import { pdfViewer } from "helpers/Functions";
import { useContext } from "react";
import { Droppable } from "react-beautiful-dnd";
import { Tooltip } from "react-tooltip";
import eyeSVG from "../../../../assets/media/icons/other_icons/Eye.svg";
import Cross from "../../../../assets/media/icons/standard_icons/cross.svg";

const PreUpload = ({
  eventIndex,
  eventRecordIndex,
  eventRecord,
  instructionLetterTemplates,
  initialTemplateState,
  templateState,
  setTemplateState,
  handleChangeTemplate,
  handleInputChanges,
  deletePdfRightSide,
  pdfView = () => {},
  files = [],
  newFIleAllSaved = false,
}: any) => {
  const globalLayout = useContext(LayoutContext);
  const handleOnChange = (data: any) => {
    if (data?.target?.value?.value !== 0) {
      setTemplateState((pre: any) => {
        return {
          ...pre,
          templateView: true,
          data: data?.target?.value,
          inputs: {},
        };
      });
    } else {
      setTemplateState((pre: any) => {
        return {
          ...pre,
          templateView: false,
          inputs: {},
        };
      });
    }
    setTimeout(() => {
      handleChangeTemplate(data?.target?.value, eventRecordIndex, eventIndex);
    }, 100);
    let canvasBody: any =
      document.getElementsByClassName("offcanvas-body")?.[0];
    if (canvasBody) {
      canvasBody.scrollTop = 0;
    }
    if (eventRecord?.additionalFile) {
      deletePdfRightSide(
        eventRecord?.additionalFile,
        "additionalFile",
        eventRecordIndex,
        eventIndex
      );
    }
  };

  let isPathExist = eventRecord?.additionalFile?.path;

  return (
    <Droppable
      droppableId={`${eventIndex}-${eventRecordIndex}-additionalFile`}
      key={`${eventIndex}-${eventRecordIndex}-additionalFile`}
      isDropDisabled={eventRecord?.template?.value !== 0 || isPathExist}
    >
      {(provided: any, snapshot: any) => {
        return (
          <div
            ref={provided.innerRef}
            className={`   addtional_file_drag ${
              eventRecord?.template?.value === 0 && "!ps-0 !pt-0"
            } w-full `}
          >
            <div
              className="drag_items"
              key={eventRecordIndex}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <div
                className={clsx(
                  "px-2  border border-solid rounded rounded-t-none -mt-[.0625rem] w-full text-xs mb-2.5 bg-gray-100/50 flex grid-cols-[25%_1fr_2.5rem_2.5rem] md:grid-cols-[1fr_17%_1fr_2.5rem_2.5rem]",
                  instructionLetterTemplates?.length > 0 && "flex-col",
                  snapshot?.isDraggingOver
                    ? "border-green-500"
                    : "border-violet-500/10",
                  "gap-2.5",
                  eventRecord?.additionalFile &&
                    "!bg-green-800/5 !border-green-500 !border-solid",
                  eventRecord?.template && "pb-2",
                  newFIleAllSaved && "pointer-events-none"
                )}
              >
                <div
                  className={`flex items-center ${
                    instructionLetterTemplates?.length > 0
                      ? "w-full"
                      : "w-[5.6rem]"
                  } gap-2 pt-2 ${
                    eventRecord?.template?.value !== 0 &&
                    eventRecord?.template &&
                    "pb-2"
                  }`}
                >
                  <p>Additional file</p>

                  {instructionLetterTemplates?.length > 0 && (
                    <DropDownField
                      options={[
                        ...instructionLetterTemplates,
                        {
                          title: "upload your own",
                          value: 0,
                          id: "0",
                        },
                      ]}
                      placeholder="Template"
                      name="template"
                      value={eventRecord?.template}
                      getOptionLabel="title"
                      handleOnChange={(data: any) => handleOnChange(data)}
                      isClearable={false}
                    />
                  )}
                  {eventRecord?.template &&
                    eventRecord?.template?.value !== 0 && (
                      <button
                        type="button"
                        id={`view-${eventIndex}-${eventRecordIndex}-additionalFile`}
                        onClick={() => {
                          setTemplateState((pre: any) => {
                            return {
                              ...pre,
                              templateView: true,
                              data: eventRecord?.template,
                              inputs: eventRecord?.inputs,
                            };
                          });
                          let canvasBody: any =
                            document.getElementsByClassName(
                              "offcanvas-body"
                            )?.[0];
                          if (canvasBody) {
                            canvasBody.scrollTop = 0;
                          }
                        }}
                      >
                        <IKTSVG
                          className="fill-violet-500 hover:fill-indigo-500 transition"
                          path={eyeSVG}
                          svgClassName="w-4 h-[11px]"
                        />
                        <Tooltip
                          anchorId={`view-${eventIndex}-${eventRecordIndex}-additionalFile`}
                          content="Preview"
                          place="top"
                          variant="light"
                          className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                        />
                      </button>
                    )}
                </div>

                <div className={`flex flex-col w-full gap-2.5 gray-bg-label`}>
                  {eventRecord?.templateKeys?.length > 0 &&
                    eventRecord?.templateKeys?.map((key: any) => (
                      <div key={`${eventRecord?.order_id}-${key}`}>
                        <Textfield
                          type="text"
                          id={`${eventRecord?.order_id}-${key}`}
                          name={key}
                          value={eventRecord?.inputs?.[key] || ""}
                          label={key}
                          handleOnChange={(e: any) => {
                            setTemplateState((pre: any) => {
                              return {
                                ...pre,
                                data: eventRecord?.template,
                              };
                            });
                            handleInputChanges(
                              e,
                              eventRecordIndex,
                              eventIndex,
                              key
                            );
                          }}
                        />
                      </div>
                    ))}

                  {(instructionLetterTemplates?.length <= 0 ||
                    eventRecord?.template?.value === 0) && (
                    <div className="single_drag flex flex-wrap items-center ">
                      <div
                        // onMouseEnter={() => {
                        //   setHoverFile(item?.addtionalFile?.id);
                        // }}
                        // onMouseLeave={() => {
                        //   setHoverFile("");
                        // }}
                        className={`${
                          isPathExist &&
                          "!border-solid !border-green-500 hover:!border-indigo-500 hover:bg-violet-50 transition"
                        }  border border-dashed rounded bg-white px-2 py-[3px] mb-2 flex-1 transition`}
                      >
                        <span
                          className={`${
                            isPathExist &&
                            "!text-black !inline-flex items-center justify-between w-full"
                          } ${
                            !eventRecord?.can_be_fulfilled &&
                            "inline-flex justify-between w-full "
                          }text-sm12 font-medium text-gray-400 block `}
                        >
                          {isPathExist ? isPathExist : " Drag file here"}
                          <span className={`flex s-center gap-x-2 `}>
                            {isPathExist && (
                              <button
                                type="button"
                                id={`view-${eventIndex}-${eventRecordIndex}-additionalFile`}
                                onClick={() => {
                                  pdfViewer(
                                    globalLayout.setFilePreviewState,
                                    eventRecord?.additionalFile
                                  );
                                  pdfView(eventRecord?.additionalFile);
                                }}
                              >
                                <IKTSVG
                                  className="fill-violet-500 hover:fill-indigo-500 transition"
                                  path={eyeSVG}
                                  svgClassName="w-4 h-4"
                                />
                                <Tooltip
                                  anchorId={`view-${eventIndex}-${eventRecordIndex}-additionalFile`}
                                  content="Preview"
                                  place="top"
                                  variant="light"
                                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                />
                              </button>
                            )}

                            {isPathExist && (
                              <button
                                type="button"
                                onClick={() => {
                                  deletePdfRightSide(
                                    eventRecord?.additionalFile,
                                    "additionalFile",
                                    eventRecordIndex,
                                    eventIndex
                                  );
                                }}
                                id={`delete-${eventIndex}-${eventRecordIndex}-additionalFile`}
                              >
                                {files.some(
                                  (file: any) =>
                                    file?.id === eventRecord?.additionalFile?.id
                                ) ? (
                                  <IKTSVG
                                    className="fill-violet-500 hover:fill-indigo-500 transition"
                                    path={Cross}
                                    svgClassName="w-[.5625rem] h-[.5938rem]"
                                  />
                                ) : (
                                  <KTSVG
                                    className="fill-violet-500 hover:fill-indigo-500 transition"
                                    path={"other_icons/Delete.svg"}
                                    svgClassName="w-2.5 h-[.8125rem]"
                                  />
                                )}
                                {/* {files?.}
                                <IKTSVG
                                  className="fill-violet-500 hover:fill-indigo-500 h-2.5 w-2.5 transition"
                                  svgClassName="h-2.5 w-2.5"
                                  path={Cross}
                                /> */}
                                <Tooltip
                                  anchorId={`delete-${eventIndex}-${eventRecordIndex}-additionalFile`}
                                  content="Remove"
                                  place="top"
                                  variant="light"
                                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                />
                              </button>
                            )}
                          </span>
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Droppable>
  );
};

export default PreUpload;
